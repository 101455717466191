import PropTypes from 'prop-types';
import React from 'react';

import { formatDate } from '../lib/fmt';
import Button from './Button';
import DOrder from './docs/DOrder';
import DClaim from './docs/DClaim';
import HistoryGrid from './HistoryGrid';

export default class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemId: null,
      itemType: null,
      item: null,
    };
  }

  static propTypes = {
    app: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    claims: PropTypes.array.isRequired,
    workers: PropTypes.array.isRequired,
    emptyMessage: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  render() {
    const { orders, claims, emptyMessage, isLoading } = this.props;
    const { itemId, item } = this.state;
    if (isLoading) {
      return 'Загрузка...';
    }
    if (orders.length === 0 && claims.length === 0) {
      return emptyMessage;
    }
    return (
      <div>
        <div className="d-flex mb-3">
          <select className="form-control mr-3" value={itemId || ''} onChange={(e) => this.onItemChange(e)}>
            <option value="" disabled>
              Выбрать заказ или заявку
            </option>
            {orders.length > 0 &&
              orders.map((x) => (
                <option key={x.id} value={x.id} data-type="order">
                  {this.getOrderTitle(x)}
                </option>
              ))}
            {orders.length > 0 && claims.length > 0 && (
              <option value="" disabled>
                &mdash;
              </option>
            )}
            {claims.length > 0 &&
              claims.map((x) => (
                <option key={x.id} value={x.id} data-type="claim">
                  {this.getClaimTitle(x)}
                </option>
              ))}
          </select>
          <Button type="dark" text="Печать" icon="print" disabled={!item} onClick={() => this.onPrint()} />
        </div>
        {this.renderHistory()}
      </div>
    );
  }

  // event handlers

  async onItemChange(e) {
    const { app } = this.props;
    const itemId = e.target.value ? Number(e.target.value) : null;
    const itemType =
      (e.target[e.target.selectedIndex] && e.target[e.target.selectedIndex].getAttribute('data-type')) || undefined;
    if (!itemId && !itemType) {
      return;
    }
    try {
      const res = await app.getApi().get(`/${itemType === 'order' ? 'orders' : 'claims'}/${itemId}`);
      this.setState({
        itemId,
        itemType,
        item: res[itemType],
      });
    } catch (err) {
      app.onError(err);
    }
  }

  onPrint() {
    const { app } = this.props;
    const { item, itemType } = this.state;
    if (!item || !itemType) {
      return;
    }
    if (itemType === 'order') {
      app.printDocument(`Заказ # ${item.id}`, DOrder, { order: item });
    } else {
      app.printDocument(`Заявка # ${item.id}`, DClaim, { claim: item });
    }
  }

  // render helpers

  renderHistory() {
    const { app, orders, claims, workers } = this.props;
    const { itemId } = this.state;
    if (orders.length === 0 && claims.length === 0) {
      return null;
    }
    const orderItems = orders.map((x) => ({
      ...x,
      title: this.getOrderTitle(x),
    }));
    const claimItems = claims.map((x) => ({
      ...x,
      title: this.getClaimTitle(x),
    }));
    return <HistoryGrid app={app} orders={orderItems} claims={claimItems} workers={workers} itemId={itemId} />;
  }

  // other helpers

  getOrderTitle(order) {
    const state = order.applied_at ? formatDate(order.applied_at) : 'открыт';
    const id = `# ${order.id}`;
    const mileage = order.mileage ? `${order.mileage} km` : null;
    const rec = order.recommendations.length > 0 ? 'Р' : null;
    return ['Заказ', state, id, mileage, rec].filter((x) => x !== null).join(' – ');
  }

  getClaimTitle(claim) {
    const state = claim.applied_at ? formatDate(claim.applied_at) : 'открыта';
    const id = `# ${claim.id}`;
    return ['Заявка', state, id].join(' – ');
  }
}
