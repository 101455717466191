import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime, formatMoney, formatWh } from '../../lib/fmt';
import { esc, replace } from '../../lib/utils';
import { getLegalEntityDetails } from '../../services/order.service';

export default class DClaim extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    claim: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="DClaim">
        {this.renderHeader()}
        {this.renderActivities()}
        {this.renderProducts()}
        {this.renderFooter()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { app, claim } = this.props;
    const legalEntityDetails = getLegalEntityDetails(app, claim);
    return (
      <div>
        <h1 className="text-center">Клубный сервис TigerBox</h1>
        <div className="text-center mb-3">
          Большая Филевская 3 стр. 1А, {legalEntityDetails.phoneNumber}, {legalEntityDetails.name},{' '}
          {legalEntityDetails.registrationNumber}
        </div>
        <h2 className="text-center">Заявка на работы № {claim.id}</h2>
        <hr />
        <div className="row">
          <div className="col">
            <dl className="row">
              <dt className="col-4">Приём заявки</dt>
              <dd className="col-8">{formatDateTime(claim.created_at) || '–'}</dd>
              <dt className="col-4">Потребитель</dt>
              <dd className="col-8">{claim.contractor_name}</dd>
              <dt className="col-4">Заказ</dt>
              <dd className="col-8">{claim.ord_id || '-'}</dd>
              <dt className="col-4">Модель</dt>
              <dd className="col-8">
                {claim.vehicle_brand} {claim.vehicle_model}
              </dd>
            </dl>
          </div>
          <div className="col">
            <dl className="row">
              <dt className="col-4">Год выпуска</dt>
              <dd className="col-8">{claim.vehicle_year}</dd>
              <dt className="col-4">Цвет</dt>
              <dd className="col-8">{claim.vehicle_color || '–'}</dd>
              <dt className="col-4">VIN</dt>
              <dd className="col-8">{claim.vehicle_vin || '–'}</dd>
              <dt className="col-4">Гос. номер</dt>
              <dd className="col-8">{claim.vehicle_reg}</dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }

  renderActivities() {
    const { claim } = this.props;
    const items = claim.activities;
    return (
      <div className="mb-3">
        <table className="table table-bclaimed table-sm text-center">
          <thead>
            <tr>
              <th>Работы</th>
              <th>Н/ч</th>
              <th>Кол-во</th>
              <th>Цена н/ч</th>
              <th>Стоимость</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => this.renderActivityRow(item))}
            {items.length === 0 && this.renderActivityRow()}
          </tbody>
        </table>
        <div>Мастер приёмщик {this.getSignPlaceholder(4)}</div>
      </div>
    );
  }

  renderActivityRow(item) {
    if (!item) {
      return (
        <tr>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
    const { claim } = this.props;
    const amount = Math.round((claim.wh_price * item.wh * item.qty) / 100);
    return (
      <tr key={item.id}>
        <td>{item.activity_name}</td>
        <td>{formatWh(item.wh)}</td>
        <td>{item.qty}</td>
        <td>{formatMoney(claim.wh_price, true, true)}</td>
        <td>{formatMoney(amount, true, true)}</td>
      </tr>
    );
  }

  renderProducts() {
    const { claim } = this.props;
    const items = claim.products;
    if (items.length === 0) {
      return null;
    }
    return (
      <div className="mb-3">
        <h2 className="text-center">Накладная на запасные части и расходные материалы</h2>
        <table className="table table-bclaimed table-sm text-center">
          <thead>
            <tr>
              <th>Код</th>
              <th>Наименование</th>
              <th>Цена</th>
              <th>Кол-во</th>
              <th>Стоимость</th>
            </tr>
          </thead>
          <tbody>{items.map((item) => this.renderProductRow(item))}</tbody>
        </table>
        <div>Отпустил {this.getSignPlaceholder(4)}</div>
      </div>
    );
  }

  renderProductRow(item) {
    return (
      <tr key={item.id}>
        <td>
          {item.product_sku_original} / {item.producer_name}
        </td>
        <td>{item.product_name}</td>
        <td>{formatMoney(item.price, true, true)}</td>
        <td>{item.qty}</td>
        <td>{formatMoney(item.price * item.qty, true, true)}</td>
      </tr>
    );
  }

  renderFooter() {
    const { claim } = this.props;
    const amount = claim.activity_amount + claim.product_amount;
    return (
      <div>
        <h2 className="text-center mt-3">Итого по заявке и накладной</h2>
        <table className="table table-bclaimed">
          <tbody>
            <tr>
              <td className="w-50">
                <dl className="row mb-0">
                  <dt className="col-8">Общая стоимость ремонтных работ</dt>
                  <dd className="col-4">{formatMoney(claim.activity_amount, true, true)}</dd>
                  <dt className="col-8">Общая стоимость запасных частей</dt>
                  <dd className="col-4">{formatMoney(claim.product_amount, true, true)}</dd>
                  <dt className="col-8">НДС</dt>
                  <dd className="col-4">{formatMoney(0, true, true)}</dd>
                  <dt className="col-8">Общая стоимость ремонта с НДС</dt>
                  <dd className="col-4">{formatMoney(amount, true, true)}</dd>
                </dl>
              </td>
              <td>
                С объёмом работ и с первоначальной стоимостью согласен. С Правилами предоставления услуг ознакомлен.
                Согласен на согласование стоимости дополнительных и сопутстсвующих работ посредством мессенджеров,
                электронной почты или звонка по телефону.
                <br />
                <br />
                Потребитель {this.getSignPlaceholder(4)}
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <br />
                <br />
                Кассир {this.getSignPlaceholder(4)}
              </td>
              <td>
                Объём и качество выполненных работ и комплектность автотранспортного средства проверил
                <br />
                <br />
                Мастер {this.getSignPlaceholder(4)}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mb-3">
          <strong>Гарантийные обязательства исполнителя:</strong> Техническое обслуживание – 20 дней или до 1000 км
          пробега, по текущему ремноту – 30 дней или до 2000 км пробега, по ремонту ДВС – один год, без ограничения
          пробега (есть дополнительные условия). Исполнитель несёт гарантийные обязательства при условии соблюдения
          потребителем правил эксплуатации и рекомендаций исполнителя по использованию результатов работы (услуги).
        </div>
        {false && (
          <div className="mb-3">
            <strong>Комментарий:</strong>
            <div dangerouslySetInnerHTML={this.getHtmlWithLineBreaks(claim.comment)} />
          </div>
        )}
        <div className="mb-3"></div>
        <div className="mb-3">
          Претензий по комплектности не имею. С объёмом выполненных работ согласен. С правилами и условиями эффективного
          и безопасного использования результатов оказанных услуг (выполненных работ) ознакомлен.
          <br />
          <br />
          Потребитель {this.getSignPlaceholder(4)}
        </div>
      </div>
    );
  }

  // other helpers

  getSignPlaceholder(size = 1) {
    return '_'.repeat(size * 10);
  }

  getHtmlWithLineBreaks(text) {
    return { __html: replace(esc(text), '\n', '<br>') };
  }
}
