import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import FormGroup from '../FormGroup';

import {
  formHasError,
  formNormalizePhone,
  formResetErrors,
  formSetError,
  formTrimAll,
  formValidatePhone,
} from '../../lib/form';

export default class PLogin extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    const { app } = props;
    super(props);
    this.state = {
      // +79999999999 / 123456
      form: {
        phone: app.getLogin() || '',
        password: '',
      },
      loading: false,
    };
  }

  render() {
    const { form, loading } = this.state;
    const hasErr = formHasError(form);
    return (
      <div className="PLogin align-items-center d-flex h-100 justify-content-center">
        <div className="card">
          <form className="card-body">
            <h5 className="card-title">Tigermatic</h5>
            <FormGroup
              type="text"
              name="phone"
              label="Телефон"
              placeholder="Любой формат"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
            <FormGroup
              type="password"
              name="password"
              label="Пароль"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
            <Button
              type="primary"
              text="Вход"
              disabled={!form.phone || !form.password || loading || hasErr}
              onClick={() => this.onLogin()}
            />
          </form>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // Logout:
    this.props.app.setToken(null);
  }

  // event handlers

  onFormChange(form) {
    this.setState({
      form: formResetErrors(form),
    });
  }

  async onLogin() {
    const { app } = this.props;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formNormalizePhone(form, 'phone');
    form = formValidatePhone(form, 'phone');
    this.setState({ form });
    if (formHasError(form)) {
      return;
    }
    this.setState({ loading: true });
    try {
      const res1 = await app.getApi().post('/auth', {
        phone: form.phone,
        password: form.password,
      });
      app.setToken(res1.token);
      const res2 = await app.getApi().get('/auth');
      app.setUser(res2.worker);
      app.getHistory().push('/');
    } catch (err) {
      form = formSetError(form, 'phone');
      form = formSetError(form, 'password');
      this.setState({ loading: false, form });
    }
  }
}
