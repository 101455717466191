import { isAfter, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { getTimeOffset } from '../services/scheduler.service';

const INTERVAL = 30 * 1000;

export default class CurrentTimeLine extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(['mobile', 'desktop']).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
  }

  render() {
    return <div className="CurrentTimeLine" style={this.getStyle()} />;
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      this.setState({ date: new Date() });
    }, INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  // other helpers

  getStyle() {
    const { app, mode } = this.props;
    const { date } = this.state;
    const tableHeadHeight = mode === 'desktop' ? 50 : 40;
    const top = getTimeOffset(app, date);
    const config = app.getConfig();
    const timeFinish = new Date();
    const timeStart = new Date();
    timeFinish.setHours(config.scheduler.timeFinish.h, config.scheduler.timeFinish.m, 0);
    timeStart.setHours(config.scheduler.timeStart.h, config.scheduler.timeStart.m, 0);
    const style = {};
    if (isBefore(date, timeStart) || isAfter(date, timeFinish)) {
      style.display = 'none';
    } else {
      style.top = top + tableHeadHeight;
    }
    return style;
  }
}
