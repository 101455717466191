import PropTypes from 'prop-types';
import React from 'react';

import { formatRole } from '../../lib/fmt';
import {
  formHasError,
  formNormalizePhone,
  formResetErrors,
  formTrimAll,
  formValidatePassword,
  formValidatePhone,
  formValidateRequired,
  formValidateTelegram,
} from '../../lib/form';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

const OPTIONS_ROLE = [
  { value: 'boss', title: formatRole('boss') },
  { value: 'master', title: formatRole('master') },
  { value: 'logistician', title: formatRole('logistician') },
  { value: 'executor', title: formatRole('executor') },
];

const OPTIONS_STATUS = [
  { value: 'active', title: 'Активный' },
  { value: 'inactive', title: 'Неактивный' },
];

export default class MWorkerEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    worker: PropTypes.object,
    depts: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, close, worker } = this.props;
    let form = { ...this.state.form };
    form = formResetErrors(form);
    form = formTrimAll(form);
    form = formNormalizePhone(form, 'phone');
    form = formValidateRequired(form, 'name');
    form = formValidateRequired(form, 'phone');
    form = formValidateTelegram(form, 'telegram');
    if (!worker) {
      form = formValidateRequired(form, 'password');
    }
    form = formValidatePhone(form, 'phone');
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    form = formValidatePassword(form, 'password');
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert(
        'Пароль должен удовлетворять следующим условиям:\n' +
          '- не менее 8 символов;\n' +
          '- не менее 1 символа в верхнем регистре;\n' +
          '- не менее 1 спец. символа.',
      );
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      if (worker) {
        await api.put(`/workers/${worker.id}`, pd);
        if (form.password) {
          await api.put(`/workers/${worker.id}/password`, {
            password: form.password,
          });
        }
        const res = await app.getApi().get(`/workers/${worker.id}`);
        close(res.worker);
      } else {
        const { id } = await api.post(`/workers`, pd);
        const res = await app.getApi().get(`/workers/${id}`);
        close(res.worker);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onDelete() {
    const { app, close, worker } = this.props;
    if (!window.confirm('Удалить сотрудника?')) {
      return;
    }
    try {
      await app.getApi().delete(`/workers/${worker.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, worker } = this.props;
    const title = worker ? worker.name : 'Новый сотрудник';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { worker } = this.props;
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="name"
            label="ФИО *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="phone"
            label="Телефон *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_ROLE}
            name="role"
            label="Роль"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_STATUS}
            name="status"
            label="Статус"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={this.getDeptsOptions()}
            name="dept_id"
            label="Подразделение"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="telegram"
            label="Telegram"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="password"
            name="password"
            label={worker ? 'Изменить пароль' : 'Пароль *'}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close, worker } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        {worker && (
          <Button className="mr-auto" type="danger" text="Удалить" icon="trash" onClick={() => this.onDelete()} />
        )}
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm() {
    const { worker } = this.props;
    if (!worker) {
      return {
        name: '',
        phone: '',
        role: 'executor',
        status: 'active',
        dept_id: '',
        telegram: '',
        password: '',
      };
    }
    return {
      name: worker.name,
      phone: worker.phone,
      role: worker.role,
      dept_id: worker.dept_id || '',
      telegram: worker.telegram,
      status: worker.is_active ? 'active' : 'inactive',
      password: '',
    };
  }

  getPostData(form) {
    return {
      name: form.name,
      phone: form.phone,
      role: form.role,
      dept_id: form.dept_id ? Number(form.dept_id) : null,
      telegram: form.telegram,
      is_active: form.status === 'active',
      password: form.password,
    };
  }

  getDeptsOptions() {
    const { depts } = this.props;
    return [{ title: 'Не указано', value: '' }].concat(
      depts.map((d) => ({
        value: d.id,
        title: d.name,
      })),
    );
  }
}
