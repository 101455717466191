import PropTypes from 'prop-types';
import React from 'react';

import List from '../List';
import MDeptEdit from '../modals/MDeptEdit';

export default class PDepts extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: props.app.loadFromCache('depts') || [],
      search: '',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PDepts">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('/refs', 'Подразделения');
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  async onItemSelect(dept) {
    const { app } = this.props;
    await app.showModal(MDeptEdit, { dept });
    await this.refreshData();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Название',
        value: (item) => item.name,
      },
      {
        name: 'Краткое название',
        value: (item) => item.short_name,
      },
      {
        name: 'Посты',
        value: (item) => item.post_count,
      },
      {
        name: 'Сотрудники',
        value: (item) => item.worker_count,
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/depts');
      this.setState({ items });
      app.saveToCache('depts', items);
    } catch (err) {
      app.onError(err);
    }
  }
}
