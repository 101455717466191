import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime, formatMoney, formatPaymentType, formatPhone } from '../../lib/fmt';
import {
  formHasError,
  formNormalizeMoney,
  formResetErrors,
  formSetError,
  formTrimAll,
  formValidateMoney,
  formValidateRequired,
} from '../../lib/form';
import { parseMoney, parseNumber } from '../../lib/parser';
import { asyncAlert } from '../../lib/utils';
import ActivityGrid from '../ActivityGrid';
import Button from '../Button';
import DClaim from '../docs/DClaim';
import FormGroup from '../FormGroup';
import LabelValue from '../LabelValue';
import ModalNav from '../ModalNav';
import ProductGrid from '../ProductGrid';
import MActivityPicker from './MActivityPicker';
import MContractorPicker from './MContractorPicker';
import MContractorView from './MContractorView';
import MOrderEdit from './MOrderEdit';
import MProductPicker from './MProductPicker';
import MVehicleView from './MVehicleView';

const NAV_INFO = 'info';
const NAV_ACTIVITIES = 'activities';
const NAV_PRODUCTS = 'products';
const NAV_COMMENT = 'comment';

const NAVS = [
  [NAV_INFO, 'Информация'],
  [NAV_ACTIVITIES, 'Работы'],
  [NAV_PRODUCTS, 'З/ч'],
  [NAV_COMMENT, 'Комментарий'],
];

export default class MClaimEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    claim: PropTypes.object.isRequired,
    // --
    isEditMode: PropTypes.bool,
  };

  static defaultProps = {
    isEditMode: false,
  };

  messageHandler;
  reconnectHandler;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEditMode: props.isEditMode,
      nav: NAV_INFO,
      claim: props.claim,
      contractor: undefined,
      vehicle: undefined,
      providers: [],
      form: this.initForm(props.claim),
      claimActivities: [],
      claimProducts: [],
    };
  }

  render() {
    return (
      <div className="MClaimEdit modal-dialog modal-xl">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    const { claim } = this.state;
    try {
      const { claim: freshClaim } = await app.getApi().get(`/claims/${claim.id}`);
      const { contractor } = await app.getApi().get(`/contractors/${claim.contractor_id}`);
      const { vehicle } = await app.getApi().get(`/vehicles/${claim.vehicle_id}`);
      const { items: contractors } = await app.getApi().get('/contractors');
      const providers = contractors.filter((c) => c.is_provider);
      this.setState({
        claim: freshClaim,
        contractor,
        vehicle,
        providers,
        claimActivities: this.initClaimActivities(freshClaim),
        claimProducts: this.initClaimProducts(freshClaim),
      });
    } catch (err) {
      app.onError(err);
    }
    this.setupWebSocket();
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(this.messageHandler);
    ws.registerReconnectHandler(this.reconnectHandler);
  }

  // event handlers

  onNavChange(nav) {
    this.setState({ nav });
  }

  onFormChange(form) {
    form = formResetErrors(form);
    this.setState({ form });
  }

  onActivityGridChange(items) {
    this.setState({ claimActivities: items });
  }

  onProductGridChange(items) {
    this.setState({ claimProducts: items });
  }

  async onCreateOrder() {
    const { app } = this.props;
    const { claim } = this.state;
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const { ord_id } = await api.post(`/claims/${claim.id}/order`);
      const { order } = await api.get(`/orders/${ord_id}`);
      await app.showModal(MOrderEdit, { order });
      await this.refreshClaim();
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.data && err.data.code === 'order_already_exists') {
        asyncAlert('По данной заявке уже есть заказ');
      } else {
        app.onError(err);
      }
    }
  }

  async onOpenOrder() {
    const { app } = this.props;
    const { claim } = this.state;
    if (!claim.ord_id) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const { order } = await api.get(`/orders/${claim.ord_id}`);
      await app.showModal(MOrderEdit, { order });
      await this.refreshClaim();
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  onEdit() {
    this.setState({ isEditMode: true });
  }

  onPrint() {
    const { app } = this.props;
    const { claim } = this.state;
    if (!claim.activities || !claim.products) {
      return;
    }
    app.printDocument(`Заявка # ${claim.id}`, DClaim, { claim });
  }

  async onDelete() {
    const { app, close } = this.props;
    const { claim } = this.state;
    const msg = 'Удалить заявку?';
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().delete(`/claims/${claim.id}`);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onArchive() {
    const { app } = this.props;
    const { claim } = this.state;
    const msg = 'Архивировать заявку?';
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().post(`/claims/${claim.id}/archive`);
      await this.refreshClaim();
    } catch (err) {
      app.onError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async onUnarchive() {
    const { app } = this.props;
    const { claim } = this.state;
    const msg = 'Активировать заявку?';
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().post(`/claims/${claim.id}/unarchive`);
      await this.refreshClaim();
    } catch (err) {
      app.onError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async onSave() {
    const { app } = this.props;
    const { claim, claimActivities, claimProducts } = this.state;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    const prepayment_amount = parseMoney(form.prepayment_amount);
    if ((form.prepayment_amount && prepayment_amount !== 0) || form.prepayment_type) {
      form = formNormalizeMoney(form, 'prepayment_amount');
      form = formValidateRequired(form, 'prepayment_amount');
      form = formValidateMoney(form, 'prepayment_amount');
      form = formValidateRequired(form, 'prepayment_type');
      if (prepayment_amount === 0) {
        form = formSetError(form, 'prepayment_amount');
      }
    }
    if (formHasError(form)) {
      this.setState({ nav: NAV_INFO, form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    if (!this.validateActivities()) {
      this.setState({ nav: NAV_ACTIVITIES });
      return;
    }
    if (!this.validateProducts()) {
      this.setState({ nav: NAV_PRODUCTS });
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form, claimActivities, claimProducts);
      await api.put(`/claims/${claim.id}`, pd);
      await this.refreshClaim();
      this.setState({
        isLoading: false,
        isEditMode: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  onCancel() {
    const { claim } = this.state;
    this.setState({
      isEditMode: false,
      form: this.initForm(claim),
      claimActivities: this.initClaimActivities(claim),
      claimProducts: this.initClaimProducts(claim),
    });
  }

  async onContractorClick() {
    const { app } = this.props;
    const { contractor } = this.state;
    if (!contractor) {
      return;
    }
    await app.showModal(MContractorView, { contractor });
  }

  async onVehicleClick() {
    const { app } = this.props;
    const { vehicle } = this.state;
    if (!vehicle) {
      return;
    }
    await app.showModal(MVehicleView, { vehicle });
  }

  async onAddActivities() {
    const { app } = this.props;
    const { claimActivities } = this.state;
    const usedIds = claimActivities.map((item) => item.activity_id);
    const selectedItems = await app.showModal(MActivityPicker, { usedIds });
    if (!selectedItems) {
      return;
    }
    const newClaimActivities = selectedItems.map((item) => ({
      activity_id: item.activity.id,
      activity_name: item.activity.name,
      wh: item.wh,
      qty: item.qty,
    }));
    this.setState({ claimActivities: [...claimActivities, ...newClaimActivities] });
  }

  async onAddProducts() {
    const { app } = this.props;
    const { claimProducts } = this.state;
    const usedIds = claimProducts.map((item) => item.product_id);
    const selectedItems = await app.showModal(MProductPicker, {
      priceType: 2,
      shouldCheckStock: false,
      usedIds,
    });
    if (!selectedItems) {
      return;
    }
    const newClaimProducts = selectedItems.map((item) => ({
      product_id: item.product.id,
      product_name: item.product.name,
      product_stock_qty: item.product.stock_qty,
      product_sku_producer: item.product.sku_producer,
      product_sku_original: item.product.sku_original,
      product_unit: item.product.unit,
      producer_name: item.product.producer_name,
      producer_country: item.product.producer_country,
      price: formatMoney(item.price, true),
      qty: String(item.qty),
      delivery_days: '',
    }));
    this.setState({ claimProducts: [...claimProducts, ...newClaimProducts] });
  }

  async onSelectProvider() {
    const { app } = this.props;
    const { claimProducts } = this.state;
    const providerId = await app.showModal(MContractorPicker, {
      isProvider: true,
    });
    if (!providerId) {
      return;
    }
    const newClaimProducts = claimProducts.map((item) => ({
      ...item,
      contractor_id: item.contractor_id || providerId,
    }));
    this.setState({ claimProducts: newClaimProducts });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const { claim } = this.state;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Заявка # {claim.id}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(nav) => this.onNavChange(nav)} />;
  }

  renderBody() {
    return <div className="modal-body overflow-scroll">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_INFO:
        return this.renderInfo();
      case NAV_ACTIVITIES:
        return this.renderActivities();
      case NAV_PRODUCTS:
        return this.renderProducts();
      case NAV_COMMENT:
        return this.renderComment();
      default:
        return null;
    }
  }

  renderFooter() {
    const { isLoading, isEditMode, claim, nav } = this.state;
    return (
      <div className="modal-footer">
        <div className="mr-auto">
          {!isEditMode && (
            <Button
              className="mr-2"
              type="primary"
              text="Редактировать"
              icon="pen"
              disabled={isLoading || !this.canEdit()}
              onClick={() => this.onEdit()}
            />
          )}
          {!isEditMode && (
            <Button
              className="mr-2"
              type="dark"
              text="Печать"
              icon="print"
              disabled={isLoading}
              onClick={() => this.onPrint()}
            />
          )}
          {!isEditMode && !claim.ord_id && !claim.prepayment_amount && !claim.archived_at && (
            <Button
              className="mr-2"
              type="warning"
              text="Архивировать"
              icon="archive"
              disabled={isLoading || !this.canArchive()}
              onClick={() => this.onArchive()}
            />
          )}
          {!isEditMode && !claim.ord_id && claim.archived_at && (
            <Button
              className="mr-2"
              type="warning"
              text="Активировать"
              icon="undo-alt"
              disabled={isLoading || !this.canArchive()}
              onClick={() => this.onUnarchive()}
            />
          )}
          {!isEditMode && (
            <Button
              className="mr-2"
              type="danger"
              text="Удалить"
              icon="trash"
              disabled={isLoading || !this.canDelete()}
              onClick={() => this.onDelete()}
            />
          )}
          {isEditMode && nav === NAV_ACTIVITIES && (
            <Button
              className="mr-2"
              type="primary"
              text="Добавить позиции"
              icon="plus"
              disabled={isLoading}
              onClick={() => this.onAddActivities()}
            />
          )}
          {isEditMode && nav === NAV_PRODUCTS && (
            <Button
              className="mr-2"
              type="primary"
              text="Добавить позиции"
              icon="plus"
              disabled={isLoading}
              onClick={() => this.onAddProducts()}
            />
          )}
          {isEditMode && nav === NAV_PRODUCTS && this.hasProductsWithEmptyContractor() && (
            <Button
              className="mr-2"
              type="info"
              text="Выбрать поставщика"
              icon="magic"
              disabled={isLoading}
              onClick={() => this.onSelectProvider()}
            />
          )}
        </div>
        {isEditMode && <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => this.onCancel()} />}
        {isEditMode && <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />}
      </div>
    );
  }

  renderInfo() {
    const { isEditMode, claim, form } = this.state;
    const vehicle = `${claim.vehicle_brand} ${claim.vehicle_model} ${claim.vehicle_year}`;
    const phone = formatPhone(claim.contractor_details.phone || claim.contractor_details.contact_phone);
    const paymentOptions = [
      { value: '', title: 'Не выбран' },
      { value: 'cash', title: formatPaymentType('cash') },
      { value: 'cashless', title: formatPaymentType('cashless') },
      { value: 'card', title: formatPaymentType('card') },
    ];
    return (
      <div>
        <div className="row">
          <LabelValue
            className="col"
            label="Клиент"
            value={claim.contractor_name}
            onValueClick={() => this.onContractorClick()}
          />
          <LabelValue className="col" label="Телефон" value={phone} />
          <LabelValue
            className="col"
            label="Сумма"
            value={formatMoney(claim.activity_amount + claim.product_amount, true, true)}
          />
        </div>
        <div className="row">
          <LabelValue className="col" label="Автомобиль" value={vehicle} onValueClick={() => this.onVehicleClick()} />
          <LabelValue className="col" label="Гос. номер" value={claim.vehicle_reg} />
          <LabelValue className="col" label="VIN" value={claim.vehicle_vin} />
        </div>
        <div className="row">
          <LabelValue className="col" label="Приём заявки" value={formatDateTime(claim.created_at)} />
          {claim.archived_at ? (
            <LabelValue className="col" label="Архивация заявки" value={formatDateTime(claim.archived_at)} />
          ) : (
            <LabelValue className="col" label="Закрытие заявки" value={formatDateTime(claim.ord_applied_at)} />
          )}
          <LabelValue
            className="col"
            label="Заказ"
            value={claim.ord_id || 'Создать'}
            onValueClick={() => (claim.ord_id ? this.onOpenOrder() : this.onCreateOrder())}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col-4"
            type="text"
            name="prepayment_amount"
            label="Сумма предоплаты"
            form={form}
            disabled={!isEditMode}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-4"
            type="select"
            options={paymentOptions}
            name="prepayment_type"
            label="Вид предоплаты"
            form={form}
            disabled={!isEditMode}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </div>
    );
  }

  renderActivities() {
    const { app } = this.props;
    const { isEditMode, claim, claimActivities } = this.state;
    return (
      <ActivityGrid
        app={app}
        type="claim"
        items={claimActivities}
        whPrice={claim.wh_price}
        isEditMode={isEditMode}
        onChange={(items) => this.onActivityGridChange(items)}
      />
    );
  }

  renderProducts() {
    const { isEditMode, claimProducts, claim } = this.state;
    return (
      <ProductGrid
        items={claimProducts}
        isEditMode={isEditMode}
        type="claim"
        isApplied={Boolean(claim.ord_applied_at)}
        providers={this.getProviderOptions()}
        onChange={(items) => this.onProductGridChange(items)}
      />
    );
  }

  renderComment() {
    const { isEditMode, form } = this.state;
    return (
      <FormGroup
        className="col"
        type="textarea"
        rows={12}
        name="comment"
        label="Комментарий (не выводится на печатную форму)"
        form={form}
        disabled={!isEditMode}
        onChange={(newForm) => this.onFormChange(newForm)}
      />
    );
  }

  // other helpers

  canEdit() {
    const { app } = this.props;
    const { claim } = this.state;
    if (app.hasRole('boss')) {
      return true;
    }
    return !claim.ord_id && !claim.ord_applied_at;
  }

  canArchive() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  setupWebSocket() {
    const { app } = this.props;
    const { claim } = this.state;
    const ws = app.getWebSocket();
    this.messageHandler = ws.getMessageHandler();
    this.reconnectHandler = ws.getReconnectHandler();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'claim_update') {
        const { isEditMode, isLoading } = this.state;
        const { claim_id } = msg.details;
        if (claim_id === claim.id && !isEditMode && !isLoading) {
          await this.refreshClaim();
        }
      }
    });
    ws.registerReconnectHandler(async () => {
      const { isEditMode, isLoading } = this.state;
      if (!isEditMode && !isLoading) {
        await this.refreshClaim();
      }
    });
  }

  initForm(claim) {
    return {
      prepayment_type: claim.prepayment_type || '',
      prepayment_amount: claim.prepayment_amount !== 0 ? formatMoney(claim.prepayment_amount, true) : '',
      comment: claim.comment,
    };
  }

  initClaimActivities(claim) {
    return [...claim.activities];
  }

  initClaimProducts(claim) {
    return claim.products.map((item) => ({
      ...item,
      qty: String(item.qty),
      price: formatMoney(item.price, true),
    }));
  }

  getPostData(form, claimActivities, claimProducts) {
    return {
      prepayment_type: form.prepayment_type || null,
      prepayment_amount: parseMoney(form.prepayment_amount) || 0,
      comment: form.comment,
      activities: claimActivities.map((item) => ({
        activity_id: item.activity_id,
        wh: item.wh,
        qty: parseNumber(item.qty),
      })),
      products: claimProducts.map((item) => ({
        product_id: item.product_id,
        qty: parseNumber(item.qty),
        price: parseMoney(item.price),
        contractor_id: item.contractor_id ? parseNumber(item.contractor_id) : null,
        delivery_days: item.delivery_days ? parseNumber(item.delivery_days) : null,
      })),
    };
  }

  validateActivities() {
    const { claimActivities } = this.state;
    for (const item of claimActivities) {
      const wh = parseNumber(item.wh);
      const qty = parseNumber(item.qty);
      if (wh === undefined || wh < 0) {
        asyncAlert('Неверно введены н/ч');
        return false;
      }
      if (qty === undefined || qty <= 0) {
        asyncAlert('Неверно введено количество');
        return false;
      }
    }
    return true;
  }

  validateProducts() {
    const { claimProducts } = this.state;
    for (const item of claimProducts) {
      const price = parseMoney(item.price);
      const qty = parseNumber(item.qty);
      const delivery_days = parseNumber(item.delivery_days);
      if (price === undefined || price < 0) {
        asyncAlert('Неверно введено цена');
        return false;
      }
      if (qty === undefined || qty <= 0) {
        asyncAlert('Неверно введено количество');
        return false;
      }
      if (item.delivery_days && !(delivery_days > 0)) {
        asyncAlert('Неверно введен срок поставки');
        return false;
      }
    }
    return true;
  }

  getProviderOptions() {
    const { providers } = this.state;
    const first = { value: '', title: 'Не выбран' };
    const options = providers.map((p) => ({ value: p.id, title: p.name }));
    return [first, ...options];
  }

  async refreshClaim() {
    const { app } = this.props;
    const { claim } = this.state;
    const { claim: freshClaim } = await app.getApi().get(`/claims/${claim.id}`);
    this.setState({
      claim: freshClaim,
      form: this.initForm(freshClaim),
      claimActivities: this.initClaimActivities(freshClaim),
      claimProducts: this.initClaimProducts(freshClaim),
    });
  }

  hasProductsWithEmptyContractor() {
    const { claimProducts } = this.state;
    return claimProducts.some((product) => !product.contractor_id);
  }
}
