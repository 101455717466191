import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../lib/utils';
import DirPicker from './DirPicker';

const Awesomplete = window.Awesomplete;

export default class FormGroup extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([
      'text',
      'password',
      'date',
      'select',
      'textarea',
      'dir',
      'checks',
      'checkbox',
      'number',
      'color',
    ]).isRequired,
    name: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    // --
    label: PropTypes.string,
    className: PropTypes.string,
    formControlClassName: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    autocomplete: PropTypes.array,
    autocompleteFilter: PropTypes.func,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    dirs: PropTypes.array,
    dirLevels: PropTypes.number,
    dirWithRoot: PropTypes.bool,
    rows: PropTypes.number,
    checks: PropTypes.array,
    checkLabel: PropTypes.string,
    onChange: PropTypes.func,
  };

  render() {
    const { name, label, className } = this.props;
    const groupClassName = cn(className, 'form-group');
    return (
      <div className={groupClassName} key={name}>
        {label && <label htmlFor={this.getId()}>{label}</label>}
        {this.renderControl()}
      </div>
    );
  }

  // event handlers

  onChange(name, value) {
    const { form, onChange } = this.props;
    if (!onChange) {
      return;
    }
    const newForm = { ...form };
    newForm[name] = value;
    newForm[`${name}_error`] = undefined;
    onChange(newForm, name);
  }

  // render helpers

  renderControl() {
    switch (this.props.type) {
      case 'text':
      case 'password':
      case 'date':
      case 'number':
        return this.renderInput();
      case 'select':
        return this.renderSelect();
      case 'textarea':
        return this.renderTextarea();
      case 'dir':
        return this.renderDir();
      case 'checks':
        return this.renderChecks();
      case 'checkbox':
        return this.renderCheckbox();
      case 'color':
        return this.renderColor();
      default:
        return null;
    }
  }

  renderInput() {
    const {
      formControlClassName,
      type,
      name,
      form,
      disabled,
      placeholder,
      autocomplete,
      autocompleteFilter,
      minDate,
      maxDate,
    } = this.props;
    const value = form[name];
    const error = form[`${name}_error`];
    const className = cn('form-control', error ? 'is-invalid' : '', formControlClassName);
    const ref = (domElement) => {
      if (autocomplete && domElement && !domElement.awesomplete) {
        domElement.awesomplete = new Awesomplete(domElement, {
          list: autocomplete,
          filter: autocompleteFilter || Awesomplete.FILTER_CONTAINS,
          minChars: autocomplete.length < 100 ? 1 : 2,
        });
        domElement.addEventListener('awesomplete-selectcomplete', () => {
          this.onChange(name, domElement.value);
        });
      }
    };
    return (
      <input
        className={className}
        type={type}
        id={this.getId()}
        value={value}
        min={minDate}
        max={maxDate}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="off"
        ref={ref}
        onChange={(e) => this.onChange(name, e.target.value)}
      />
    );
  }

  renderSelect() {
    const { name, form, disabled } = this.props;
    const value = form[name];
    const error = form[`${name}_error`];
    const className = cn('form-control', error ? 'is-invalid' : '');
    return (
      <select
        className={className}
        id={this.getId()}
        value={value}
        disabled={disabled}
        onChange={(e) => this.onChange(name, e.target.value)}
      >
        {this.renderSelectOptions()}
      </select>
    );
  }

  renderSelectOptions() {
    return this.props.options.map((opt) => {
      if (opt.value === undefined) {
        return (
          <option key={opt} value={opt}>
            {opt}
          </option>
        );
      }
      return (
        <option key={opt.value} value={opt.value} disabled={opt.disabled}>
          {opt.title}
        </option>
      );
    });
  }

  renderTextarea() {
    const { name, form, disabled, placeholder, rows } = this.props;
    const value = form[name];
    const error = form[`${name}_error`];
    const className = cn('form-control', error ? 'is-invalid' : '');
    return (
      <textarea
        className={className}
        id={this.getId()}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows || 5}
        onChange={(e) => this.onChange(name, e.target.value)}
      />
    );
  }

  renderDir() {
    const { name, form, disabled, dirs, dirLevels, dirWithRoot } = this.props;
    const value = form[name];
    const error = form[`${name}_error`];
    return (
      <DirPicker
        dirs={dirs}
        levels={dirLevels}
        withRoot={dirWithRoot}
        selectedId={Number(value) || null}
        error={error}
        disabled={disabled}
        onChange={(id) => this.onChange(name, String(id))}
      />
    );
  }

  renderChecks() {
    const { checks } = this.props;
    return (checks || []).map((x) => this.renderCheck(x));
  }

  renderCheck(check) {
    const { name, form, disabled } = this.props;
    const fullName = `${name}_${check.name}`;
    const checked = form[fullName] === 'on';
    return (
      <div className="form-check" key={check.name}>
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            disabled={disabled || check.disabled}
            onChange={() => this.onChange(fullName, checked ? '' : 'on')}
          />
          {check.title}
        </label>
      </div>
    );
  }

  renderCheckbox() {
    const { name, checkLabel, form, disabled } = this.props;
    const checked = form[name] === 'on';
    return (
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={name}
          checked={checked}
          disabled={disabled}
          onChange={() => this.onChange(name, checked ? 'off' : 'on')}
        />
        <label className="custom-control-label" htmlFor={name}>
          {checkLabel}
        </label>
      </div>
    );
  }

  renderColor() {
    const { name, form, disabled, placeholder } = this.props;
    const errorClassName = form[`${name}_error`] ? 'is-invalid' : '';
    const groupClassName = cn('input-group FormGroup_inputColor', errorClassName);
    const controlClassName = cn('form-control', errorClassName);
    const value = form[name];
    const colorIconColor = /^[0-9a-f]{6}$/i.test(value) ? value : 'ffffff';
    return (
      <div className={groupClassName}>
        <div className="input-group-prepend">
          <div className="input-group-text">#</div>
        </div>
        <div className="FormGroup_inputColorIcon" style={{ backgroundColor: `#${colorIconColor}` }} />
        <input
          className={controlClassName}
          type="text"
          id={this.getId()}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(e) => this.onChange(name, e.target.value)}
        />
      </div>
    );
  }

  // other helpers

  getId() {
    return `fc_${this.props.name}`;
  }
}
