import PropTypes from 'prop-types';
import React from 'react';

import List from '../List';
import MVehicleView from '../modals/MVehicleView';

export default class PVehicles extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      search: '',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PVehicles">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('/refs', 'Автомобили');
    const items = app.loadFromCache('vehicles');
    if (items) {
      this.setState({ items });
    }
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  async onItemSelect(vehicle) {
    const { app } = this.props;
    await app.showModal(MVehicleView, { vehicle });
    await this.refreshData();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Модель',
        value: (item) => `${item.brand} ${item.model}`,
        headClassName: 'w-200px',
      },
      {
        name: 'Год',
        value: (item) => item.year,
        headClassName: 'w-200px',
      },
      {
        name: 'Цвет',
        value: (item) => item.color,
        headClassName: 'w-200px',
      },
      {
        name: 'Гос. номер',
        value: (item) => item.reg,
        headClassName: 'w-200px',
      },
      {
        name: 'Владелец',
        value: (item) => item.owner_name,
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/vehicles');
      app.saveToCache('vehicles', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }
}
