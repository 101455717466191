import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '../lib/utils';

export default class CheckBox extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    // --
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  };

  render() {
    const { className, name, label, checked, disabled, color } = this.props;
    const icon = checked ? 'check-square' : 'square';
    return (
      <div className={cn('CheckBox', className, color && `_${color}`)}>
        <input id={name} type="checkbox" disabled={disabled} checked={checked} onChange={() => this.onChange()} />
        <label htmlFor={name}>
          <i className={`fas fa-${icon}`} />
          <span>{label}</span>
        </label>
      </div>
    );
  }

  onChange() {
    const { onChange } = this.props;
    if (onChange) {
      onChange();
    }
  }
}
