import { sleep } from './utils';

const RECONNECT_DELAY_IN_MS = 100;

export function createWebSocket(url) {
  const state = {
    messageHandler: undefined,
    reconnectHandler: undefined,
  };
  const reconnect = () => {
    init({ url, reconnect, state });
    if (state.reconnectHandler) {
      state.reconnectHandler();
    }
  };
  reconnect();
  return {
    registerMessageHandler(handler) {
      state.messageHandler = handler;
    },
    registerReconnectHandler(handler) {
      state.reconnectHandler = handler;
    },
    getMessageHandler() {
      return state.messageHandler;
    },
    getReconnectHandler() {
      return state.reconnectHandler;
    },
  };
}

function init({ url, reconnect, state }) {
  const ws = new WebSocket(url);
  ws.onopen = () => {
    // eslint-disable-next-line no-console
    console.log('WebSocket opened');
  };
  ws.onmessage = (e) => {
    const msg = JSON.parse(e.data);
    // eslint-disable-next-line no-console
    console.log('WebSocket message:', msg);
    if (state.messageHandler) {
      state.messageHandler(msg);
    }
  };
  ws.onclose = async () => {
    // eslint-disable-next-line no-console
    console.log('WebSocket closed');
    await sleep(RECONNECT_DELAY_IN_MS);
    reconnect();
  };
  ws.onerror = (error) => {
    // eslint-disable-next-line no-console
    console.log('WebSocket error:', error.message);
  };
  return ws;
}
