export function getBaseWorkerOptions(workers = []) {
  const firstUnactive = workers.findIndex((i) => !i.is_active);
  let options = workers.map((worker) => ({ value: worker.id, title: worker.name }));
  if (firstUnactive !== -1) {
    options = [
      ...options.slice(0, firstUnactive),
      { value: '—', title: '—', disabled: true },
      ...options.slice(firstUnactive),
    ];
  }
  return options;
}
