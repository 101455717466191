import PropTypes from 'prop-types';
import React from 'react';

import { formatPhone, formatRole } from '../../lib/fmt';
import Button from '../Button';
import List from '../List';
import MWorkerEdit from '../modals/MWorkerEdit';

export default class PWorkers extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      page: 1,
      items: [],
      depts: [],
    };
  }

  render() {
    return (
      <div className="PWorkers">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('/refs', 'Сотрудники');
    const items = app.loadFromCache('workers') || [];
    const depts = app.loadFromCache('depts') || [];
    this.setState({ items, depts });
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  async onAdd() {
    const { app } = this.props;
    const { depts } = this.state;
    await app.showModal(MWorkerEdit, { depts });
    await this.refreshData();
  }

  onItemGetClassName(worker) {
    return worker.is_active ? '' : 'text-muted';
  }

  async onItemSelect(worker) {
    const { app } = this.props;
    const { depts } = this.state;
    await app.showModal(MWorkerEdit, { worker, depts });
    await this.refreshData();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemGetClassName={(item) => this.onItemGetClassName(item)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'ФИО',
        value: (item) => item.name,
      },
      {
        name: 'Подразделение',
        value: (item) => item.dept_name || '–',
        headClassName: 'w-220px',
      },
      {
        name: 'Роль',
        value: (item) => formatRole(item.role),
        headClassName: 'w-220px',
        cellClassName: (item) => (item.is_active ? '' : 'text-lt'),
      },
      {
        name: 'Телефон',
        value: (item) => formatPhone(item.phone),
        headClassName: 'w-220px',
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/workers');
      const { items: depts } = await app.getApi().get('/depts');
      app.saveToCache('workers', items);
      app.saveToCache('depts', depts);
      this.setState({ items, depts });
    } catch (err) {
      app.onError(err);
    }
  }
}
