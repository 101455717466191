export function getChildren(dirs, parentId) {
  return dirs.filter((x) => x.parent_id === parentId);
}

export function getPath(dirs, dirId) {
  if (!dirId) {
    return [];
  }
  let dir = getDirById(dirs, dirId);
  const path = [dir];
  while (!isRoot(dir)) {
    dir = getDirById(dirs, dir.parent_id);
    path.push(dir);
  }
  path.reverse();
  return path;
}

export function runFilter(dirs, items, dirId) {
  if (!dirId) {
    return items;
  }
  if (dirs.length === 0) {
    return items;
  }
  const root = dirs[0];
  if (dirId === root.id) {
    return items;
  }
  const map = new Map();
  dirs.forEach((dir) => {
    map.set(dir.id, dir);
  });
  return items.filter((item) => {
    let id = item.dir_id;
    while (id) {
      if (id === dirId) {
        return true;
      }
      const dir = map.get(id);
      if (!dir) {
        return false;
      }
      id = dir.parent_id;
    }
    return false;
  });
}

// helpers:

function getDirById(dirs, id) {
  return dirs.find((x) => x.id === id);
}

function isRoot(dir) {
  return dir.parent_id ? false : true;
}
