import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../lib/utils';
import {
  getPositionCard,
  getPostNames,
  getRecordsByCell,
  getTimeSteps,
  isIntersection,
} from '../services/scheduler.service';
import CurrentTimeLine from './CurrentTimeLine';
import RecordCard from './RecordCard';

export default class SchedulerTable extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    virtualRecords: PropTypes.array.isRequired,
    // --
    className: PropTypes.string,
    dept: PropTypes.object,
    isCurrentDay: PropTypes.bool,
    isHoliday: PropTypes.bool,
    onAddRecord: PropTypes.func,
    onEditRecord: PropTypes.func,
  };

  render() {
    const { className, isCurrentDay, isHoliday } = this.props;
    return (
      <section className={cn('SchedulerTable position-relative', className, isHoliday && '_holiday')}>
        <table className="table table-bordered text-center">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </table>
        {isCurrentDay && this.renderCurrentTimeLine()}
      </section>
    );
  }

  // event handlers

  onAddRecord(time, postIndex) {
    const { onAddRecord, isHoliday } = this.props;
    const checkHoliday = !isHoliday || window.confirm('Вы уверены, что хотите записать на выходной?');
    if (!onAddRecord || !checkHoliday) {
      return;
    }
    onAddRecord(time, postIndex);
  }

  onEditRecord(record) {
    const { onEditRecord } = this.props;
    if (!onEditRecord) {
      return;
    }
    onEditRecord(record);
  }

  // render helpers

  renderTableHead() {
    const { dept } = this.props;
    const posts = getPostNames(dept);
    return (
      <thead>
        <tr>
          <th />
          {posts.map((x) => (
            <th key={x.index}>{x.title}</th>
          ))}
        </tr>
      </thead>
    );
  }

  renderTableBody() {
    const { app } = this.props;
    const timeSteps = getTimeSteps(app);
    return (
      <tbody>
        {timeSteps.map((x, i) => (
          <tr key={x}>{this.renderTableRow(i + 1, x)}</tr>
        ))}
        {this.renderAdditionalTableRows()}
      </tbody>
    );
  }

  renderAdditionalTableRows() {
    const { app, virtualRecords } = this.props;
    const timeSteps = getTimeSteps(app);
    const additionalRowCount = Math.max(0, virtualRecords.length - timeSteps.length);
    const rows = [];
    for (let i = 1; i <= additionalRowCount; i++) {
      rows.push(<tr key={i}>{this.renderTableRow(timeSteps.length + i)}</tr>);
    }
    return rows;
  }

  renderTableRow(rowNum, timeStep) {
    const { dept } = this.props;
    const posts = getPostNames(dept);
    return (
      <React.Fragment>
        <td>{timeStep && timeStep.slice(0, 5)}</td>
        {posts.map((x) =>
          x.index !== 0 ? this.renderRealPostTableCell(timeStep, x) : this.renderVirtualPostTableCell(rowNum),
        )}
      </React.Fragment>
    );
  }

  renderRealPostTableCell(timeStep, post) {
    const { app, records } = this.props;
    const filteredRecords = records.filter((x) => x.post_index === post.index);
    const recordsByCell = getRecordsByCell(app, records, timeStep, post);
    const isEmpty = timeStep ? recordsByCell.length === 0 : false;
    return (
      <td key={post.index}>
        {filteredRecords.map(
          (x) => x.time === timeStep && this.renderTableCellRecord(x, isIntersection(x, recordsByCell)),
        )}
        {isEmpty && (
          <RecordCard app={app} className="_empty" onOpenRecord={() => this.onAddRecord(timeStep, post.index)} />
        )}
      </td>
    );
  }

  renderVirtualPostTableCell(rowNum) {
    const { virtualRecords } = this.props;
    const virtualRecord = virtualRecords[rowNum - 1];
    return (
      <td key={0} className="_virtual">
        {virtualRecord && this.renderTableCellRecord(virtualRecord, true)}
      </td>
    );
  }

  renderTableCellRecord(record, isIntersecting) {
    const { app } = this.props;
    const position = getPositionCard(isIntersecting);
    return (
      <RecordCard
        key={record.id}
        app={app}
        record={record}
        position={position}
        onOpenRecord={() => this.onEditRecord(record)}
      />
    );
  }

  renderCurrentTimeLine() {
    const { app } = this.props;
    return <CurrentTimeLine app={app} mode="desktop" />;
  }
}
