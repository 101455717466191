import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button';
import FormGroup from '../FormGroup';
import LabelValue from '../LabelValue';
import MContractorView from './MContractorView';
import { getMap, getContractorAutocomplete } from '../../services/autocomplete.service';

export default class MVehicleTransfer extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    vehicle: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: { newOwner: '' },
      contractors: [],
      contractorMap: {},
      newOwnerAutocomplete: undefined,
      newOwner: undefined,
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app, vehicle } = this.props;
    try {
      const { items: contractors } = await app.getApi().get('/contractors');
      this.setState({
        contractors,
        contractorMap: getMap(contractors),
        newOwnerAutocomplete: getContractorAutocomplete(contractors, vehicle.owner_id),
      });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onFormChange(newForm) {
    const { form, contractorMap } = this.state;
    let { newOwner } = this.state;
    const isNewOwnerChanged = newForm.newOwner !== form.newOwner;
    if (isNewOwnerChanged) {
      const match = newForm.newOwner.match(/ID_(\d+)/);
      if (match) {
        const contractorId = Number(match[1]);
        newOwner = contractorMap[contractorId];
      } else {
        newOwner = undefined;
      }
    }
    if (newOwner) {
      newForm.newOwner = this.getContractorLabel(newOwner);
    }
    this.setState({ form: newForm, newOwner });
  }

  async onSave() {
    const { app, close, vehicle } = this.props;
    const { newOwner } = this.state;
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      await api.put(`/vehicles/${vehicle.id}/owner`, { id: newOwner.id });
      const res = await api.get(`/vehicles/${vehicle.id}`);
      close(res.vehicle);
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onOwnerClick() {
    const { app, vehicle } = this.props;
    const { contractorMap } = this.state;
    const contractor = contractorMap[vehicle.owner_id];
    if (!contractor) {
      return;
    }
    await app.showModal(MContractorView, { contractor });
  }

  // render helpers

  renderHeader() {
    const { close, vehicle } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">
          {vehicle.brand} {vehicle.model} – {vehicle.reg}
        </h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { vehicle } = this.props;
    const { form, newOwner, newOwnerAutocomplete } = this.state;
    return (
      <form className="modal-body">
        <LabelValue label="Текущий владелец" value={vehicle.owner_name} onValueClick={() => this.onOwnerClick()} />
        <FormGroup
          formControlClassName={newOwner && 'is-valid'}
          type="text"
          name="newOwner"
          label="Новый владелец *"
          placeholder="Поиск по ФИО, телефону"
          autocomplete={newOwnerAutocomplete}
          form={form}
          onChange={(newForm) => this.onFormChange(newForm)}
        />
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { newOwner, isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button
          type="success"
          text="Сменить владельца"
          disabled={isLoading || !newOwner}
          onClick={() => this.onSave()}
        />
      </div>
    );
  }

  // other helpers

  getContractorLabel(contractor) {
    return contractor.name;
  }
}
