import Button from './Button';
import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../lib/utils';

export default class FilterButton extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]).isRequired,
    label: PropTypes.string.isRequired,
    onClickFilter: PropTypes.func.isRequired,
    onResetFilter: PropTypes.func.isRequired,
    // --
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
  };

  render() {
    const { label, value, className, isDisabled, onClickFilter, onResetFilter } = this.props;
    const groupClassName = cn('btn-group FilterButton', className, isDisabled && '_disabled');
    let selectedItemsCount = 0;
    if (['number', 'string'].includes(typeof value)) {
      selectedItemsCount = value ? 1 : 0;
    } else {
      selectedItemsCount = value.length;
    }
    const hasSelectedItems = Boolean(selectedItemsCount);
    const textCount = hasSelectedItems ? ` (${selectedItemsCount})` : '';
    return (
      <div className={groupClassName} role="group">
        <Button
          type="outline-secondary"
          disabled={isDisabled}
          text={`${label}${textCount}`}
          onClick={() => onClickFilter()}
        />
        <Button
          className="FilterButton_clearBtn"
          type="outline-secondary"
          disabled={isDisabled || !hasSelectedItems}
          icon="times-circle"
          onClick={() => onResetFilter()}
        />
      </div>
    );
  }
}
