import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney } from '../../lib/fmt';
import { cn } from '../../lib/utils';

export default class DProviderReport extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    date1: PropTypes.string.isRequired,
    date2: PropTypes.string.isRequired,
    // --
    providerId: PropTypes.number,
  };

  render() {
    return (
      <div className="DProviderReport">
        {this.renderHeader()}
        {this.renderProviders()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { date1, date2 } = this.props;
    const d1 = dateFns.format(date1, 'DD.MM.YYYY');
    const d2 = dateFns.format(date2, 'DD.MM.YYYY');
    const period = d1 === d2 ? d1 : `${d1} – ${d2}`;
    return (
      <div>
        <h1 className="text-center">Отчёт по поставщикам</h1>
        <h2 className="text-center mb-3">({period})</h2>
      </div>
    );
  }

  renderProviders() {
    const { providerId } = this.props;
    const { providers, amount } = this.processReport();
    const trSummaryClassName = cn('font-weight-bold', providerId && 'd-none');
    return (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th></th>
            <th>Наименование</th>
            <th>Код</th>
            <th>Ед. изм.</th>
            <th>Кол-во</th>
            <th>Цена</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody>
          {providers.map((p) => this.renderProvider(p))}
          <tr className={trSummaryClassName}>
            <td colSpan="6">Итого:</td>
            <td>{formatMoney(amount, true, true)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderProvider(p) {
    const { providerId } = this.props;
    if (providerId && providerId !== p.id) {
      return null;
    }
    return (
      <React.Fragment key={p.id}>
        <tr className="font-weight-bold">
          <td colSpan="7" className="table-active">
            {p.name}
          </td>
        </tr>
        {p.items.map((item, index) => this.renderItem(item, index))}
        <tr className="font-weight-bold">
          <td colSpan="6">Итого по поставщику:</td>
          <td>{formatMoney(p.amount, true, true)}</td>
        </tr>
      </React.Fragment>
    );
  }

  renderItem(item, index) {
    return (
      <tr key={`${item.contractor_id}-${index}`}>
        <td>{index + 1}</td>
        <td>{item.product_name}</td>
        <td>{item.product_sku_producer}</td>
        <td>{item.product_unit}</td>
        <td>{item.qty}</td>
        <td>{formatMoney(item.price, true, true)}</td>
        <td>{formatMoney(item.amount, true, true)}</td>
      </tr>
    );
  }

  // other helpers

  processReport() {
    const { report } = this.props;
    const { items } = report;
    const providers = [];
    const providerSet = new Set();
    for (const item of items) {
      if (!providerSet.has(item.contractor_id)) {
        providers.push({
          id: item.contractor_id,
          name: item.contractor_name,
          amount: 0,
          items: [],
        });
        providerSet.add(item.contractor_id);
      }
    }
    let amount = 0;
    for (const p of providers) {
      for (const item of items) {
        if (item.contractor_id === p.id) {
          p.amount += item.amount;
          p.items.push(item);
        }
      }
      amount += p.amount;
    }
    return { providers, amount };
  }
}
