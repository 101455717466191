import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDate, formatMoney } from '../../lib/fmt';

export default class DOrderReport extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    date1: PropTypes.string.isRequired,
    date2: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="DOrderReport">
        {this.renderHeader()}
        {this.renderOrders()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { date1, date2 } = this.props;
    const d1 = dateFns.format(date1, 'DD.MM.YYYY');
    const d2 = dateFns.format(date2, 'DD.MM.YYYY');
    const period = d1 === d2 ? d1 : `${d1} – ${d2}`;
    return (
      <div>
        <h1 className="text-center">Отчёт по выполненным заказам</h1>
        <h2 className="text-center mb-3">({period})</h2>
      </div>
    );
  }

  renderOrders() {
    const { items, amount } = this.processReport();
    return (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th></th>
            <th>Дата</th>
            <th>Заказ</th>
            <th>Автомобиль</th>
            <th>Клиент</th>
            <th>Сумма</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => this.renderOrder(item, index))}
          <tr className="font-weight-bold">
            <td colSpan="5">Итого:</td>
            <td>{formatMoney(amount, true, true)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderOrder(item, index) {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{formatDate(item.applied_at)}</td>
        <td>{item.id}</td>
        <td>
          {item.vehicle_brand} {item.vehicle_model}
        </td>
        <td>{item.contractor_name}</td>
        <td>{formatMoney(item.amount, true, true)}</td>
      </tr>
    );
  }

  // other helpers

  processReport() {
    const { report } = this.props;
    const { items } = report;
    let amount = 0;
    for (const item of items) {
      amount += item.amount;
    }
    return { items, amount };
  }
}
