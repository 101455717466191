import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { cn } from '../lib/utils';
import PActivities from './pages/PActivities';
import PContractors from './pages/PContractors';
import PClaims from './pages/PClaims';
import PDepts from './pages/PDepts';
import PLogin from './pages/PLogin';
import POrders from './pages/POrders';
import PProducers from './pages/PProducers';
import PProducts from './pages/PProducts';
import PRecordStatuses from './pages/PRecordStatuses';
import PRefs from './pages/PRefs';
import PReports from './pages/PReports';
import PScheduler from './pages/PScheduler';
import PSchedulerRecords from './pages/PSchedulerRecords';
import PStock from './pages/PStock';
import PVehicles from './pages/PVehicles';
import PWorkers from './pages/PWorkers';
import PMyOrder from './pages/PMyOrder';
import PMyOrders from './pages/PMyOrders';
import PageWrapper from './PageWrapper';
import Sidebar from './Sidebar';
import Topbar from './Topbar';

export default class App extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { app } = props;
    app.setUpdate((cb) => {
      this.forceUpdate(cb);
    });
  }

  render() {
    const { app } = this.props;
    return (
      <Router history={app.getHistory()}>
        <Switch>
          {createAppRoute(app, PLogin, '/login')}
          <Auth app={app} />
        </Switch>
      </Router>
    );
  }
}

function Auth({ app }) {
  if (!app.getUser()) {
    return <Redirect to="/login" />;
  }
  return (
    <div className="d-flex h-100">
      <Sidebar app={app} />
      <div className="d-flex flex-column flex-fill">
        <Topbar app={app} />
        <PageWrapper className="App_content" app={app}>
          <Switch>
            {createAppRoute(app, POrders, '/orders')}
            {createAppRoute(app, PClaims, '/claims')}
            {createAppRoute(app, PStock, '/stock')}
            {createAppRoute(app, PReports, '/reports')}
            {createAppRoute(app, PRefs, '/refs')}
            {createAppRoute(app, PVehicles, '/refs/vehicles')}
            {createAppRoute(app, PProducts, '/refs/products')}
            {createAppRoute(app, PContractors, '/refs/contractors')}
            {createAppRoute(app, PDepts, '/refs/depts')}
            {createAppRoute(app, PProducers, '/refs/producers')}
            {createAppRoute(app, PActivities, '/refs/activities')}
            {createAppRoute(app, PWorkers, '/refs/workers')}
            {createAppRoute(app, PRecordStatuses, '/refs/record_statuses')}
            {createAppRoute(app, PScheduler, '/scheduler/:date?')}
            {createAppRoute(app, PSchedulerRecords, '/scheduler/:date/:deptId')}
            {createAppRoute(app, PMyOrders, '/my/orders')}
            {createAppRoute(app, PMyOrder, '/my/orders/:orderId')}
            <Route path="*">{RefirectToHome(app)}</Route>
          </Switch>
        </PageWrapper>
      </div>
      {renderModals(app)}
      {renderPrinter(app)}
    </div>
  );
}

function RefirectToHome(app) {
  return <Redirect to={getHomeUrl(app)} />;
}

function createAppRoute(app, Component, path) {
  const render = ({ match }) => <Component app={app} match={match} />;
  return <Route path={path} exact render={render} />;
}

function renderModals(app) {
  const modals = app.getModals();
  const n = modals.length;
  if (n === 0) {
    return null;
  }
  return (
    <div>
      {modals.map((m, i) => renderModal(m, i === n - 1))}
      <div key="backdrop" className="modal-backdrop show"></div>
    </div>
  );
}

function renderModal(modal, isVisible) {
  const { type, props, key } = modal;
  const className = cn('modal', isVisible ? 'd-block' : '');
  return (
    <div key={key} className={className}>
      {React.createElement(type, props)}
    </div>
  );
}

function renderPrinter(app) {
  const doc = app.getPrinterDocument();
  return (
    <div id="printer" className="d-none">
      {renderPrinterContent(app, doc)}
    </div>
  );
}

function renderPrinterContent(app, doc) {
  if (!doc) {
    return null;
  }
  const { type, props } = doc;
  return React.createElement(type, { app, ...props });
}

function getHomeUrl(app) {
  const user = app.getUser();
  if (user.role === 'executor') {
    return '/my/orders';
  } else if (app.isMobile()) {
    return '/scheduler';
  } else {
    return '/orders';
  }
}
