import PropTypes from 'prop-types';
import React from 'react';

import VoidLink from '../components/VoidLink';
import { cn } from '../lib/utils';

export default class Dropdown extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link'])
      .isRequired,
    text: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    // --
    className: PropTypes.string,
    disabled: PropTypes.bool,
    right: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  render() {
    const { type, text, items, className, disabled, right } = this.props;
    const dropdownClassName = cn(className, 'dropdown');
    const menuClassName = cn('dropdown-menu', right && 'dropdown-menu-right');
    return (
      <div className={dropdownClassName}>
        <button className={`btn btn-${type} dropdown-toggle`} type="button" disabled={disabled} data-toggle="dropdown">
          {text}{' '}
        </button>
        <div className={menuClassName}>{items.map((item, index) => this.renderItem(item, index))}</div>
      </div>
    );
  }

  // event handlers

  onItemClick(index) {
    if (this.props.onSelect) {
      this.props.onSelect(index);
    }
  }

  // render helpers

  renderItem(item, index) {
    return (
      <VoidLink key={index} className="dropdown-item" onClick={() => this.onItemClick(index)}>
        {item}
      </VoidLink>
    );
  }
}
