import PropTypes from 'prop-types';
import React from 'react';

import { formatDrive, formatInt, formatTransmissionType } from '../../lib/fmt';
import { asyncAlert, uniqueArray } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

import {
  formHasError,
  formNormalizeReg,
  formTrimAll,
  formValidateReg,
  formValidateRegexp,
  formValidateRequired,
} from '../../lib/form';

const OPTIONS_COLOR = [
  { value: '', title: 'Не выбран' },
  'Белый',
  'Голубой',
  'Жёлтый',
  'Зелёный',
  'Коричневый',
  'Красный',
  'Оранжевый',
  'Розовый',
  'Серый',
  'Синий',
  'Фиолетовый',
  'Чёрный',
];

const OPTIONS_TRANSMISSION_TYPE = [
  { value: '', title: 'Не выбран' },
  { value: 'manual', title: formatTransmissionType('manual') },
  { value: 'automatic', title: formatTransmissionType('automatic') },
];

const OPTIONS_DRIVE = [
  { value: '', title: 'Не выбран' },
  { value: 'rwd', title: formatDrive('rwd') },
  { value: 'fwd', title: formatDrive('fwd') },
  { value: '4wd', title: formatDrive('4wd') },
];

export default class MVehicleEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    vehicle: PropTypes.object,
    contractor: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.vehicle),
      brandModelSet: null,
      autocompleteBrand: null,
      autocompleteModel: null,
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    try {
      const { models } = await app.getApi().get('/vehicles/models');
      const brandModelSet = new Set();
      models.forEach((m) => brandModelSet.add(`${m.brand}:${m.model}`));
      this.setState({
        brandModelSet,
        autocompleteBrand: uniqueArray(models.map((m) => m.brand)),
        autocompleteModel: uniqueArray(models.map((m) => m.model)),
      });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onFormChange(form) {
    this.setState({
      form: {
        ...form,
        brand: form.brand.toUpperCase(),
        model: form.model.toUpperCase(),
        reg: form.reg.toUpperCase(),
        vin: form.vin.toUpperCase(),
        trade_code: form.trade_code.toUpperCase(),
        motor_code: form.motor_code.toUpperCase(),
        transmission_code: form.transmission_code.toUpperCase(),
        drive_code: form.drive_code.toUpperCase(),
      },
    });
  }

  async onSave() {
    const { app, close, contractor, vehicle } = this.props;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formValidateRequired(form, 'brand');
    form = formValidateRequired(form, 'model');
    form = formValidateRequired(form, 'year');
    form = formValidateRegexp(form, 'year', /^\d{4}$/);
    form = formNormalizeReg(form, 'reg');
    form = formValidateRequired(form, 'reg');
    form = formValidateReg(form, 'reg');
    form = formValidateRegexp(form, 'vin', /^[0-9A-Z]{17}$/);
    form = formValidateRegexp(form, 'trade_code', /^[0-9A-Z]{2,6}$/); // ?
    form = formValidateRegexp(form, 'motor_volume', /^\d{3,5}$/);
    form = formValidateRegexp(form, 'motor_code', /^[0-9A-Z]{2,6}$/); // ?
    form = formValidateRegexp(form, 'transmission_code', /^[0-9A-Z]{2,6}$/); // ?
    form = formValidateRegexp(form, 'drive_code', /^[0-9A-Z]{2,6}$/); // ?
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      if (vehicle) {
        await api.put(`/vehicles/${vehicle.id}`, pd);
        const res = await app.getApi().get(`/vehicles/${vehicle.id}`);
        close(res.vehicle);
      } else {
        const { id } = await api.post(`/contractors/${contractor.id}/vehicles`, pd);
        const res = await app.getApi().get(`/vehicles/${id}`);
        close(res.vehicle);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.data === 'vin_already_exists') {
        alert('Автомобиль с таким VIN уже существует');
      } else {
        app.onError(err);
      }
    }
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{this.getTitle()}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form, brandModelSet, autocompleteBrand, autocompleteModel } = this.state;
    const modelFilter = (text, input) => {
      if (!brandModelSet.has(`${this.state.form.brand}:${text}`)) {
        return false;
      }
      return text.toLowerCase().includes(input.toLowerCase());
    };
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            autocomplete={autocompleteBrand}
            name="brand"
            label="Марка *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            autocomplete={autocompleteModel}
            autocompleteFilter={modelFilter}
            name="model"
            label="Модель *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="year"
            label="Год *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="reg"
            label="Гос. номер *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="vin"
            label="VIN"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_COLOR}
            name="color"
            label="Цвет"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="trade_code"
            label="Торговое обозначение"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="motor_volume"
            label="Объем двигателя (см³)"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="motor_code"
            label="Буквенное обозначение двигателя"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_TRANSMISSION_TYPE}
            name="transmission_type"
            label="Тип КПП"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="transmission_code"
            label="Буквенное обозначение КПП"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_DRIVE}
            name="drive"
            label="Тип привода"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="drive_code"
            label="Идентификатор привода осей"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(vehicle) {
    if (!vehicle) {
      return {
        brand: '',
        model: '',
        year: '',
        reg: '',
        vin: '',
        color: '',
        trade_code: '',
        motor_volume: '',
        motor_code: '',
        transmission_type: '',
        transmission_code: '',
        drive: '',
        drive_code: '',
      };
    }
    return {
      brand: vehicle.brand,
      model: vehicle.model,
      year: String(vehicle.year),
      reg: vehicle.reg,
      vin: vehicle.vin || '',
      color: vehicle.color,
      trade_code: vehicle.trade_code,
      motor_volume: formatInt(vehicle.motor_volume),
      motor_code: vehicle.motor_code,
      transmission_type: vehicle.transmission_type || '',
      transmission_code: vehicle.transmission_code,
      drive: vehicle.drive || '',
      drive_code: vehicle.drive_code,
    };
  }

  getTitle() {
    const { vehicle } = this.props;
    return vehicle ? `${vehicle.brand} ${vehicle.model} – ${vehicle.reg}` : 'Новый автомобиль';
  }

  getPostData(form) {
    return {
      brand: form.brand,
      model: form.model,
      year: Number(form.year),
      reg: form.reg,
      vin: form.vin || null,
      color: form.color,
      trade_code: form.trade_code,
      motor_volume: Number(form.motor_volume) || null,
      motor_code: form.motor_code,
      transmission_type: form.transmission_type || null,
      transmission_code: form.transmission_code,
      drive: form.drive || null,
      drive_code: form.drive_code,
    };
  }
}
