import PropTypes from 'prop-types';
import React from 'react';
import { formatInt } from '../../lib/fmt';
import { formHasError, formTrimAll, formValidateRegexp, formValidateRequired } from '../../lib/form';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

export default class MDirEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    dirs: PropTypes.array.isRequired,
    // --
    dir: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, close, type, dir } = this.props;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formValidateRequired(form, 'name');
    form = formValidateRegexp(form, 'pos', /^\d+$/);
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      if (dir) {
        await api.put(`/${type}/dirs/${dir.id}`, pd);
        const res = await app.getApi().get(`/${type}/dirs/${dir.id}`);
        close(res.dir);
      } else {
        const { id } = await api.post(`/${type}/dirs`, pd);
        const res = await app.getApi().get(`/${type}/dirs/${id}`);
        close(res.dir);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onDelete() {
    const { app, close, type, dir } = this.props;
    if (!window.confirm('Удалить папку?')) {
      return;
    }
    try {
      await app.getApi().delete(`/${type}/dirs/${dir.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, dir } = this.props;
    const title = dir ? dir.name : 'Новая папка';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { dirs, dir } = this.props;
    const { form } = this.state;
    const filteredDirs = dir ? dirs.filter((x) => x.id !== dir.id) : dirs;
    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="dir"
            dirs={filteredDirs}
            dirLevels={2}
            dirWithRoot
            name="parent"
            label="Расположение"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col-8"
            type="text"
            name="name"
            label="Название *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-4"
            type="text"
            name="pos"
            label="Порядковый номер"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close, dir } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        {dir && (
          <Button
            className="mr-auto"
            type="danger"
            text="Удалить"
            icon="trash"
            disabled={!this.canDelete()}
            onClick={() => this.onDelete()}
          />
        )}
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading || !this.canEdit()} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  canEdit() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  canDelete() {
    const { app, type } = this.props;
    if (type === 'activities') {
      return app.hasRole('boss');
    }
    return app.hasRole('boss', 'logistician');
  }

  initForm() {
    const { dirs, dir } = this.props;
    if (!dir) {
      return {
        parent: String(dirs[0].id),
        name: '',
        pos: '',
      };
    }
    return {
      parent: String(dir.parent_id),
      name: dir.name,
      pos: formatInt(dir.pos),
    };
  }

  getPostData(form) {
    return {
      parent_id: Number(form.parent),
      name: form.name,
      pos: Number(form.pos) || null,
    };
  }
}
