export function parseNumber(value) {
  if (!value) {
    return undefined;
  }
  const cleanValue = String(value)
    .replace(',', '.')
    .replace(/[^0-9.-]/g, '');
  const numValue = parseFloat(cleanValue);
  return isNaN(numValue) ? undefined : numValue;
}

export function parseMoney(value) {
  const numValue = parseNumber(value);
  if (numValue === undefined) {
    return undefined;
  }
  return Math.round(numValue * 100);
}

export function parsePhone(value) {
  if (!value) {
    return undefined;
  }
  let digits = value.replace(/[^0-9]/g, '');
  if (digits.length === 10 && digits[0] !== '7' && digits[0] !== '8') {
    digits = '7' + digits;
  }
  if (digits.length !== 11) {
    return undefined;
  }
  if (digits.charAt(0) === '8') {
    digits = '7' + digits.substr(1);
  }
  if (digits.charAt(0) !== '7') {
    return undefined;
  }
  return '+' + digits;
}

export function parseReg(value) {
  if (!value) {
    return undefined;
  }
  let result = value.toUpperCase();
  result = result.split('А').join('A');
  result = result.split('В').join('B');
  result = result.split('Е').join('E');
  result = result.split('К').join('K');
  result = result.split('М').join('M');
  result = result.split('Н').join('H');
  result = result.split('О').join('O');
  result = result.split('Р').join('P');
  result = result.split('С').join('C');
  result = result.split('Т').join('T');
  result = result.split('У').join('Y');
  result = result.split('Х').join('X');
  result = result.replace(/[^0-9A-Z]/g, '');
  return result;
}

export function parseWh(value) {
  const numValue = parseNumber(value);
  if (!numValue) {
    return undefined;
  }
  return Math.round(numValue * 100);
}
