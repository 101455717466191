import PropTypes from 'prop-types';
import React from 'react';

import CheckBox from './CheckBox';
import { cn } from '../lib/utils';

export default class CheckListCard extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark', 'white']),
    header: PropTypes.string.isRequired,
    // --
    className: PropTypes.string,
    checks: PropTypes.array,
    onChange: PropTypes.func,
  };

  render() {
    const { className, header, type, checks } = this.props;
    const isCardWhite = type !== 'white';
    const checkBoxColor = isCardWhite ? 'white' : '';
    return (
      <div className={cn('CheckListCard', className, 'card mb-3', `bg-${type}`, isCardWhite && 'text-white')}>
        <div className="card-body">
          <h5 className="card-title">{header}</h5>
          {checks.map((x) => this.renderCheckBox(x, checkBoxColor))}
        </div>
      </div>
    );
  }

  // event handlers

  onChange(check) {
    if (this.props.onChange) {
      this.props.onChange(check);
    }
  }

  // render helpers

  renderCheckBox(checkBox, color) {
    return (
      <CheckBox
        key={checkBox.id}
        name={checkBox.name}
        label={checkBox.label}
        color={color}
        disabled={checkBox.disabled}
        checked={checkBox.checked}
        onChange={() => this.onChange(checkBox)}
      />
    );
  }
}
