import PropTypes from 'prop-types';
import React from 'react';

import { formatContractorRole, formatContractorType } from '../../lib/fmt';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

import {
  formHasError,
  formNormalizePhone,
  formResetErrors,
  formTrimAll,
  formValidateEmail,
  formValidatePhone,
  formValidateRegexp,
  formValidateRequired,
} from '../../lib/form';

const OPTIONS_TYPE = [
  { value: 'individual', title: formatContractorType('individual') },
  { value: 'company', title: formatContractorType('company') },
];

const OPTIONS_ROLE = [
  { value: 'client', title: formatContractorRole(true, false) },
  { value: 'provider', title: formatContractorRole(false, true) },
  { value: 'client_provider', title: formatContractorRole(true, true) },
];

export default class MContractorEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    contractor: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form) {
    const oldForm = this.state.form;
    const newForm = oldForm.type === form.type ? form : formResetErrors(form);
    this.setState({ form: newForm });
  }

  async onSave() {
    const { app, close, contractor } = this.props;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formValidateRequired(form, 'name');
    if (form.type === 'individual') {
      form = formNormalizePhone(form, 'i_phone');
      form = formValidatePhone(form, 'i_phone');
      form = formValidateEmail(form, 'i_email');
    } else {
      form = formNormalizePhone(form, 'c_contact_phone');
      form = formValidatePhone(form, 'c_contact_phone');
      form = formValidateEmail(form, 'c_contact_email');
      form = formValidateRegexp(form, 'c_inn', /^[0-9]{10,12}$/);
      form = formValidateRegexp(form, 'c_bik', /^04[0-9]{7}$/);
      form = formValidateRegexp(form, 'c_account', /^[0-9]{20}$/);
    }
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      if (contractor) {
        await app.getApi().put(`/contractors/${contractor.id}`, this.getPostData(form));
        const res = await app.getApi().get(`/contractors/${contractor.id}`);
        close(res.contractor);
      } else {
        const { id } = await app.getApi().post('/contractors', this.getPostData(form));
        const res = await app.getApi().get(`/contractors/${id}`);
        close(res.contractor);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, contractor } = this.props;
    const title = contractor ? contractor.name : 'Новый контрагент';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { contractor } = this.props;
    const { form } = this.state;
    const isIndividual = form.type === 'individual';
    const isCompany = form.type === 'company';
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_TYPE}
            name="type"
            label="Тип"
            disabled={Boolean(contractor)}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_ROLE}
            name="role"
            label="Роль"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="name"
            label={isCompany ? 'Название *' : 'ФИО *'}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        {isIndividual && (
          <div className="row">
            <FormGroup
              className="col"
              type="text"
              name="i_phone"
              label="Телефон"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
            <FormGroup
              className="col"
              type="text"
              name="i_email"
              label="Email"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
          </div>
        )}
        {isCompany && (
          <div className="row">
            <FormGroup
              className="col"
              type="text"
              name="c_contact_name"
              label="Контактное лицо"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
          </div>
        )}
        {isCompany && (
          <div className="row">
            <FormGroup
              className="col"
              type="text"
              name="c_contact_phone"
              label="Телефон"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
            <FormGroup
              className="col"
              type="text"
              name="c_contact_email"
              label="Email"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
          </div>
        )}
        {isCompany && (
          <div className="row">
            <FormGroup
              className="col"
              type="text"
              name="c_inn"
              label="ИНН"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
          </div>
        )}
        {isCompany && (
          <div className="row">
            <FormGroup
              className="col"
              type="text"
              name="c_bik"
              label="БИК"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
            <FormGroup
              className="col"
              type="text"
              name="c_account"
              label="Р/с"
              form={form}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
          </div>
        )}
        <div className="row">
          <FormGroup
            className="col"
            type="textarea"
            name="comment"
            label="Комментарий"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm() {
    const { contractor } = this.props;
    if (!contractor) {
      return {
        type: 'individual',
        role: 'client',
        name: '',
        comment: '',
        i_phone: '',
        i_email: '',
        c_contact_name: '',
        c_contact_phone: '',
        c_contact_email: '',
        c_inn: '',
        c_bik: '',
        c_account: '',
      };
    }
    const isIndividual = contractor.type === 'individual';
    const isCompany = contractor.type === 'company';
    const details = contractor.details;
    return {
      type: contractor.type,
      role: this.getRole(contractor),
      name: contractor.name,
      comment: contractor.comment,
      i_phone: isIndividual ? details.phone : '',
      i_email: isIndividual ? details.email : '',
      c_contact_name: isCompany ? details.contact_name : '',
      c_contact_phone: isCompany ? details.contact_phone : '',
      c_contact_email: isCompany ? details.contact_email : '',
      c_inn: isCompany ? details.inn : '',
      c_bik: isCompany ? details.bik : '',
      c_account: isCompany ? details.account : '',
    };
  }

  getPostData(form) {
    const iDetails = {
      phone: form.i_phone,
      email: form.i_email,
    };
    const cDetails = {
      contact_name: form.c_contact_name,
      contact_phone: form.c_contact_phone,
      contact_email: form.c_contact_email,
      inn: form.c_inn,
      bik: form.c_bik,
      account: form.c_account,
    };
    return {
      type: form.type,
      name: form.name,
      comment: form.comment,
      is_client: form.role.includes('client'),
      is_provider: form.role.includes('provider'),
      details: form.type === 'individual' ? iDetails : cDetails,
    };
  }

  getRole(contractor) {
    if (contractor.is_client && contractor.is_provider) {
      return 'client_provider';
    }
    if (contractor.is_client) {
      return 'client';
    }
    if (contractor.is_provider) {
      return 'provider';
    }
    return undefined;
  }
}
