import PropTypes from 'prop-types';
import React from 'react';

import { getChildren, getPath } from '../lib/tree';
import { cn } from '../lib/utils';

export default class DirPicker extends React.Component {
  static propTypes = {
    dirs: PropTypes.array.isRequired,
    // --
    className: PropTypes.string,
    withRoot: PropTypes.bool,
    levels: PropTypes.number,
    selectedId: PropTypes.number,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    withRoot: false,
    levels: 3,
    error: false,
    disabled: false,
  };

  render() {
    const { className } = this.props;
    const containerClassName = cn(className, 'DirPicker d-flex align-items-center');
    return (
      <div className={containerClassName}>
        {this.renderSelect(1, this.getOptions(1))}
        {this.renderSeparator(2)}
        {this.renderSelect(2, this.getOptions(2))}
        {this.renderSeparator(3)}
        {this.renderSelect(3, this.getOptions(3))}
      </div>
    );
  }

  // event handlers

  onChange(e) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(Number(e.target.value) || null);
    }
  }

  // render helpers

  renderSelect(level, options) {
    const { levels, error, disabled } = this.props;
    if (level > levels) {
      return null;
    }
    const className = cn('form-control flex-grow-1', error && level === 1 && 'is-invalid');
    return (
      <select
        className={className}
        value={this.getValue(options)}
        disabled={disabled || options.length === 0}
        onChange={(e) => this.onChange(e)}
      >
        {this.renderOptions(options)}
      </select>
    );
  }

  renderOptions(options) {
    return options.map((opt) => this.renderOption(opt));
  }

  renderOption(opt) {
    return (
      <option key={opt.value} value={opt.value} disabled={opt.disabled}>
        {opt.title}
      </option>
    );
  }

  renderSeparator(level) {
    const { levels } = this.props;
    if (level > levels) {
      return null;
    }
    return <div className="DirPicker_separator text-center text-black-50">/</div>;
  }

  // other helpers

  getOptions(level) {
    const { dirs, withRoot } = this.props;
    if (dirs.length === 0) {
      return [];
    }
    const parentId = this.getParentId(level);
    const options = getChildren(dirs, parentId).map(getOption);
    if (level === 1) {
      if (withRoot) {
        options.unshift({ title: 'Корень', value: String(parentId) });
      }
      options.unshift({ title: 'Выбрать', value: '', disabled: true });
    } else if (options.length > 0) {
      options.unshift({ title: '–', value: String(parentId) });
    }
    return options;
  }

  getParentId(level) {
    const { dirs, selectedId } = this.props;
    const root = dirs[0];
    if (level === 1) {
      return root.id;
    }
    const path = getPath(dirs, selectedId);
    if (path.length < level) {
      return [];
    }
    return path[level - 1].id;
  }

  getValue(options) {
    if (options.length === 0) {
      return '';
    }
    const { dirs, selectedId } = this.props;
    const path = getPath(dirs, selectedId);
    if (path.length === 0) {
      return '';
    }
    const values = path.map((dir) => String(dir.id));
    for (let i = options.length - 1; i >= 0; i -= 1) {
      const value = options[i].value;
      if (values.includes(value)) {
        return value;
      }
    }
    return '';
  }
}

function getOption(dir) {
  return { title: dir.name, value: String(dir.id) };
}
