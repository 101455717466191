import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button';
import FormGroup from '../FormGroup';

export default class MContractorPicker extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    isProvider: PropTypes.bool,
  };

  static defaultProps = {
    isProvider: false,
  };

  constructor(props) {
    super(props);
    const { app } = props;
    this.state = {
      contractors: app.loadFromCache('contractors') || [],
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="MContractorPicker modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  // render helpers

  renderHeader() {
    const { close, isProvider } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Выбор {isProvider ? 'поставщика' : 'контрагента'}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            name="contractor"
            options={this.getContractorOptions()}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { form } = this.state;
    const isDisabled = !form.contractor;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Выбрать" disabled={isDisabled} onClick={() => close(this.getResult())} />
      </div>
    );
  }

  // other helpers

  initForm() {
    return {
      contractor: '',
    };
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/contractors');
      this.setState({ contractors: items });
    } catch (err) {
      app.onError(err);
    }
  }

  getContractorOptions() {
    const { contractors } = this.state;
    const { isProvider } = this.props;
    const first = { value: '', title: 'Выбрать', disabled: true };
    const options = (isProvider ? contractors.filter((p) => p.is_provider) : contractors).map((p) => ({
      value: p.id,
      title: p.name,
    }));
    return [first, ...options];
  }

  getResult() {
    return this.state.form.contractor;
  }
}
