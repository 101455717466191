import PropTypes from 'prop-types';
import React from 'react';

import { formatWh } from '../../lib/fmt';
import { runFilter } from '../../lib/tree';
import Button from '../Button';
import DirPicker from '../DirPicker';
import List from '../List';
import Pager from '../Pager';
import MActivityEdit from './MActivityEdit';

const PAGE_SIZE = 10;

export default class MActivityPicker extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    usedIds: PropTypes.array,
  };

  static defaultProps = {
    usedIds: [],
  };

  constructor(props) {
    super(props);
    const { app } = props;
    const config = app.getConfig();
    this.state = {
      dirId: config.dirRootId,
      search: '',
      page: 1,
      selected: [],
      dirs: app.loadFromCache('activities/dirs') || [],
      items: app.loadFromCache('activities') || [],
    };
  }

  render() {
    return (
      <div className="MActivityPicker modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshDirs();
    await this.refreshItems();
  }

  // event handlers

  onDirChange(dirId) {
    this.setState({ dirId, page: 1 });
  }

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onItemGetClassName(activity) {
    const { usedIds } = this.props;
    const { selected } = this.state;
    const item = selected.find((x) => x.id === activity.id);
    if (item) {
      return 'table-success';
    }
    if (usedIds.includes(activity.id)) {
      return 'table-warning';
    }
    return undefined;
  }

  onItemGetMenu(activity) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await app.showModal(MActivityEdit, { activity });
          await this.refreshItems();
        },
      },
    ];
  }

  async onItemSelect(activity) {
    await this.selectItem(activity);
  }

  onPageChange(page) {
    this.setState({ page });
  }

  async onAddActivity() {
    const { app } = this.props;
    const { dirId } = this.state;
    const newActivity = await app.showModal(MActivityEdit, { dirId });
    if (!newActivity) {
      return;
    }
    await Promise.all([this.selectItem(newActivity), this.refreshItems()]);
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Выбор работ</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { dirId, search, page, dirs } = this.state;
    return (
      <div className="modal-body">
        <DirPicker
          className="mb-3"
          withRoot
          dirs={dirs}
          selectedId={dirId}
          onChange={(dirId) => this.onDirChange(dirId)}
        />
        <input
          className="form-control mb-3"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        <List
          columns={this.getColumns()}
          items={this.filterItems()}
          pageSize={PAGE_SIZE}
          pageNumber={page}
          noPager
          onItemGetClassName={(item) => this.onItemGetClassName(item)}
          onItemGetMenu={(item) => this.onItemGetMenu(item)}
          onItemSelect={(item) => this.onItemSelect(item)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { selected } = this.state;
    return (
      <div className="modal-footer">
        <div className="mr-auto d-flex">
          <Button
            className="mr-2"
            type="primary"
            text="Новая работа"
            icon="plus"
            onClick={() => this.onAddActivity()}
          />
          {this.renderPager()}
        </div>
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button
          type="success"
          text="Выбрать"
          disabled={selected.length === 0}
          onClick={() => close(this.getResult())}
        />
      </div>
    );
  }

  renderPager() {
    const { page } = this.state;
    const items = this.filterItems();
    if (items.length <= PAGE_SIZE) {
      return null;
    }
    return (
      <Pager
        total={items.length}
        pageSize={PAGE_SIZE}
        pageNumber={page}
        maxButtons={7}
        onSelect={(p) => this.onPageChange(p)}
      />
    );
  }

  // other helpers

  async selectItem(activity) {
    const { selected } = this.state;
    const index = selected.findIndex((x) => x.id === activity.id);
    if (index !== -1) {
      const tmp = [...selected];
      tmp.splice(index, 1);
      this.setState({ selected: tmp });
      return;
    }
    const item = { id: activity.id, wh: activity.wh, qty: 1 };
    this.setState({ selected: [...selected, item] });
  }

  getColumns() {
    return [
      {
        name: 'Название',
        value: (item) => item.name,
      },
      {
        name: 'Н/ч',
        value: (item) => formatWh(item.wh),
        headClassName: 'w-80px',
      },
    ];
  }

  filterItems() {
    const { dirId, search, dirs, items } = this.state;
    const tmpItems = runFilter(dirs, items, dirId);
    if (!search) {
      return tmpItems;
    }
    const lowerSearch = search.toLowerCase();
    return tmpItems.filter((item) => item.$.includes(lowerSearch));
  }

  async refreshItems() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/activities');
      app.saveToCache('activities', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }

  async refreshDirs() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/activities/dirs');
      app.saveToCache('activities/dirs', items);
      this.setState({ dirs: items });
    } catch (err) {
      app.onError(err);
    }
  }

  getResult() {
    const { selected, items } = this.state;
    const result = selected.map((item) => ({
      activity: items.find((a) => a.id === item.id),
      wh: item.wh,
      qty: item.qty,
    }));
    return result.filter((item) => item.activity);
  }
}
