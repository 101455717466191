import PropTypes from 'prop-types';
import React from 'react';

import { formatWh } from '../../lib/fmt';
import {
  formHasError,
  formNormalizeNumber,
  formResetErrors,
  formSetError,
  formTrimAll,
  formValidateRegexp,
  formValidateRequired,
} from '../../lib/form';
import { parseNumber, parseWh } from '../../lib/parser';
import { asyncAlert } from '../../lib/utils';
import { getBaseWorkerOptions } from '../../services/worker.service';
import Button from '../Button';
import FormGroup from '../FormGroup';

export default class MOrderActivityEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    orderActivity: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { orderActivity } = props;
    const total = orderActivity.wh * orderActivity.qty;
    const w1 = orderActivity.workers[0];
    const w2 = orderActivity.workers[1];
    this.state = {
      workers: [],
      form: {
        wh: formatWh(orderActivity.wh),
        qty: String(orderActivity.qty),
        total: formatWh(total),
        worker1: w1 ? String(w1.worker_id) : '',
        wh1: w1 ? formatWh(w1.wh) : '',
        worker2: w2 ? String(w2.worker_id) : '',
        wh2: w2 ? formatWh(w2.wh) : '',
      },
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    try {
      const { items: workers } = await app.getApi().get('/workers');
      this.setState({ workers });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onFormChange(form) {
    if (!form.worker1) {
      form.worker2 = '';
    }
    if (!form.worker2) {
      form.wh2 = '';
    }
    const wh = parseWh(form.wh);
    const qty = parseNumber(form.qty);
    const total = wh && qty ? wh * qty : 0;
    const wh2 = parseWh(form.wh2) || 0;
    const wh1 = form.worker1 ? Math.max(total - wh2, 0) : 0;
    form.total = total ? formatWh(total) : '';
    form.wh1 = wh1 ? formatWh(wh1) : '';
    form = formResetErrors(form);
    this.setState({ form });
  }

  onApply() {
    const { close, orderActivity } = this.props;
    const { workers } = this.state;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formNormalizeNumber(form, 'wh');
    form = formNormalizeNumber(form, 'qty');
    form = formValidateRequired(form, 'wh');
    form = formValidateRequired(form, 'qty');
    if (form.worker2) {
      form = formNormalizeNumber(form, 'wh2');
      form = formValidateRequired(form, 'wh2');
    }
    form = formValidateRegexp(form, 'wh', /^\d+(\.\d{1,2})?$/);
    form = formValidateRegexp(form, 'qty', /^\d+$/);
    form = formValidateRegexp(form, 'wh2', /^\d+(\.\d{1,2})?$/);
    const wh = parseWh(form.wh);
    const qty = parseNumber(form.qty);
    const wh1 = parseWh(form.wh1);
    const wh2 = parseWh(form.wh2);
    if (!wh) {
      form = formSetError(form, 'wh');
    }
    if (!qty) {
      form = formSetError(form, 'qty');
    }
    if (form.worker2) {
      if (form.worker1 === form.worker2) {
        form = formSetError(form, 'worker2');
      }
      if (!wh2 || wh2 >= wh * qty) {
        form = formSetError(form, 'wh2');
      }
    }
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    const result = {
      ...orderActivity,
      wh,
      qty,
      workers: [],
    };
    if (form.worker1) {
      const workerId = Number(form.worker1);
      const worker = workers.find((w) => w.id === workerId);
      result.workers.push({
        worker_id: workerId,
        worker_name: worker.name,
        wh: wh1,
      });
    }
    if (form.worker2) {
      const workerId = Number(form.worker2);
      const worker = workers.find((w) => w.id === workerId);
      result.workers.push({
        worker_id: workerId,
        worker_name: worker.name,
        wh: wh2,
      });
    }
    close(result);
  }

  // render helpers

  renderHeader() {
    const { close, orderActivity } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{orderActivity.activity_name}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="wh"
            label="Н/ч *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="qty"
            label="Кол-во *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup className="col" type="text" name="total" label="Сумма" disabled={true} form={form} />
        </div>
        <div className="row">
          <FormGroup
            className="col-8"
            type="select"
            options={this.getWorkerOptions()}
            name="worker1"
            label="Исполнитель 1"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup className="col-4" type="text" name="wh1" label="Н/ч" disabled={true} form={form} />
        </div>
        <div className="row">
          <FormGroup
            className="col-8"
            type="select"
            options={this.getWorkerOptions()}
            name="worker2"
            label="Исполнитель 2"
            disabled={!form.worker1}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-4"
            type="text"
            name="wh2"
            label="Н/ч"
            disabled={!form.worker2}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Применить" onClick={() => this.onApply()} />
      </div>
    );
  }

  // other helpers

  getWorkerOptions() {
    const { orderActivity } = this.props;
    const { workers } = this.state;
    const first = { value: '', title: 'Не выбран' };
    const options = getBaseWorkerOptions(workers);
    if (options.length === 0) {
      const w1 = orderActivity.workers[0];
      const w2 = orderActivity.workers[1];
      if (w1) {
        options.push({ value: w1.worker_id, title: w1.worker_name });
      }
      if (w2) {
        options.push({ value: w2.worker_id, title: w2.worker_name });
      }
    }
    return [first, ...options];
  }
}
