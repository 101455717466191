import PropTypes from 'prop-types';
import React from 'react';

import { formatWh } from '../../lib/fmt';
import { runFilter } from '../../lib/tree';
import Button from '../Button';
import DirTree from '../DirTree';
import List from '../List';
import MActivityEdit from '../modals/MActivityEdit';
import MDirEdit from '../modals/MDirEdit';

export default class PActivities extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { app } = props;
    const config = app.getConfig();
    this.state = {
      items: [],
      dirs: [],
      dirId: config.dirRootId,
      search: '',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PActivities">
        {this.renderTop()}
        {this.renderMain()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('/refs', 'Работы');
    const items = app.loadFromCache('activities') || [];
    const dirs = app.loadFromCache('activities/dirs') || [];
    this.setState({ items, dirs });
    await this.refreshDirs();
    await this.refreshItems();
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  async onAdd() {
    const { app } = this.props;
    const { dirId } = this.state;
    await app.showModal(MActivityEdit, { dirId });
    await this.refreshItems();
  }

  async onDirSelect(dir) {
    this.setState({ dirId: dir.id });
  }

  async onDirAdd() {
    const { app } = this.props;
    const { dirs } = this.state;
    await app.showModal(MDirEdit, { type: 'activities', dirs });
    await this.refreshDirs();
  }

  async onDirEdit(dir) {
    const { app } = this.props;
    const { dirs } = this.state;
    await app.showModal(MDirEdit, { type: 'activities', dirs, dir });
    await this.refreshDirs();
  }

  async onItemSelect(activity) {
    const { app } = this.props;
    await app.showModal(MActivityEdit, { activity });
    await this.refreshItems();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" disabled={!this.canCreate()} onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderMain() {
    return (
      <div className="row">
        <div className="col-3">{this.renderTree()}</div>
        <div className="col-9">{this.renderList()}</div>
      </div>
    );
  }

  renderTree() {
    const { app } = this.props;
    const { dirs, dirId } = this.state;
    const config = app.getConfig();
    return (
      <DirTree
        rootId={config.dirRootId}
        rootName="Все работы"
        maxLevel={config.dirMaxLevel}
        dirs={dirs}
        selectedId={dirId}
        onSelect={(dir) => this.onDirSelect(dir)}
        onAdd={() => this.onDirAdd()}
        onEdit={(dir) => this.onDirEdit(dir)}
      />
    );
  }

  renderList() {
    const { dirs, items, dirId, search, page } = this.state;
    const itemsInDir = runFilter(dirs, items, dirId);
    return (
      <List
        columns={this.getColumns()}
        items={itemsInDir}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  canCreate() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  getColumns() {
    return [
      {
        name: 'Название',
        value: (item) => item.name,
      },
      {
        name: 'Нормо-часы',
        value: (item) => formatWh(item.wh),
      },
    ];
  }

  async refreshItems() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/activities');
      app.saveToCache('activities', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }

  async refreshDirs() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/activities/dirs');
      app.saveToCache('activities/dirs', items);
      this.setState({ dirs: items });
    } catch (err) {
      app.onError(err);
    }
  }
}
