import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import { cn } from '../lib/utils';

export default class Button extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf([
      'primary',
      'secondary',
      'success',
      'danger',
      'warning',
      'info',
      'light',
      'dark',
      'link',
      'outline-primary',
      'outline-secondary',
      'outline-success',
      'outline-danger',
      'outline-warning',
      'outline-info',
      'outline-light',
      'outline-dark',
    ]).isRequired,
    // --
    className: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    icon: PropTypes.string,
    dataDismiss: PropTypes.string,
    onClick: PropTypes.func,
  };

  constructor() {
    super();
    this.domNode = React.createRef();
  }

  render() {
    const { type, text, icon, className, disabled, dataDismiss } = this.props;
    const btnClassName = cn(className, `btn btn-${type}`);
    return (
      <button
        ref={this.domNode}
        className={btnClassName}
        type="button"
        title={icon ? text : undefined}
        disabled={disabled}
        data-dismiss={dataDismiss}
        data-toggle="tooltip"
        data-placement="top"
        data-trigger="hover"
        data-animation="false"
        onClick={() => this.onClick()}
        onMouseDown={() => this.onMouseDown()}
      >
        {icon ? undefined : text}
        {this.renderIcon()}
      </button>
    );
  }

  componentDidMount() {
    $(this.domNode.current).tooltip();
  }

  // event handlers

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  onMouseDown() {
    $(this.domNode.current).tooltip('hide');
  }

  // render helpers

  renderIcon() {
    const { icon } = this.props;
    if (!icon) {
      return null;
    }
    return <i className={`fas fa-${icon}`} />;
  }
}
