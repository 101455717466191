import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import DOrderReport from '../docs/DOrderReport';
import DProfitReport from '../docs/DProfitReport';
import DProviderReport from '../docs/DProviderReport';
import DReceiptReport from '../docs/DReceiptReport';
import DStockReport from '../docs/DStockReport';
import DWorkerReport from '../docs/DWorkerReport';
import DUnappliedOrderReport from '../docs/DUnappliedOrderReport';
import MReport from '../modals/MReport';
import VoidLink from '../VoidLink';

export default class PReports extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  render() {
    const items = [
      {
        title: 'По исполнителям',
        params: {
          title: 'Отчёт по исполнителям',
          type: 'worker',
          view: DWorkerReport,
          withWorkerOption: true,
          withGroupByOrder: true,
          roles: ['boss', 'master'],
        },
      },
      {
        title: 'По выполненным заказам',
        params: {
          title: 'Отчёт по выполненным заказам',
          type: 'order',
          view: DOrderReport,
          roles: ['boss', 'logistician'],
        },
      },
      {
        title: 'По невыполненным заказам',
        params: {
          title: 'Отчёт по невыполненным заказам',
          type: 'unapplied_order',
          subtypes: [
            { value: 'worker', title: 'По исполнителям' },
            { value: 'product', title: 'По запчастям' },
          ],
          view: DUnappliedOrderReport,
          withoutDates: true,
          roles: ['boss', 'logistician', 'master'],
        },
      },
      {
        title: 'По поступлениям',
        params: {
          title: 'Отчёт по поступлениям',
          type: 'receipt',
          view: DReceiptReport,
          withProductFilter: true,
          roles: ['boss'],
        },
      },
      {
        title: 'По поставщикам',
        params: {
          title: 'Отчёт по поставщикам',
          type: 'receipt',
          view: DProviderReport,
          withProviderOption: true,
          withProductFilter: true,
          roles: ['boss', 'logistician'],
        },
      },
      {
        title: 'По реализации с прибылью',
        params: {
          title: 'Отчёт по реализации с прибылью',
          type: 'profit',
          view: DProfitReport,
          withProductFilter: true,
          roles: ['boss', 'logistician'],
        },
      },
      {
        title: 'По остаткам',
        params: {
          title: 'Ведомость материальных остатков',
          type: 'stock',
          view: DStockReport,
          withSingleDate: true,
          withProductFilter: true,
          roles: ['boss', 'logistician'],
        },
      },
    ];
    return <ul className="PReports">{items.map(({ title, params }) => this.renderItem(title, params))}</ul>;
  }

  componentDidMount() {
    const { app } = this.props;
    const today = dateFns.format(new Date(), 'YYYY-MM-DD');
    app.setupPage(null, 'Отчёты');
    app.saveToCache('reports_date1', today);
    app.saveToCache('reports_date2', today);
  }

  // event handlers

  async onSelectReport(params) {
    const { app } = this.props;
    await app.showModal(MReport, params);
  }

  // render helpers

  renderItem(title, params) {
    const { app } = this.props;
    if (params.roles && !app.hasRole(...params.roles)) {
      return null;
    }
    return (
      <li key={title}>
        <VoidLink onClick={() => this.onSelectReport(params)} text={title} />
      </li>
    );
  }
}
