import React from 'react';
import ReactDOM from 'react-dom';

import 'jquery';
import 'bootstrap';
import 'swiper';
import 'awesomplete';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'awesomplete/awesomplete.css';
import 'swiper/css/swiper.min.css';
import './style.css';

import { createApp } from './lib/app';
import App from './components/App';

const app = createApp();
window.document.title = `Tigermatic ${app.getConfig().version}`;
ReactDOM.render(<App app={app} />, document.getElementById('root'));
