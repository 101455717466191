import { isSameDay } from 'date-fns';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

import { formatDate } from '../../lib/fmt';
import { cn } from '../../lib/utils';
import MobileSchedulerTable from '../MobileSchedulerTable';
import MRecordEdit from '../modals/MRecordEdit';
import SchedulerChart from '../SchedulerChart';
import SchedulerTable from '../SchedulerTable';
import VirtualChart from '../VirtualChart';
import WorkloadTable from '../WorkloadTable';

export default class PSchedulerRecords extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { app } = this.props;
    this.state = {
      depts: app.loadFromCache('depts') || [],
      records: [],
      virtualRecords: [],
      activePost: '1',
      isModalOpened: false,
    };
  }

  render() {
    return (
      <div className="PSchedulerRecords d-lg-flex align-items-start justify-content-around">
        {this.renderWorkloadTable()}
        <div className="mr-5">
          {this.renderChart()}
          {this.renderVirtualChart()}
        </div>
        {this.renderMobileTable()}
        {this.renderTable()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    this.setupPage();
    try {
      const { items: depts } = await app.getApi().get('/depts');
      this.setState({ depts });
      app.saveToCache('depts', depts);
    } catch (err) {
      app.onError(err);
    }
    this.setupPage();
    this.setupWebSocket();
    await this.refreshData();
  }

  async componentDidUpdate(prevProps) {
    const { match } = this.props;
    const { match: prevMatch } = prevProps;
    if (prevMatch.params.deptId !== match.params.deptId) {
      this.setupPage();
      await this.refreshData();
    }
    this.showModalAndChangeLocation();
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(undefined);
    ws.registerReconnectHandler(undefined);
  }

  // event handlers

  async onChartClick(index) {
    const { app } = this.props;
    const { depts } = this.state;
    const deptId = this.getDeptId();
    const newDeptId = depts[index].id;
    if (deptId !== newDeptId) {
      app.getHistory().push(`/scheduler/${this.getDate()}/${newDeptId}`);
    }
  }

  async onAddRecord(time, postIndex) {
    const { app } = this.props;
    const date = this.getDate();
    const dept = this.getDept();
    await app.showModal(MRecordEdit, { dept, date, time, postIndex });
  }

  async onEditRecord(record) {
    const { app } = this.props;
    const date = this.getDate();
    const dept = this.getDept();
    app.getHistory().push(`/scheduler/${date}/${dept.id}?record=${record.id}`);
  }

  onChangePost(postIndex, dept) {
    const activePost = postIndex > dept.post_count ? 'В' : String(postIndex);
    this.setState({ activePost });
  }

  // render helpers

  renderChart() {
    const { app } = this.props;
    const { depts, records } = this.state;
    return (
      <SchedulerChart
        className="mb-5 d-none d-lg-block"
        depts={depts}
        app={app}
        records={records}
        deptId={this.getDeptId()}
        onClick={(element) => this.onChartClick(element)}
      />
    );
  }

  renderVirtualChart() {
    const { app } = this.props;
    const { depts, virtualRecords } = this.state;
    return (
      <VirtualChart
        className={cn('d-none mx-auto d-lg-block', virtualRecords.length && '_fade')}
        app={app}
        depts={depts}
        records={virtualRecords}
      />
    );
  }

  renderWorkloadTable() {
    const { app, match } = this.props;
    const { records, virtualRecords, activePost } = this.state;
    const dept = this.getDept();
    const virtualRecordsByDept = virtualRecords.filter((x) => x.dept_id === dept.id);
    return (
      <WorkloadTable
        app={app}
        match={match}
        activePost={activePost}
        postCount={dept.post_count}
        virtualRecords={virtualRecordsByDept}
        records={records.filter((x) => x.dept_id === dept.id)}
      />
    );
  }

  renderTable() {
    const { app } = this.props;
    const { records, virtualRecords } = this.state;
    const dept = this.getDept();
    return (
      <SchedulerTable
        app={app}
        className="d-none d-lg-block"
        dept={dept}
        isHoliday={this.isHoliday()}
        isCurrentDay={isSameDay(new Date(), this.getDate())}
        records={records.filter((x) => x.dept_id === dept.id)}
        virtualRecords={virtualRecords.filter((x) => x.dept_id === dept.id)}
        onAddRecord={(time, postIndex) => this.onAddRecord(time, postIndex)}
        onEditRecord={(record) => this.onEditRecord(record)}
      />
    );
  }

  renderMobileTable() {
    const { app } = this.props;
    const { records, virtualRecords, activePost } = this.state;
    const dept = this.getDept();
    return (
      <MobileSchedulerTable
        app={app}
        dept={dept}
        activePost={activePost}
        className={cn(this.isHoliday() && 'holiday')}
        isHoliday={this.isHoliday()}
        isCurrentDay={isSameDay(new Date(), this.getDate())}
        records={records.filter((x) => x.dept_id === dept.id)}
        virtualRecords={virtualRecords.filter((x) => x.dept_id === dept.id)}
        onAddRecord={(time, postIndex) => this.onAddRecord(time, postIndex)}
        onEditRecord={(record) => this.onEditRecord(record)}
        onChangePost={(postIndex) => this.onChangePost(postIndex, dept)}
      />
    );
  }

  // other helpers

  setupWebSocket() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'scheduler_update') {
        const { date } = msg.details;
        if (!date || date === this.getDate()) {
          await this.refreshData();
        }
      }
    });
    ws.registerReconnectHandler(async () => {
      await this.refreshData();
    });
  }

  getDate() {
    const { match } = this.props;
    return match.params.date;
  }

  getDeptId() {
    const { match } = this.props;
    return Number(match.params.deptId);
  }

  getDept() {
    const { depts } = this.state;
    const deptId = this.getDeptId();
    return depts.find((x) => x.id === deptId);
  }

  setupPage() {
    const { app } = this.props;
    const date = this.getDate();
    const dept = this.getDept();
    if (!dept) {
      return;
    }
    const holidayMarker = this.isHoliday() ? ', Выходной' : '';
    app.setupPage(`/scheduler/${date}`, `${formatDate(date)}, ${dept.name}${holidayMarker}`);
  }

  async showModal(recordId) {
    const { app } = this.props;
    const { records, virtualRecords } = this.state;
    const date = this.getDate();
    const dept = this.getDept();
    const record = records.find((x) => x.id === recordId);
    const virtualRecord = virtualRecords.find((x) => x.id === recordId);
    await app.showModal(MRecordEdit, { dept, date, record: record || virtualRecord });
  }

  async showModalAndChangeLocation() {
    const { app } = this.props;
    const { isModalOpened } = this.state;
    const location = app.getHistory().location;
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryRecordId = Number(queryParams.record);
    if (!this.hasRecords() || !queryRecordId || isModalOpened) {
      return;
    }
    const recordId = Number(queryParams.record);
    this.setState({ isModalOpened: true });
    await this.showModal(recordId);
    delete queryParams.record;
    const newParameters = qs.stringify(queryParams);
    app.getHistory().push(`${location.pathname}?${newParameters}`);
    this.setState({ isModalOpened: false });
  }

  hasRecords() {
    const { records, virtualRecords } = this.state;
    return records.length > 0 || virtualRecords.length > 0;
  }

  async refreshData() {
    const { app } = this.props;
    const api = app.getApi();
    const date = this.getDate();
    try {
      const { items: records } = await api.get('/records', { start_date: date, finish_date: date, is_virtual: false });
      const { items: virtualRecords } = await api.get('/records', { is_virtual: true });
      this.setState({ records, virtualRecords });
    } catch (err) {
      app.onError(err);
    }
  }

  isHoliday() {
    const dept = this.getDept();
    const currentDate = this.getDate();
    return dept.holidays.some((x) => isSameDay(new Date(x.date), currentDate));
  }
}
