import React from 'react';
import PropTypes from 'prop-types';

import List from '../List';

export default class PMyOrders extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  render() {
    return <div className="PMyOrders">{this.renderList()}</div>;
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage(null, 'Мои заказы');
    const items = app.loadFromCache('my_orders');
    if (items) {
      this.setState({ items });
    }
    this.setupWebSocket();
    await this.refreshData();
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(undefined);
    ws.registerReconnectHandler(undefined);
  }

  // event handlers

  onItemSelect(item) {
    const { app } = this.props;
    app.getHistory().push(`/my/orders/${item.id}`);
  }

  // render helpers

  renderList() {
    const { app } = this.props;
    const { items } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        roomy={app.isMobile()}
        onItemSelect={(item) => this.onItemSelect(item)}
      />
    );
  }

  // other helpers

  setupWebSocket() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'order_update') {
        this.refreshData();
      }
    });
    ws.registerReconnectHandler(async () => {
      this.refreshData();
    });
  }

  getColumns() {
    return [
      {
        name: 'Гос. номер',
        value: (item) => item.vehicle_reg,
        headClassName: 'w-120px',
      },
      {
        name: 'Автомобиль',
        value: (item) => `${item.vehicle_brand} ${item.vehicle_model}`,
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/orders');
      app.saveToCache('my_orders', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }
}
