import PropTypes from 'prop-types';
import React from 'react';

import { countWorkload, getRecordsByCell, getTimeSteps } from '../services/scheduler.service';

const HOURS = [10, 12, 14, 16, 18];

export default class WorkloadTable extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    postCount: PropTypes.number.isRequired,
    records: PropTypes.array.isRequired,
    virtualRecords: PropTypes.array.isRequired,
    activePost: PropTypes.string.isRequired,
  };

  render() {
    return (
      <table className="WorkloadTable d-lg-none">
        {this.renderTableHead()}
        {this.renderTableBody()}
      </table>
    );
  }

  // render helpers

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th />
          {HOURS.map((x, i) => (
            <th key={i} colSpan="4">
              {x}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  renderTableBody() {
    const posts = this.getPosts();
    return (
      <tbody>
        {posts.map((x, i) => (
          <tr key={i}>{this.renderTableRow(x, i + 1)}</tr>
        ))}
      </tbody>
    );
  }

  renderTableRow(post) {
    const { app, activePost } = this.props;
    const timeSteps = getTimeSteps(app);
    const className = activePost === post.title ? '_active' : '';
    return (
      <React.Fragment>
        {
          <td className={className} key={post.title}>
            {post.title}
          </td>
        }
        {timeSteps.map((x, index) => this.renderTableCell(x, post, index))}
      </React.Fragment>
    );
  }

  renderTableCell(time, post, index) {
    const { app, records, virtualRecords } = this.props;
    const recordsByCell = getRecordsByCell(app, records, time, post);
    let isEmpty = time ? recordsByCell.length === 0 : false;
    if (post.index === 0) {
      isEmpty = index >= countWorkload(app, virtualRecords);
    }
    let className = isEmpty ? '' : '_filled';
    return <td key={time} className={className}></td>;
  }

  // other helpers

  getPosts() {
    const { postCount } = this.props;
    const posts = [];
    for (let i = 1; i <= postCount; i++) {
      posts.push({ title: String(i), index: i });
    }
    posts.push({ title: 'В', index: 0 });
    return posts;
  }
}
