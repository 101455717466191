import { format, getYear, isSameDay } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatMonth } from '../../lib/fmt';
import Button from '../Button';
import FormGroup from '../FormGroup';
import { HolidayCalendar } from '../HolidayCalendar';
import MHolidaysGenerate from './MHolidaysGenerate';

export default class MDeptCalendar extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    dept: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.processing = {};
    this.state = {
      dept: props.dept,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.refreshData();
  }

  // event handlers

  async onOpenGenerate() {
    const { app, dept } = this.props;
    const freshDept = await app.showModal(MHolidaysGenerate, { dept });
    if (freshDept) {
      this.setState({ dept: freshDept });
    }
  }

  onFormChange(form) {
    this.setState({ form });
  }

  async onHolidayChange(day) {
    const dayString = format(day, 'YYYY-MM-DD');
    if (this.processing[dayString]) {
      return;
    }
    this.processing[dayString] = true;
    const { app } = this.props;
    const { dept } = this.state;
    try {
      const holiday = dept.holidays.find((x) => isSameDay(new Date(x.date), day));
      if (holiday) {
        await app.getApi().delete(`/holidays/${holiday.id}`);
      } else {
        await app.getApi().post('/holidays', {
          date: dayString,
          dept_id: dept.id,
        });
      }
      await this.refreshData();
    } catch (err) {
      app.onError(err);
    }
    this.processing[dayString] = false;
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const { dept } = this.state;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{dept.name}: График</h5>
        <button type="button" className="close" onClick={() => close(dept)}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    const calendarDate = new Date(form.year, form.month, 1);
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            name="year"
            options={this.getYearOptions()}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="select"
            name="month"
            options={this.getMonthOptions()}
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">{this.renderCalendar(calendarDate)}</div>
        {this.renderButtons()}
      </form>
    );
  }

  renderButtons() {
    return (
      <div className="d-flex flex-100 justify-content-center">
        <Button
          className="w-100"
          type="outline-secondary"
          text="Генерация графика"
          onClick={() => this.onOpenGenerate()}
        />
      </div>
    );
  }

  renderCalendar(date) {
    const { dept } = this.state;
    return (
      <HolidayCalendar
        className="col"
        date={date}
        holidays={dept.holidays}
        onChange={(day) => this.onHolidayChange(day)}
      />
    );
  }

  // other helpers

  initForm() {
    const now = new Date();
    return {
      year: now.getFullYear(),
      month: now.getMonth(),
    };
  }

  getYearOptions() {
    const nowYear = getYear(new Date());
    const options = [];
    for (let i = nowYear - 1; i <= nowYear + 1; i++) {
      options.push({ value: i, title: String(i) });
    }
    return options;
  }

  getMonthOptions() {
    const options = [];
    for (let i = 0; i <= 11; i++) {
      options.push({ value: i, title: formatMonth(i) });
    }
    return options;
  }

  async refreshData() {
    const { app, dept } = this.props;
    try {
      const { dept: freshDept } = await app.getApi().get(`/depts/${dept.id}`);
      this.setState({ dept: freshDept });
    } catch (err) {
      app.onError(err);
    }
  }
}
