import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney } from '../../lib/fmt';

export default class DProfitReport extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    date1: PropTypes.string.isRequired,
    date2: PropTypes.string.isRequired,
  };

  static generateCsv({ report, date1, date2 }) {
    const d1 = dateFns.format(date1, 'YYYYMMDD');
    const d2 = dateFns.format(date2, 'YYYYMMDD');
    const fileName = `profit_${d1}_${d2}.csv`;
    const lines = [
      [
        'index',
        'product_name',
        'product_sku_producer',
        'product_unit',
        'qty',
        'price_1',
        'amount_1',
        'price_2',
        'amount_2',
        'profit',
      ],
      ...report.items.map((item, index) => [
        index + 1,
        item.product_name,
        item.product_sku_producer,
        item.product_unit,
        item.qty,
        (item.price_1 / 100).toFixed(2),
        (item.amount_1 / 100).toFixed(2),
        (item.price_2 / 100).toFixed(2),
        (item.amount_2 / 100).toFixed(2),
        (item.profit / 100).toFixed(2),
      ]),
    ];
    return { fileName, lines };
  }

  render() {
    return (
      <div className="DProfitReport">
        {this.renderHeader()}
        {this.renderItems()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { date1, date2 } = this.props;
    const d1 = dateFns.format(date1, 'DD.MM.YYYY');
    const d2 = dateFns.format(date2, 'DD.MM.YYYY');
    const period = d1 === d2 ? d1 : `${d1} – ${d2}`;
    return (
      <div>
        <h1 className="text-center">Отчёт по реализации с прибылью</h1>
        <h2 className="text-center mb-3">({period})</h2>
      </div>
    );
  }

  renderItems() {
    const { items, qty, amount_1, amount_2, profit } = this.processReport();
    return (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th></th>
            <th>Наименование</th>
            <th>Код</th>
            <th>Ед. изм.</th>
            <th className="text-nowrap">Кол-во</th>
            <th>Цена входная</th>
            <th>Сумма входная</th>
            <th>Цена реализации</th>
            <th>Сумма реализации</th>
            <th>Прибыль</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => this.renderItem(item, index))}
          <tr className="font-weight-bold">
            <td colSpan="4">Итого:</td>
            <td>{qty}</td>
            <td></td>
            <td className="text-nowrap">{formatMoney(amount_1, true, true)}</td>
            <td></td>
            <td className="text-nowrap">{formatMoney(amount_2, true, true)}</td>
            <td className="text-nowrap">{formatMoney(profit, true, true)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderItem(item, index) {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.product_name}</td>
        <td>{item.product_sku_producer}</td>
        <td>{item.product_unit}</td>
        <td>{item.qty}</td>
        <td className="text-nowrap">{formatMoney(item.price_1, true, true)}</td>
        <td>{formatMoney(item.amount_1, true, true)}</td>
        <td className="text-nowrap">{formatMoney(item.price_2, true, true)}</td>
        <td>{formatMoney(item.amount_2, true, true)}</td>
        <td>{formatMoney(item.profit, true, true)}</td>
      </tr>
    );
  }

  // other helpers

  processReport() {
    const { report } = this.props;
    const { items } = report;
    const result = {
      items: report.items,
      qty: 0,
      amount_1: 0,
      amount_2: 0,
      profit: 0,
    };
    for (const item of items) {
      result.qty += item.qty;
      result.amount_1 += item.amount_1;
      result.amount_2 += item.amount_2;
      result.profit += item.profit;
    }
    return result;
  }
}
