export function getMap(items) {
  const map = {};
  items.forEach((item) => {
    map[item.id] = item;
  });
  return map;
}

export function getContractorAutocomplete(contractors, contractorId) {
  return contractors
    .filter((c) => c.is_client && (contractorId ? c.id !== contractorId : true))
    .map((c) => ({
      label: getContractorAutocompleteLabel(c),
      value: `ID_${c.id}`,
    }));
}

export function getVehicleAutocomplete(vehicles) {
  return vehicles.map((v) => ({
    label: getVehicleAutocompleteLabel(v),
    value: `ID_${v.id}`,
  }));
}

// private

function getContractorAutocompleteLabel(contractor) {
  const parts = [contractor.name];
  if (contractor.details.phone) {
    parts.push(contractor.details.phone);
  }
  if (contractor.details.contact_phone) {
    parts.push(contractor.details.contact_phone);
  }
  return parts.join(' ');
}

function getVehicleAutocompleteLabel(vehicle) {
  const parts = [`${vehicle.brand} ${vehicle.model}`, vehicle.reg];
  if (vehicle.vin) {
    parts.push(vehicle.vin);
  }
  return parts.join(' ');
}
