import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formHasError, formTrimAll, formValidateRequired } from '../../lib/form';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

export default class MHolidaysGenerate extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    dept: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dept: props.dept,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  async onGenerate() {
    const { app, close } = this.props;
    let { form } = this.state;
    form = formTrimAll(form);
    form = formValidateRequired(form, 'workdays');
    form = formValidateRequired(form, 'holidays');
    form = formValidateRequired(form, 'month_count');
    form = formValidateRequired(form, 'date_from');
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      await api.post(`/holidays/generate`, pd);
      await this.refreshData();
      close(this.state.dept);
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, dept } = this.props;
    const title = dept.name;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}: Генерация графика</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="number"
            name="workdays"
            label="Рабочих дней"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="number"
            name="holidays"
            label="Выходных дней"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="date"
            name="date_from"
            label="Начиная с"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="number"
            name="month_count"
            label="Количество месяцев"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Генерировать" disabled={isLoading} onClick={() => this.onGenerate()} />
      </div>
    );
  }

  // other helpers

  initForm() {
    return {
      month_count: 1,
      workdays: 3,
      holidays: 2,
      date_from: format(new Date(), 'YYYY-MM-DD'),
    };
  }

  getPostData(form) {
    const { dept } = this.props;
    return {
      month_count: Number(form.month_count),
      workdays: Number(form.workdays),
      holidays: Number(form.holidays),
      date_from: form.date_from,
      dept_id: dept.id,
    };
  }

  async refreshData() {
    const { app, dept } = this.props;
    try {
      const { dept: freshDept } = await app.getApi().get(`/depts/${dept.id}`);
      this.setState({ dept: freshDept });
    } catch (err) {
      app.onError(err);
    }
  }
}
