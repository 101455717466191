import PropTypes from 'prop-types';
import React from 'react';

import { formatDate, formatInt } from '../../lib/fmt';
import { getLegalEntityDetails } from '../../services/order.service';

export default class DHandoverAct extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="DHandoverAct">
        {this.renderHeader()}
        {this.renderCheckup()}
        {this.renderTexts()}
        {this.renderFooter()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { app, order } = this.props;
    const legalEntityDetails = getLegalEntityDetails(app, order);
    return (
      <div>
        <h1 className="text-center">Клубный сервис TigerBox</h1>
        <div className="text-center mb-3">
          Большая Филевская 3 стр. 1А, {legalEntityDetails.phoneNumber}, {legalEntityDetails.name},{' '}
          {legalEntityDetails.registrationNumber}
        </div>
        <h2 className="text-center">
          Акт приёмки-передачи автомобиля в ремонт
          <br />к заказ-наряду № {order.id} от {formatDate(order.created_at)}
        </h2>
        <hr />
        <div className="row">
          <div className="col">
            <dl className="row">
              <dt className="col-4">Потребитель</dt>
              <dd className="col-8">{order.contractor_name}</dd>
              <dt className="col-4">Модель</dt>
              <dd className="col-8">
                {order.vehicle_brand} {order.vehicle_model}
              </dd>
              <dt className="col-4">Год выпуска</dt>
              <dd className="col-8">{order.vehicle_year}</dd>
              <dt className="col-4">Цвет</dt>
              <dd className="col-8">{order.vehicle_color || '–'}</dd>
            </dl>
          </div>
          <div className="col">
            <dl className="row">
              <dt className="col-4">VIN</dt>
              <dd className="col-8">{order.vehicle_vin || '–'}</dd>
              <dt className="col-4">Гос. номер</dt>
              <dd className="col-8">{order.vehicle_reg}</dd>
              <dt className="col-4">Пробег</dt>
              <dd className="col-8">{order.mileage ? `${formatInt(order.mileage, true)} км` : '–'}</dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }

  renderCheckup() {
    return (
      <table className="table table-bordered text-center">
        <tbody>
          <tr>
            <td className="w-25">
              <strong>Комплектность автомобиля</strong>
            </td>
            <td className="w-75">
              <strong>Наружный осмотр состояния автомобиля</strong>
              <br />
              <img className="img-fluid" src="/img/ha_vehicle.jpg" alt="Автомобиль" />
              <br />
              В - Вмятина; Г - Пятно; Н - Не закреплено; О - Отсутствие элемента;
              <br />П - Повреждение; С - Скол; Т - Трещина; Ц - Царапина;
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderTexts() {
    const { order } = this.props;
    return (
      <div>
        <div className="mb-3">
          <strong>Дополнительное соглашение:</strong> {order.act.supplementary_agreement || '–'}
        </div>
        <div className="mb-3">
          <strong>Неисправности автомобиля (со слов клиента):</strong> {order.act.defects || '–'}
        </div>
        <div className="mb-3">
          <strong>Заявки клиента на работы:</strong> {order.act.customer_application || '–'}
        </div>
        <div className="mb-3">
          <strong>Без моего согласия разрешаю:</strong> {order.act.without_consent || '–'}
        </div>
        <hr />
      </div>
    );
  }

  renderFooter() {
    return (
      <div className="row text-center mb-3">
        <div className="col">
          Автомобиль сдал (заказчик)
          <br />
          <br />
          {this.getSignPlaceholder(4)}
        </div>
        <div className="col">
          Автомобиль принял (мастер-приёмщик)
          <br />
          <br />
          {this.getSignPlaceholder(4)}
        </div>
      </div>
    );
  }

  // other helpers

  getSignPlaceholder(size = 1) {
    return '_'.repeat(size * 10);
  }
}
