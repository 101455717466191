export function getLegalEntityDetails(app, order) {
  if (!order.activities) {
    return {
      name: '?',
      registrationNumber: '?',
      phoneNumber: '?',
    };
  }
  const { legalEntityDetails } = app.getConfig();
  const { main, motorka } = legalEntityDetails;
  if (containsWorkerName(order.activities, motorka.condition.containsWorkerName)) {
    return {
      name: motorka.name,
      registrationNumber: motorka.registrationNumber,
      phoneNumber: motorka.phoneNumber,
    };
  }
  return {
    name: main.name,
    registrationNumber: main.registrationNumber,
    phoneNumber: main.phoneNumber,
  };
}

// private

function containsWorkerName(activities, workerName) {
  return Boolean(
    activities.find((a) => {
      return a.workers && Boolean(a.workers.find((w) => w.worker_name === workerName));
    }),
  );
}
