import * as dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { formatDateTime, formatMoney } from '../../lib/fmt';
import Button from '../Button';
import List from '../List';
import MStockMovementEdit from '../modals/MStockMovementEdit';

export default class PStock extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      search: '',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PStock">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage(null, 'Склад');
    const items = app.loadFromCache('stock/movements');
    if (items) {
      this.setState({ items });
    }
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  async onAdd() {
    const { app } = this.props;
    await app.showModal(MStockMovementEdit);
    await this.refreshData();
  }

  onItemGetClassName(stockMovement, prevStockMovement) {
    if (!prevStockMovement) {
      return undefined;
    }
    const d1 = dateFns.format(prevStockMovement.applied_at, 'YYYY-MM-DD');
    const d2 = dateFns.format(stockMovement.applied_at, 'YYYY-MM-DD');
    if (d1 === d2) {
      return undefined;
    }
    return 'List_rowWithTopSeparator';
  }

  async onItemSelect(stockMovement) {
    const { app } = this.props;
    await app.showModal(MStockMovementEdit, { stockMovement });
    await this.refreshData();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" disabled={!this.canCreate()} onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemGetClassName={(item, prevItem) => this.onItemGetClassName(item, prevItem)}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  canCreate() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  getColumns() {
    return [
      {
        name: '#',
        value: (item) => item.id,
        headClassName: 'w-80px',
      },
      {
        name: 'Тип',
        value: (item) => 'Поступление',
        headClassName: 'w-180px',
      },
      {
        name: 'Проведён',
        value: (item) => formatDateTime(item.applied_at) || '–',
        headClassName: 'w-180px',
      },
      {
        name: 'Поставщик',
        value: (item) => item.contractor_name,
      },
      {
        name: 'Сумма',
        value: (item) => formatMoney(item.amount, true, true),
        headClassName: 'w-180px',
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/stock/movements');
      app.saveToCache('stock/movements', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }
}
