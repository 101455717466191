import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import FormGroup from '../FormGroup';
import DHandoverAct from '../docs/DHandoverAct';

export default class MHandoverAct extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="MHandoverAct modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  async onPrint() {
    const { app, close, order } = this.props;
    const { form } = this.state;
    const title = `Акт приёмки-передачи к заказу # ${order.id}`;
    if (!this.isChanged(form)) {
      app.printDocument(title, DHandoverAct, { order });
      close();
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      await api.put(`/orders/${order.id}/act`, pd);
      const { order: freshOrder } = await api.get(`/orders/${order.id}`);
      app.printDocument(title, DHandoverAct, { order: freshOrder });
      close(freshOrder);
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, order } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Акт приёмки-передачи к заказу # {order.id}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="textarea"
            name="supplementary_agreement"
            label="Дополнительное соглашение"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="textarea"
            name="defects"
            label="Неисправности автомобиля (со слов клиента)"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="textarea"
            name="customer_application"
            label="Заявка клиента на работы"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="textarea"
            name="without_consent"
            label="Без моего согласия разрешаю"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Печать" disabled={isLoading} onClick={() => this.onPrint()} />
      </div>
    );
  }

  // other helpers

  initForm() {
    const { order } = this.props;
    return {
      supplementary_agreement: order.act.supplementary_agreement || '',
      defects: order.act.defects || '',
      customer_application: order.act.customer_application || '',
      without_consent: order.act.without_consent || '',
    };
  }

  getPostData(form) {
    return form;
  }

  isChanged(form) {
    const initialForm = this.initForm();
    return JSON.stringify(initialForm) !== JSON.stringify(form);
  }
}
