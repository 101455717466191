export function createApi(config, token) {
  return {
    get(path, params) {
      const url = getUrl(config, path, params);
      const options = getOptions(token, 'GET');
      return fetch(url, options).then(processResponse);
    },
    post(path, params) {
      const url = getUrl(config, path);
      const options = getOptions(token, 'POST', params);
      return fetch(url, options).then(processResponse);
    },
    put(path, params) {
      const url = getUrl(config, path);
      const options = getOptions(token, 'PUT', params);
      return fetch(url, options).then(processResponse);
    },
    delete(path) {
      const url = getUrl(config, path);
      const options = getOptions(token, 'DELETE');
      return fetch(url, options).then(processResponse);
    },
    upload(file) {
      let url = getUrl(config, '/media');
      let formData = new FormData();
      formData.append('file', file);
      const options = getOptions(token, 'POST', undefined, formData);
      return fetch(url, options).then(processResponse);
    },
  };
}

function getUrl(config, path, params) {
  const url = config.api + path;
  if (!params) {
    return url;
  }
  const pairs = Object.keys(params)
    .filter((key) => params[key] != null)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`);
  if (pairs.length === 0) {
    return url;
  }
  const query = pairs.join('&');
  return `${url}?${query}`;
}

function getOptions(token, method, params, formData) {
  const options = { method, headers: {} };
  if (token) {
    options.headers['X-Token'] = token;
  }
  if (params) {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(params);
  }
  if (formData) {
    options.body = formData;
  }
  return options;
}

function processResponse(response) {
  return response
    .text()
    .then((text) => {
      if (!text) {
        return undefined;
      }
      const first = text.charAt(0);
      return first === '{' || first === '[' ? JSON.parse(text) : text;
    })
    .then((data) => {
      if (response.status !== 200) {
        let message = `API Error ${response.status}`;
        if (data) {
          message += ` (${JSON.stringify(data)})`;
        }
        const error = Error(message);
        error.status = response.status;
        error.data = data;
        throw error;
      }
      return data;
    });
}
