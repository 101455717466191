import PropTypes from 'prop-types';
import React from 'react';

import { formatContractorRole, formatContractorType } from '../../lib/fmt';
import Button from '../Button';
import List from '../List';
import MContractorEdit from '../modals/MContractorEdit';
import MContractorView from '../modals/MContractorView';

export default class PContractors extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      search: '',
      page: 1,
    };
  }

  render() {
    return (
      <div className="PContractors">
        {this.renderTop()}
        {this.renderList()}
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage('/refs', 'Контрагенты');
    const items = app.loadFromCache('contractors');
    if (items) {
      this.setState({ items });
    }
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  async onAdd() {
    const { app } = this.props;
    const newItem = await app.showModal(MContractorEdit);
    if (!newItem) {
      return;
    }
    await app.showModal(MContractorView, { contractor: newItem });
    await this.refreshData();
  }

  async onItemSelect(contractor) {
    const { app } = this.props;
    await app.showModal(MContractorView, { contractor });
    await this.refreshData();
  }

  onPageChange(page) {
    const { app } = this.props;
    this.setState({ page });
    app.scrollToTop();
  }

  // render helpers

  renderTop() {
    const { search } = this.state;
    return (
      <div className="d-flex mb-3">
        <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
        <input
          className="form-control ml-3"
          type="text"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderList() {
    const { items, search, page } = this.state;
    return (
      <List
        columns={this.getColumns()}
        items={items}
        search={search}
        pageSize={100}
        pageNumber={page}
        onItemSelect={(item) => this.onItemSelect(item)}
        onPageChange={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
      {
        name: 'Тип',
        value: (item) => formatContractorType(item.type),
      },
      {
        name: 'Роль',
        value: (item) => formatContractorRole(item.is_client, item.is_provider),
      },
    ];
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/contractors');
      app.saveToCache('contractors', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }
}
