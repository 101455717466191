import Chart from 'chart.js';
import PropTypes from 'prop-types';
import React from 'react';

import { cn, pluralize } from '../lib/utils';
import { formatRecordDuration } from '../lib/fmt.js';
import { countWorkload } from '../services/scheduler.service';

const SEGMENT_OPACITY = 0x88;

export default class VirtualChart extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    depts: PropTypes.array.isRequired,
    records: PropTypes.array.isRequired,
    // --
    className: PropTypes.string,
  };

  chart;
  refChart = React.createRef();

  render() {
    const { className } = this.props;
    return (
      <section className={cn(className, 'VirtualChart')}>
        <canvas ref={this.refChart} />
      </section>
    );
  }

  componentDidMount() {
    this.initChart();
  }

  async componentDidUpdate() {
    const { depts } = this.props;
    this.chart.data.labels = depts.map((x) => x.name.slice(0, 1));
    this.chart.data.datasets[0].data = this.getChartData();
    this.chart.data.datasets[0].backgroundColor = depts.map((x) => x.color + SEGMENT_OPACITY.toString(16));
    this.chart.update();
  }

  // other helpers

  initChart() {
    const { app } = this.props;
    const timeStep = app.getConfig().scheduler.timeStepMinutes;
    if (!this.refChart.current) {
      return;
    }
    this.chart = new Chart(this.refChart.current, {
      type: 'bar',
      options: {
        aspectRatio: 1,
        title: { display: true, fontColor: '#000', fontSize: 16, text: 'Висяки' },
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              if (tooltipItem.datasetIndex === 0) {
                const countRecords = this.getRecordCount(tooltipItem.label);
                const currentDuration = formatRecordDuration(data.datasets[0].data[tooltipItem.index] * timeStep);
                return `${pluralize(countRecords, 'запись', 'записи', 'записей')} на ${currentDuration}`;
              }
            },
            title: (tooltipItems) => this.getDeptNameForTooltip(tooltipItems[0].label),
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
                beginAtZero: true,
                callback: (value) => formatRecordDuration(value * timeStep),
              },
            },
          ],
        },
      },
      data: {
        datasets: [{ data: [] }],
      },
    });
  }

  getChartData() {
    const { app, depts, records } = this.props;
    const workloads = depts.map((d) => {
      const filteredRecords = records.filter((r) => r.dept_id === d.id);
      const workload = countWorkload(app, filteredRecords);
      return workload;
    });
    return workloads;
  }

  getRecordCount(labelName) {
    const { records } = this.props;
    return records.filter((x) => x.dept_name.slice(0, 1) === labelName).length;
  }

  getDeptNameForTooltip(label) {
    const { depts } = this.props;
    const dept = depts.find((x) => x.name.slice(0, 1) === label);
    return dept.name;
  }
}
