import PropTypes from 'prop-types';
import React from 'react';

import Button from '../Button';

export default class MRecordCopy extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="MRecordCopy modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  // event handlers

  onCopy(text) {
    const { app } = this.props;
    app.copyToClipboard(text);
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Копировать</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { record } = this.props;
    return (
      <div className="modal-body d-flex flex-column">
        <Button className="mb-3" type="primary" onClick={() => this.onCopy(`#${record.reg}`)} text={`#${record.reg}`} />
        <Button className="mb-3" type="success" onClick={() => this.onCopy(record.phone)} text={record.phone} />
        <Button
          type="secondary"
          disabled={!record.comment}
          onClick={() => this.onCopy(record.comment)}
          text="Комментарий"
        />
      </div>
    );
  }
}
