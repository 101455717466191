import PropTypes from 'prop-types';
import React from 'react';

import { formatDrive, formatTransmissionType } from '../../lib/fmt';
import Button from '../Button';
import History from '../History';
import LabelValue from '../LabelValue';
import ModalNav from '../ModalNav';
import MContractorView from './MContractorView';
import MVehicleEdit from './MVehicleEdit';
import MVehicleTransfer from './MVehicleTransfer';

const NAV_INFO = 'info';
const NAV_HISTORY = 'history';

const NAVS = [
  [NAV_INFO, 'Информация'],
  [NAV_HISTORY, 'История'],
];

export default class MVehicleView extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    vehicle: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      vehicle: props.vehicle,
      nav: NAV_INFO,
      contractor: undefined,
      orders: [],
      claims: [],
      workers: [],
      isLoading: true,
    };
  }

  render() {
    return (
      <div className="MVehicleView modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  // event handlers

  onNavChange(nav) {
    this.setState({ nav });
  }

  async onEdit() {
    const { app } = this.props;
    const { vehicle } = this.state;
    const updatedVehicle = await app.showModal(MVehicleEdit, { vehicle });
    if (!updatedVehicle) {
      return;
    }
    this.setState({ vehicle: updatedVehicle });
  }

  async onTransfer() {
    const { app } = this.props;
    const { vehicle } = this.state;
    const updatedVehicle = await app.showModal(MVehicleTransfer, { vehicle });
    if (updatedVehicle) {
      this.setState({ vehicle: updatedVehicle });
    }
  }

  async onDelete() {
    const { app, close } = this.props;
    const { vehicle } = this.state;
    if (!window.confirm('Удалить автомобиль?')) {
      return;
    }
    try {
      await app.getApi().delete(`/vehicles/${vehicle.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  async onOwnerClick() {
    const { app } = this.props;
    const { contractor } = this.state;
    if (!contractor) {
      return;
    }
    await app.showModal(MContractorView, { contractor });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const { vehicle } = this.state;
    return (
      <div className="modal-header">
        <h5 className="modal-title">
          {vehicle.brand} {vehicle.model} – {vehicle.reg}
        </h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(nav) => this.onNavChange(nav)} />;
  }

  renderBody() {
    return <div className="modal-body">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_INFO:
        return this.renderInfo();
      case NAV_HISTORY:
        return this.renderHistory();
      default:
        return null;
    }
  }

  renderInfo() {
    const { vehicle } = this.state;
    return (
      <div>
        <div className="row">
          <LabelValue
            className="col-6"
            label="Владелец"
            value={vehicle.owner_name}
            onValueClick={() => this.onOwnerClick()}
          />
          <LabelValue className="col-6" label="Гос. номер" value={vehicle.reg} />
        </div>
        <div className="row">
          <LabelValue className="col-6" label="Год" value={`${vehicle.year}`} />
          <LabelValue className="col-6" label="VIN" value={vehicle.vin} />
        </div>
        <div className="row">
          <LabelValue className="col-6" label="Цвет" value={vehicle.color} />
          <LabelValue className="col-6" label="Торговое обозначение" value={vehicle.trade_code} />
        </div>
        <div className="row">
          <LabelValue className="col-6" label="Объем двигателя (см³)" value={vehicle.motor_volume} />
          <LabelValue className="col-6" label="Буквенное обозначение двигателя" value={vehicle.motor_code} />
        </div>
        <div className="row">
          <LabelValue className="col-6" label="Тип КПП" value={formatTransmissionType(vehicle.transmission_type)} />
          <LabelValue className="col-6" label="Буквенное обозначение КПП" value={vehicle.transmission_code} />
        </div>
        <div className="row">
          <LabelValue className="col-6" label="Тип привода" value={formatDrive(vehicle.drive)} />
          <LabelValue className="col-6" label="Идентификатор привода осей" value={vehicle.drive_code} />
        </div>
      </div>
    );
  }

  renderHistory() {
    const { app } = this.props;
    const { orders, claims, workers, isLoading } = this.state;
    return (
      <History
        app={app}
        orders={orders}
        claims={claims}
        workers={workers}
        emptyMessage="Для данного автомобиля не найдено ни заказов, ни заявок"
        isLoading={isLoading}
      />
    );
  }

  renderFooter() {
    const { nav } = this.state;
    return (
      <div className="modal-footer justify-content-start">
        {nav === NAV_INFO && <Button type="primary" text="Редактировать" icon="pen" onClick={() => this.onEdit()} />}
        {nav === NAV_INFO && (
          <Button type="info" text="Сменить владельца" icon="user" onClick={() => this.onTransfer()} />
        )}
        {nav === NAV_INFO && (
          <Button
            type="danger"
            text="Удалить"
            icon="trash"
            disabled={!this.canDelete()}
            onClick={() => this.onDelete()}
          />
        )}
      </div>
    );
  }

  // other helpers

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  async refreshData() {
    const { app, vehicle } = this.props;
    const api = app.getApi();
    try {
      const { contractor } = await api.get(`/contractors/${vehicle.owner_id}`);
      const orders = await this.fetchOrders();
      const claims = await this.fetchClaims();
      const workers = await this.fetchWorkers();
      this.setState({ contractor, orders, claims, workers, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async fetchOrders() {
    const { app, vehicle } = this.props;
    const api = app.getApi();
    const { items: orders } = await api.get('/orders', { vehicle_id: vehicle.id });
    for (const order of orders) {
      const { order: fullOrder } = await api.get(`/orders/${order.id}`);
      order.activities = fullOrder.activities;
      order.products = fullOrder.products;
    }
    return orders;
  }

  async fetchClaims() {
    const { app, vehicle } = this.props;
    const api = app.getApi();
    const { items: claims } = await api.get('/claims', { vehicle_id: vehicle.id });
    for (const claim of claims) {
      const { claim: fullClaim } = await api.get(`/claims/${claim.id}`);
      claim.activities = fullClaim.activities;
      claim.products = fullClaim.products;
    }
    return claims;
  }

  async fetchWorkers() {
    const { app } = this.props;
    const api = app.getApi();
    const { items: depts } = await api.get('/depts');
    const { items: workers } = await api.get('/workers');
    for (const worker of workers) {
      worker.dept_short = worker.dept_id ? depts.find((x) => x.id === worker.dept_id).short_name : null;
    }
    return workers;
  }
}
