import PropTypes from 'prop-types';
import React from 'react';

import { formatDate, formatInt, formatMoney, formatWh } from '../../lib/fmt';
import { parseWh } from '../../lib/parser';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';
import List from '../List';
import ModalNav from '../ModalNav';
import MOrderEdit from './MOrderEdit';

import {
  formHasError,
  formNormalizeNumber,
  formTrimAll,
  formValidateRegexp,
  formValidateRequired,
} from '../../lib/form';

const NAV_GENERAL = 'general';
const NAV_HISTORY = 'history';

const NAVS = [
  [NAV_GENERAL, 'Основное'],
  [NAV_HISTORY, 'История'],
];

export default class MActivityEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    activity: PropTypes.object,
    dirId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      nav: NAV_GENERAL,
      form: this.initForm(),
      dirs: props.app.loadFromCache('activities/dirs') || [],
      history: [],
    };
  }

  render() {
    const { activity } = this.props;
    return (
      <div className="MActivityEdit modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {activity && this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app, activity } = this.props;
    const api = app.getApi();
    try {
      const { items: dirs } = await api.get('/activities/dirs');
      let history = [];
      if (activity) {
        const resHistory = await api.get(`/activities/${activity.id}/history`);
        history = resHistory.items;
      }
      this.setState({ dirs, history });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onNavChange(nav) {
    this.setState({ nav });
  }

  onFormChange(form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, close, activity } = this.props;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formNormalizeNumber(form, 'wh');
    form = formValidateRequired(form, 'dir');
    form = formValidateRequired(form, 'name');
    form = formValidateRequired(form, 'wh');
    form = formValidateRegexp(form, 'wh', /^\d+(\.\d{1,2})?$/);
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      if (activity) {
        await api.put(`/activities/${activity.id}`, pd);
        const res = await app.getApi().get(`/activities/${activity.id}`);
        close(res.activity);
      } else {
        const { id } = await api.post('/activities', pd);
        const res = await app.getApi().get(`/activities/${id}`);
        close(res.activity);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onDelete() {
    const { app, close, activity } = this.props;
    if (!window.confirm('Удалить работу?')) {
      return;
    }
    try {
      await app.getApi().delete(`/activities/${activity.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  async onHistoryItemSelect(item) {
    const { app } = this.props;
    const { order } = await app.getApi().get(`/orders/${item.ord_id}`);
    if (!order) {
      return;
    }
    await app.showModal(MOrderEdit, { order });
  }

  // render helpers

  renderHeader() {
    const { close, activity } = this.props;
    const title = activity ? activity.name : 'Новая работа';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderNav() {
    const { nav } = this.state;
    const navs = [...NAVS];
    return <ModalNav items={navs} nav={nav} onChange={(nav) => this.onNavChange(nav)} />;
  }

  renderBody() {
    const { activity } = this.props;
    return <div className="modal-body overflow-scroll">{activity ? this.renderContent() : this.renderGeneral()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_GENERAL:
        return this.renderGeneral();
      case NAV_HISTORY:
        return this.renderHistory();
      default:
        return null;
    }
  }

  renderGeneral() {
    const { form, dirs } = this.state;
    return (
      <form>
        <div className="row">
          <FormGroup
            className="col"
            type="dir"
            dirs={dirs}
            name="dir"
            label="Папка *"
            form={form}
            disabled={!this.canEditAll()}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="name"
            label="Название *"
            form={form}
            disabled={!this.canEditAll()}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="wh"
            label="Нормо-часы *"
            form={form}
            disabled={!this.canEditAll()}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="textarea"
            name="comment"
            label="Комментарий"
            form={form}
            disabled={!this.canEditComment()}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderHistory() {
    const { history } = this.state;
    if (history.length === 0) {
      return 'Не найдено заказов с данной работой';
    }
    const columns = [
      {
        name: '#',
        value: (item) => item.ord_id,
        headClassName: 'w-120px',
      },
      {
        name: 'Дата',
        value: (item) => formatDate(item.applied_at),
      },
      {
        name: 'Нормо-часы',
        value: (item) => formatWh(item.wh),
      },
      {
        name: 'Кол-во',
        value: (item) => item.qty,
        headClassName: 'w-120px',
      },
      {
        name: 'Стоимость',
        value: (item) => formatMoney(this.getActivityCost(item), true, true),
      },
    ];
    return (
      <div>
        <List columns={columns} items={history} onItemSelect={async (item) => this.onHistoryItemSelect(item)} />
      </div>
    );
  }

  renderFooter() {
    const { close, activity } = this.props;
    const { isLoading, nav } = this.state;
    return (
      <div className="modal-footer">
        {activity && nav === NAV_GENERAL && (
          <Button
            className="mr-auto"
            type="danger"
            text="Удалить"
            icon="trash"
            disabled={!this.canDelete()}
            onClick={() => this.onDelete()}
          />
        )}
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button
          type="success"
          text="Сохранить"
          disabled={isLoading || !this.canEditComment()}
          onClick={() => this.onSave()}
        />
      </div>
    );
  }

  // other helpers

  canEditAll() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  canEditComment() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  initForm() {
    const { app, activity, dirId } = this.props;
    const config = app.getConfig();
    if (!activity) {
      return {
        dir: dirId === config.dirRootId ? '' : formatInt(dirId),
        name: '',
        wh: '',
        comment: '',
      };
    }
    return {
      dir: String(activity.dir_id),
      name: activity.name,
      wh: formatWh(activity.wh),
      comment: activity.comment,
    };
  }

  getPostData(form) {
    return {
      dir_id: Number(form.dir),
      name: form.name,
      wh: parseWh(form.wh),
      comment: form.comment,
    };
  }

  getActivityCost(item) {
    return (item.qty * item.wh_price * item.wh) / 100;
  }
}
