import PropTypes from 'prop-types';
import React from 'react';

import { getChildren } from '../lib/tree';
import { cn } from '../lib/utils';

const NEW_DIR = { id: 0, name: 'Новая папка' };

export default class DirTree extends React.Component {
  static propTypes = {
    rootId: PropTypes.number.isRequired,
    rootName: PropTypes.string.isRequired,
    maxLevel: PropTypes.number.isRequired,
    dirs: PropTypes.array.isRequired,
    selectedId: PropTypes.number.isRequired,
    // --
    className: PropTypes.string,
    onSelect: PropTypes.func,
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
  };

  render() {
    const { className, rootId, rootName } = this.props;
    const rootDir = { id: rootId, name: rootName };
    return (
      <div className={cn(className, 'DirTree', 'noselect text-secondary')}>
        {this.renderDir(rootDir, 0)}
        {this.renderDirs(rootId, 1)}
        {this.renderDir(NEW_DIR, 0)}
      </div>
    );
  }

  // event handlers

  async onSelect(dir) {
    const { onSelect, onAdd } = this.props;
    if (dir.id === NEW_DIR.id) {
      if (onAdd) {
        onAdd();
      }
    } else {
      if (onSelect) {
        onSelect(dir);
      }
    }
  }

  async onEdit(dir) {
    if (this.props.onEdit) {
      this.props.onEdit(dir);
    }
  }

  // render helpers

  renderDirs(parentId, level) {
    const { maxLevel, dirs } = this.props;
    const rendered = [];
    if (level > maxLevel) {
      return rendered;
    }
    getChildren(dirs, parentId).forEach((dir) => {
      rendered.push(this.renderDir(dir, level));
      if (this.isExpanded(dir)) {
        rendered.push(...this.renderDirs(dir.id, level + 1));
      }
    });
    return rendered;
  }

  renderDir(dir, level) {
    const { selectedId } = this.props;
    const className = cn(
      'd-flex align-items-center',
      'DirTree_item',
      selectedId === dir.id && 'DirTree_item_active',
      dir.id === NEW_DIR.id && 'text-black-50',
    );
    return (
      <div key={dir.id} className={className}>
        {this.renderIndent(level)}
        {this.renderMainIcon(dir)}
        {this.renderName(dir)}
        {this.renderEditIcon(dir)}
      </div>
    );
  }

  renderIndent(level) {
    return <div className={`DirTree_indent${level} d-inline-block`} />;
  }

  renderMainIcon(dir) {
    const { selectedId } = this.props;
    if (dir.id === NEW_DIR.id) {
      return <i className="fas fa-plus" />;
    } else if (dir.id === selectedId) {
      return <i className="fas fa-circle" />;
    } else {
      return <i className="far fa-circle" />;
    }
  }

  renderName(dir) {
    return (
      <div className="DirTree_name d-inline-block px-2" onClick={() => this.onSelect(dir)}>
        {dir.name}
      </div>
    );
  }

  renderEditIcon(dir) {
    const { rootId, selectedId } = this.props;
    if (dir.id !== selectedId) {
      return null;
    }
    if (dir.id === rootId) {
      return null;
    }
    if (dir.id === NEW_DIR.id) {
      return null;
    }
    return <i className="fas fa-pen DirTree_editIcon" title="Редактировать" onClick={() => this.onEdit(dir)} />;
  }

  // other helpers

  isExpanded(dir) {
    const { dirs, selectedId } = this.props;
    if (dir.id === selectedId) {
      return true;
    }
    const children = getChildren(dirs, dir.id);
    return children.some((x) => this.isExpanded(x));
  }
}
