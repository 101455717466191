import PropTypes from 'prop-types';
import React from 'react';

import VoidLink from '../components/VoidLink';
import { cn } from '../lib/utils';

export default class LabelValue extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    // --
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    onValueClick: PropTypes.func,
  };

  render() {
    const { label, className } = this.props;
    const lvClassName = cn('mb-3', className);
    return (
      <div className={lvClassName}>
        <div className="font-weight-bold">{label}</div>
        <div>{this.renderValue()}</div>
      </div>
    );
  }

  // render helpers

  renderValue() {
    const { value, onValueClick } = this.props;
    if (!value) {
      return '–';
    }
    if (!onValueClick) {
      return value;
    }
    return <VoidLink onClick={onValueClick} text={value} />;
  }
}
