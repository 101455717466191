import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export default class PRefs extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  render() {
    return (
      <ul className="PRefs">
        {this.renderItem('/refs/vehicles', 'Автомобили', ['boss', 'logistician', 'master'])}
        {this.renderItem('/refs/products', 'Запчасти', ['boss', 'logistician', 'master'])}
        {this.renderItem('/refs/contractors', 'Контрагенты', ['boss', 'logistician', 'master'])}
        {this.renderItem('/refs/depts', 'Подразделения', ['boss'])}
        {this.renderItem('/refs/producers', 'Производители', ['boss', 'logistician'])}
        {this.renderItem('/refs/activities', 'Работы', ['boss', 'logistician', 'master'])}
        {this.renderItem('/refs/workers', 'Сотрудники', ['boss'])}
        {this.renderItem('/refs/record_statuses', 'Статусы планировщика', ['boss', 'master'])}
      </ul>
    );
  }

  componentDidMount() {
    const { app } = this.props;
    app.setupPage(null, 'Справочники');
  }

  // render helpers

  renderItem(url, text, roles) {
    const { app } = this.props;
    return (
      app.hasRole(...roles) && (
        <li>
          <Link to={url}>{text}</Link>
        </li>
      )
    );
  }
}
