import PropTypes from 'prop-types';
import React from 'react';

import { formHasError, formTrimAll, formValidateHexColor, formValidateRequired } from '../../lib/form';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';
import MDeptCalendar from './MDeptCalendar';

export default class MDeptEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    dept: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
      dept: props.dept,
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.refreshData();
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  async onOpenCalendar() {
    const { app } = this.props;
    const { dept } = this.state;
    const freshDept = await app.showModal(MDeptCalendar, { dept });
    this.setState({ dept: freshDept });
  }

  async onSave() {
    const { app, close } = this.props;
    let { form, dept } = this.state;
    form = formTrimAll(form);
    form = formValidateRequired(form, 'name');
    form = formValidateRequired(form, 'short_name');
    form = formValidateRequired(form, 'post_count');
    form = formValidateRequired(form, 'color');
    form = formValidateHexColor(form, 'color');
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      await api.put(`/depts/${dept.id}`, pd);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, dept } = this.props;
    const title = dept.name;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="name"
            label="Название *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="short_name"
            label="Краткое название *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="number"
            name="post_count"
            label="Количество постов *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="color"
            name="color"
            label="Цвет *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer d-flex w-100 justify-content-between">
        <Button type="primary" icon="calendar-alt" onClick={() => this.onOpenCalendar()} />
        <div>
          <Button className="mr-2" type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
          <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
        </div>
      </div>
    );
  }

  // other helpers

  initForm() {
    const { dept } = this.props;
    return {
      name: dept.name,
      short_name: dept.short_name,
      post_count: dept.post_count,
      color: String(dept.color).replace(/#/g, ''),
    };
  }

  getPostData(form) {
    return {
      name: form.name,
      short_name: form.short_name,
      color: `#${form.color}`,
      post_count: Number(form.post_count),
    };
  }

  async refreshData() {
    const { app, dept } = this.props;
    try {
      const { dept: freshDept } = await app.getApi().get(`/depts/${dept.id}`);
      this.setState({ dept: freshDept });
    } catch (err) {
      app.onError(err);
    }
  }
}
