import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney, formatPaymentType } from '../../lib/fmt';
import { formHasError, formTrimAll, formValidateRequired } from '../../lib/form';
import { parseMoney, parseNumber } from '../../lib/parser';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';
import ModalNav from '../ModalNav';
import ProductGrid from '../ProductGrid';
import MProductPicker from './MProductPicker';

const NAV_INFO = 'info';
const NAV_PRODUCTS = 'products';

const NAVS = [
  [NAV_INFO, 'Информация'],
  [NAV_PRODUCTS, 'Позиции'],
];

export default class MStockMovementEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    stockMovement: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEditMode: !props.stockMovement,
      nav: NAV_INFO,
      form: this.initForm(props.stockMovement),
      stockMovement: props.stockMovement,
      stockMovementProducts: [],
      contractors: [],
    };
  }

  render() {
    return (
      <div className="MStockMovementEdit modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    let stockMovement = this.state.stockMovement;
    try {
      if (stockMovement) {
        const res = await app.getApi().get(`/stock/movements/${stockMovement.id}`);
        stockMovement = res.stockMovement;
      }
      const { items: contractors } = await app.getApi().get('/contractors');
      this.setState({
        stockMovement,
        stockMovementProducts: this.initStockMovementProducts(stockMovement),
        contractors: contractors.filter((x) => x.is_provider),
      });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onNavChange(nav) {
    this.setState({ nav });
  }

  onFormChange(form) {
    this.setState({ form });
  }

  async onSave() {
    const { app } = this.props;
    const { stockMovement, stockMovementProducts } = this.state;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formValidateRequired(form, 'contractor');
    form = formValidateRequired(form, 'payment_type');
    if (formHasError(form)) {
      this.setState({ nav: NAV_INFO, form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    if (!this.validateProducts()) {
      this.setState({ nav: NAV_PRODUCTS });
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form, stockMovementProducts);
      let id;
      if (stockMovement) {
        id = stockMovement.id;
        await api.put(`/stock/movements/${id}`, pd);
      } else {
        id = (await api.post('/stock/movements', pd)).id;
      }
      const res = await app.getApi().get(`/stock/movements/${id}`);
      this.setState({
        isLoading: false,
        isEditMode: false,
        stockMovement: res.stockMovement,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  onCancel() {
    const { close } = this.props;
    const { stockMovement } = this.state;
    if (!stockMovement) {
      close();
      return;
    }
    this.setState({
      isEditMode: false,
      form: this.initForm(stockMovement),
      stockMovementProducts: this.initStockMovementProducts(stockMovement),
    });
  }

  async onEdit() {
    const { stockMovement } = this.state;
    if (stockMovement.applied_at) {
      const msg = 'Внимание! Редактирование документа может изменить остатки на складе. Продолжить?';
      if (!window.confirm(msg)) {
        return;
      }
    }
    this.setState({ isEditMode: true });
  }

  async onApply() {
    const { app, close } = this.props;
    const { stockMovement } = this.state;
    const amount = formatMoney(stockMovement.amount, true, true);
    const msg = `Провести документ # ${stockMovement.id} на сумму ${amount}?`;
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().post(`/stock/movements/${stockMovement.id}/apply`);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onDelete() {
    const { app, close } = this.props;
    const { stockMovement } = this.state;
    const msg = stockMovement.applied_at
      ? 'Внимание! Удаление документа изменит остатки на складе. Удалить документ?'
      : 'Удалить документ?';
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().delete(`/stock/movements/${stockMovement.id}`);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onAddProducts() {
    const { app } = this.props;
    const { stockMovementProducts } = this.state;
    const usedIds = stockMovementProducts.map((item) => item.product_id);
    const selectedItems = await app.showModal(MProductPicker, {
      priceType: 1,
      usedIds,
    });
    if (!selectedItems) {
      return;
    }
    const newStockMovementProducts = selectedItems.map((item) => ({
      product_id: item.product.id,
      product_name: item.product.name,
      product_sku_producer: item.product.sku_producer,
      product_sku_original: item.product.sku_original,
      product_unit: item.product.unit,
      producer_name: item.product.producer_name,
      producer_country: item.product.producer_country,
      price: formatMoney(item.price, true),
      qty: String(item.qty),
    }));
    this.setState({
      stockMovementProducts: [...stockMovementProducts, ...newStockMovementProducts],
    });
  }

  onProductGridChange(items) {
    this.setState({ stockMovementProducts: items });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const { stockMovement } = this.state;
    const title = stockMovement ? `Документ # ${stockMovement.id}` : 'Новое поступление';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(nav) => this.onNavChange(nav)} />;
  }

  renderBody() {
    return <div className="modal-body overflow-scroll">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_INFO:
        return this.renderInfo();
      case NAV_PRODUCTS:
        return this.renderProducts();
      default:
        return null;
    }
  }

  renderInfo() {
    const { isEditMode, form } = this.state;
    const paymentOptions = [
      { value: '', title: 'Не выбран', disabled: true },
      { value: 'cash', title: formatPaymentType('cash') },
      { value: 'cashless', title: formatPaymentType('cashless') },
      { value: 'card', title: formatPaymentType('card') },
    ];
    return (
      <form>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={this.getContractorOptions()}
            name="contractor"
            label="Поставщик *"
            form={form}
            disabled={!isEditMode}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="select"
            options={paymentOptions}
            name="payment_type"
            label="Вид оплаты *"
            form={form}
            disabled={!isEditMode}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="textarea"
            name="comment"
            label="Комментарий"
            form={form}
            disabled={!isEditMode}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderProducts() {
    const { isEditMode, stockMovementProducts } = this.state;
    return (
      <ProductGrid
        items={stockMovementProducts}
        type="order"
        isEditMode={isEditMode}
        onChange={(items) => this.onProductGridChange(items)}
      />
    );
  }

  renderFooter() {
    const { isLoading, isEditMode, nav, stockMovement } = this.state;
    return (
      <div className="modal-footer">
        <div className="mr-auto">
          {!isEditMode && stockMovement && (
            <Button
              className="mr-2"
              type="primary"
              text="Редактировать"
              icon="pen"
              disabled={isLoading || !this.canEdit()}
              onClick={() => this.onEdit()}
            />
          )}
          {!isEditMode && stockMovement && !stockMovement.applied_at && (
            <Button
              className="mr-2"
              type="success"
              text="Провести"
              icon="check"
              disabled={isLoading || !this.canApply()}
              onClick={() => this.onApply()}
            />
          )}
          {!isEditMode && stockMovement && (
            <Button
              className="mr-2"
              type="danger"
              text="Удалить"
              icon="trash"
              disabled={isLoading || !this.canDelete()}
              onClick={() => this.onDelete()}
            />
          )}
          {isEditMode && nav === NAV_PRODUCTS && (
            <Button
              className="mr-2"
              type="primary"
              text="Добавить позиции"
              icon="plus"
              disabled={isLoading}
              onClick={() => this.onAddProducts()}
            />
          )}
        </div>
        {isEditMode && <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => this.onCancel()} />}
        {isEditMode && <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />}
      </div>
    );
  }

  // other helpers

  canEdit() {
    const { app, stockMovement } = this.props;
    return stockMovement && stockMovement.applied_at ? app.hasRole('boss') : app.hasRole('boss', 'logistician');
  }

  canDelete() {
    const { app, stockMovement } = this.props;
    return stockMovement && stockMovement.applied_at ? app.hasRole('boss') : app.hasRole('boss', 'logistician');
  }

  canApply() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  initForm(stockMovement) {
    if (!stockMovement) {
      return {
        contractor: '',
        payment_type: '',
        comment: '',
      };
    }
    return {
      contractor: String(stockMovement.contractor_id),
      payment_type: stockMovement.payment_type,
      comment: stockMovement.comment,
    };
  }

  initStockMovementProducts(stockMovement) {
    if (!stockMovement) {
      return [];
    }
    return stockMovement.products.map((item) => ({
      ...item,
      qty: String(item.qty),
      price: formatMoney(item.price, true),
    }));
  }

  getPostData(form, stockMovementProducts) {
    const products = stockMovementProducts.map((item) => ({
      product_id: item.product_id,
      qty: parseNumber(item.qty),
      price: parseMoney(item.price),
    }));
    return {
      type: 'in',
      contractor_id: Number(form.contractor),
      payment_type: form.payment_type,
      comment: form.comment,
      products,
    };
  }

  getContractorOptions() {
    const { stockMovement, contractors } = this.state;
    const first = { value: '', title: 'Выбрать', disabled: true };
    const options = contractors.map((p) => ({ value: p.id, title: p.name }));
    if (stockMovement && options.length === 0) {
      options.push({
        value: stockMovement.contractor_id,
        title: stockMovement.contractor_name,
      });
    }
    return [first, ...options];
  }

  validateProducts() {
    const { stockMovementProducts } = this.state;
    let count = 0;
    for (const item of stockMovementProducts) {
      const price = parseMoney(item.price);
      const qty = parseNumber(item.qty);
      if (price === undefined || price < 0) {
        asyncAlert('Неверно введено цена');
        return false;
      }
      if (qty === undefined || qty <= 0) {
        asyncAlert('Неверно введено количество');
        return false;
      }
      count += qty;
    }
    if (count === 0) {
      asyncAlert('Не добавлено ни одной позиции');
      return false;
    }
    return true;
  }
}
