import PropTypes from 'prop-types';
import React from 'react';
import { formHasError, formTrimAll, formValidateRequired } from '../../lib/form';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

export default class MProducerEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    producer: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
      countries: null,
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    try {
      const { countries } = await app.getApi().get('/producers/countries');
      this.setState({ countries });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, close, producer } = this.props;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formValidateRequired(form, 'name');
    form = formValidateRequired(form, 'country');
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      if (producer) {
        await api.put(`/producers/${producer.id}`, pd);
        const res = await app.getApi().get(`/producers/${producer.id}`);
        close(res.producer);
      } else {
        const { id } = await api.post(`/producers`, pd);
        const res = await app.getApi().get(`/producers/${id}`);
        close(res.producer);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onDelete() {
    const { app, close, producer } = this.props;
    if (!window.confirm('Удалить производителя?')) {
      return;
    }
    try {
      await app.getApi().delete(`/producers/${producer.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, producer } = this.props;
    const title = producer ? producer.name : 'Новый производитель';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form, countries } = this.state;
    return (
      <form className="modal-body">
        <FormGroup
          type="text"
          name="name"
          label="Название *"
          form={form}
          onChange={(newForm) => this.onFormChange(newForm)}
        />
        <FormGroup
          type="text"
          autocomplete={countries}
          name="country"
          label="Страна *"
          form={form}
          onChange={(newForm) => this.onFormChange(newForm)}
        />
      </form>
    );
  }

  renderFooter() {
    const { close, producer } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        {producer && (
          <Button
            className="mr-auto"
            type="danger"
            text="Удалить"
            icon="trash"
            disabled={!this.canDelete()}
            onClick={() => this.onDelete()}
          />
        )}
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  initForm() {
    const { producer } = this.props;
    if (!producer) {
      return { name: '', country: '' };
    }
    return { name: producer.name, country: producer.country };
  }

  getPostData(form) {
    return { name: form.name, country: form.country };
  }
}
