import PropTypes from 'prop-types';
import React from 'react';

import VoidLink from '../components/VoidLink';
import { cn } from '../lib/utils';

export default class ModalNav extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    nav: PropTypes.string.isRequired,
    // --
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  render() {
    const { items, className } = this.props;
    return <ul className={cn(className, 'ModalNav nav')}>{items.map((item) => this.renderItem(item))}</ul>;
  }

  // event handlers

  onItemClick(nav) {
    if (this.props.onChange) {
      this.props.onChange(nav);
    }
  }

  // render helpers

  renderItem(item) {
    const { nav } = this.props;
    const [code, title] = item;
    const className = cn('nav-link', nav === code ? 'disabled' : '');
    return (
      <li className="nav-item" key={code}>
        <VoidLink className={className} onClick={() => this.onItemClick(code)} text={title} />
      </li>
    );
  }
}
