import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney, formatWh } from '../../lib/fmt';

export default class DUnappliedOrderReport extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    subtypeReport: PropTypes.string.isRequired,
  };

  render() {
    const { subtypeReport } = this.props;
    return (
      <div className="DUnappliedOrderReport">
        {this.renderHeader()}
        {subtypeReport === 'worker' ? this.renderWorkers() : this.renderProducts()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { subtypeReport } = this.props;
    const subTitle = subtypeReport === 'worker' ? 'По исполнителям' : 'По запчастям';
    return (
      <div>
        <h1 className="text-center">Отчёт по невыполненным заказам</h1>
        <h2 className="text-center mb-3">{subTitle}</h2>
      </div>
    );
  }

  renderWorkers() {
    const { workers, wh, amount } = this.processActivitiesReport();
    return (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th>Номер</th>
            <th>Заказ</th>
            <th>Автомобиль</th>
            <th>Вид работы</th>
            <th>Н/ч</th>
            <th>Цена н/ч</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          {workers.map((w) => this.renderWorker(w))}
          <tr className="font-weight-bold">
            <td colSpan="4">Итого:</td>
            <td>{formatWh(wh)}</td>
            <td></td>
            <td>{formatMoney(amount, true, true)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderWorker(w) {
    return (
      <React.Fragment key={w.id}>
        <tr className="font-weight-bold">
          <td colSpan="7" className="table-active">
            {w.name || 'Без исполнителя'}
          </td>
        </tr>
        {w.activities.map((item, index) => this.renderActivity(item, index))}
        <tr className="font-weight-bold">
          <td colSpan="4">Итого по исполнителю:</td>
          <td>{formatWh(w.wh)}</td>
          <td></td>
          <td>{formatMoney(w.amount, true, true)}</td>
        </tr>
      </React.Fragment>
    );
  }

  renderActivity(item, index) {
    return (
      <tr key={`${item.worker_id}-${index}`}>
        <td>{index + 1}</td>
        <td>{item.ord_id}</td>
        <td>
          {item.vehicle_brand} {item.vehicle_model} - {item.vehicle_reg}
        </td>
        <td>{item.activity_name}</td>
        <td>{formatWh(item.wh)}</td>
        <td>{formatMoney(item.ord_wh_price, true, true)}</td>
        <td>{formatMoney(item.amount, true, true)}</td>
      </tr>
    );
  }

  renderProducts() {
    const { orders, qty, amount_1, amount_2 } = this.processProductsReport();
    return (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th></th>
            <th>Наименование</th>
            <th>Код</th>
            <th>Ед. изм.</th>
            <th className="text-nowrap">Кол-во</th>
            <th>Цена входная</th>
            <th>Сумма входная</th>
            <th>Цена реализации</th>
            <th>Сумма реализации</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((o) => this.renderOrder(o))}
          <tr className="font-weight-bold">
            <td colSpan="4">Итого:</td>
            <td>{qty}</td>
            <td></td>
            <td>{formatMoney(amount_1, true, true)}</td>
            <td></td>
            <td>{formatMoney(amount_2, true, true)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderOrder(ord) {
    return (
      <React.Fragment key={ord.id}>
        <tr className="font-weight-bold">
          <td colSpan="9" className="table-active">
            Заказ # {ord.id}
          </td>
        </tr>
        {ord.products.map((item, index) => this.renderProduct(item, index))}
        <tr className="font-weight-bold">
          <td colSpan="4">Итого по заказу:</td>
          <td>{ord.qty}</td>
          <td></td>
          <td>{formatMoney(ord.amount_1, true, true)}</td>
          <td></td>
          <td>{formatMoney(ord.amount_2, true, true)}</td>
        </tr>
      </React.Fragment>
    );
  }

  renderProduct(item, index) {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.product_name}</td>
        <td>{item.product_sku_producer}</td>
        <td>{item.product_unit}</td>
        <td>{item.qty}</td>
        <td className="text-nowrap">{formatMoney(item.price_1, true, true)}</td>
        <td className="text-nowrap">{formatMoney(item.amount_1, true, true)}</td>
        <td className="text-nowrap">{formatMoney(item.price_2, true, true)}</td>
        <td className="text-nowrap">{formatMoney(item.amount_2, true, true)}</td>
      </tr>
    );
  }

  // other helpers

  processActivitiesReport() {
    const { report } = this.props;
    const { items } = report;
    return this.groupActivitiesByWorker(items);
  }

  groupActivitiesByWorker(items) {
    let wh = 0;
    let amount = 0;
    const workersMap = new Map();
    items.forEach((item) => {
      if (workersMap.has(item.worker_id)) {
        const temp = workersMap.get(item.worker_id);
        workersMap.set(item.worker_id, {
          ...temp,
          wh: temp.wh + item.wh,
          amount: temp.amount + item.amount,
          activities: [...temp.activities, item],
        });
      } else {
        workersMap.set(item.worker_id, {
          id: item.worker_id,
          name: item.worker_name,
          wh: item.wh,
          amount: item.amount,
          activities: [item],
        });
      }
      wh += item.wh;
      amount += item.amount;
    });
    const workers = Array.from(workersMap.values());
    return { workers, wh, amount };
  }

  processProductsReport() {
    const { report } = this.props;
    const { items } = report;
    return this.groupProductsByOrder(items);
  }

  groupProductsByOrder(items) {
    let qty = 0;
    let amount_1 = 0;
    let amount_2 = 0;
    const ordersMap = new Map();
    items.forEach((item) => {
      if (ordersMap.has(item.ord_id)) {
        const temp = ordersMap.get(item.ord_id);
        ordersMap.set(item.ord_id, {
          ...temp,
          qty: temp.qty + item.qty,
          amount_1: temp.amount_1 + item.amount_1,
          amount_2: temp.amount_2 + item.amount_2,
          products: [...temp.products, item],
        });
      } else {
        ordersMap.set(item.ord_id, {
          id: item.ord_id,
          qty: item.qty,
          amount_1: item.amount_1,
          amount_2: item.amount_2,
          products: [item],
        });
      }
      qty += item.qty;
      amount_1 += item.amount_1;
      amount_2 += item.amount_2;
    });
    const orders = Array.from(ordersMap.values());
    return { orders, qty, amount_1, amount_2 };
  }
}
