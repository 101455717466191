import PropTypes from 'prop-types';
import React from 'react';

import { formHasError, formTrimAll, formValidateRequired } from '../../lib/form';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

const CHECKS = [{ name: 'default', title: 'Статус по умолчанию' }];

export default class MRecordStatusEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    recordStatus: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form) {
    this.setState({ form });
  }

  async onSave() {
    const { app, close, recordStatus } = this.props;
    let { form } = this.state;
    form = formTrimAll(form);
    form = formValidateRequired(form, 'name');
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      if (recordStatus) {
        await api.put(`/records/statuses/${recordStatus.id}`, pd);
      } else {
        await api.post(`/records/statuses`, pd);
      }
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onDelete() {
    const { app, close, recordStatus } = this.props;
    if (!window.confirm('Удалить статус?')) {
      return;
    }
    try {
      await app.getApi().delete(`/records/statuses/${recordStatus.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, recordStatus } = this.props;
    const title = recordStatus ? recordStatus.name : 'Новый статус';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="name"
            label="Название *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={this.getStyleOptions()}
            name="style"
            label="Цвет"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="checks"
            name="check"
            form={form}
            checks={CHECKS}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    const { close, recordStatus } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        {recordStatus && (
          <Button
            className="mr-auto"
            type="danger"
            text="Удалить"
            icon="trash"
            disabled={isLoading || !this.canDelete()}
            onClick={() => this.onDelete()}
          />
        )}
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading || !this.canEdit()} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  canEdit() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  initForm() {
    const { recordStatus } = this.props;
    if (!recordStatus) {
      return {
        name: '',
        style: '',
        check_default: '',
      };
    }
    return {
      name: recordStatus.name,
      style: recordStatus.style,
      check_default: recordStatus.is_default ? 'on' : '',
    };
  }

  getPostData(form) {
    return {
      name: form.name,
      style: form.style,
      is_default: form.check_default === 'on',
    };
  }

  getStyleOptions() {
    const { app } = this.props;
    return app.getConfig().scheduler.recordStyles;
  }
}
