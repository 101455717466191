import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import VoidLink from '../components/VoidLink';
import Dropdown from './Dropdown';

class Topbar extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  refCollapse = React.createRef();

  render() {
    const { app } = this.props;
    const backUrl = app.getBackUrl();
    const title = app.getTitle();
    return (
      <div className="Topbar bg-white position-relative">
        <div className="border-bottom d-flex px-3 py-2">
          <div className="align-items-center d-flex flex-fill">
            <h3 className="text-muted m-0">
              {this.renderBack(backUrl)}
              {title}
            </h3>
          </div>
          {this.renderMenuButton()}
          {this.renderDropdown()}
        </div>
        {this.renderCollapse()}
      </div>
    );
  }

  // render helpers

  renderBack(url) {
    if (!url) {
      return null;
    }
    return (
      <Link to={url} className="mr-3">
        <i className="fas fa-chevron-left" />
      </Link>
    );
  }

  renderDropdown() {
    const { app } = this.props;
    const user = app.getUser();
    const menu = this.getDesktopMenu();
    return (
      <Dropdown
        className="Topbar_dropdown d-none d-lg-block"
        type="link"
        text={user.name}
        items={menu.map((x) => x.text)}
        right
        onSelect={(index) => menu[index].action()}
      />
    );
  }

  renderMenuButton() {
    return (
      <button
        className="Topbar_menuButton align-self-center d-lg-none"
        data-toggle="collapse"
        data-target="#collapseNav"
      >
        <i className="fas fa-bars" />
      </button>
    );
  }

  renderCollapse() {
    const menu = this.getMobileMenu();
    return (
      <div
        className="Topbar_collapse collapse position-absolute w-100 bg-white border-bottom d-lg-none"
        id="collapseNav"
        ref={this.refCollapse}
      >
        {menu.map((item) => this.renderNavItem(item))}
      </div>
    );
  }

  renderNavItem(item) {
    return <VoidLink key={item.text} className="nav-link" onClick={() => item.action()} text={item.text} />;
  }

  // other helpers

  getDesktopMenu() {
    const { app } = this.props;
    const history = app.getHistory();
    return [
      {
        text: 'Выход',
        action: () => history.push('/login'),
      },
    ];
  }

  getMobileMenu() {
    const { app } = this.props;
    const history = app.getHistory();
    const user = app.getUser();
    const goTo = (url) => {
      this.refCollapse.current.classList.remove('show');
      history.push(url);
    };
    if (user.role === 'executor') {
      return [
        {
          text: 'Выход',
          action: () => goTo('/login'),
        },
      ];
    }
    return [
      {
        text: 'Заказы',
        action: () => goTo('/orders'),
      },
      {
        text: 'Заявки',
        action: () => goTo('/claims'),
      },
      {
        text: 'Планировщик',
        action: () => goTo('/scheduler'),
      },
      {
        text: 'Склад',
        action: () => goTo('/stock'),
      },
      {
        text: 'Отчёты',
        action: () => goTo('/reports'),
      },
      {
        text: 'Справочники',
        action: () => goTo('/refs'),
      },
      {
        text: 'Выход',
        action: () => goTo('/login'),
      },
    ];
  }
}

export default withRouter(Topbar);
