import { isAfter, isSameDay, isSameMinute, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatPhone, formatRecordDuration } from '../../lib/fmt';
import { asyncAlert } from '../../lib/utils';
import { getMap, getVehicleAutocomplete } from '../../services/autocomplete.service';
import { getTimeSteps } from '../../services/scheduler.service';
import Button from '../Button';
import FormGroup from '../FormGroup';
import MOrderClaimCreate from './MOrderClaimCreate';
import MOrderEdit from './MOrderEdit';
import MCreateUpdateInfo from './MCreateUpdateInfo';

import {
  formHasError,
  formNormalizePhone,
  formNormalizeReg,
  formResetErrors,
  formSetError,
  formTrimAll,
  formValidatePhone,
  formValidateReg,
  formValidateRequired,
} from '../../lib/form';

const CHECKS = [{ name: 'virtual', title: 'Виртуальный пост' }];

export default class MRecordEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    dept: PropTypes.object,
    record: PropTypes.object,
    date: PropTypes.string,
    time: PropTypes.string,
    postIndex: PropTypes.number,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: false,
  };

  constructor(props) {
    super(props);
    const { app } = props;
    const workers = app.loadFromCache('workers') || [];
    const depts = app.loadFromCache('depts') || [];
    const recordStatuses = app.loadFromCache('records/statuses') || [];
    this.state = {
      isLoading: false,
      form: this.initForm(recordStatuses),
      workers,
      depts,
      recordStatuses,
      contractors: [],
      vehicles: [],
      contractorMap: {},
      vehicleMap: {},
      vehicleAutocomplete: undefined,
    };
  }

  render() {
    return (
      <div className="MRecordEdit modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { record, app } = this.props;
    const api = app.getApi();
    try {
      const { items: workers } = await api.get('/workers');
      const { items: depts } = await api.get('/depts');
      const { items: recordStatuses } = await api.get('/records/statuses');
      const { items: contractors } = await api.get('/contractors');
      const { items: vehicles } = await api.get('/vehicles');
      const { form } = this.state;
      if (!record) {
        form.record_status = this.getInitialStatus(recordStatuses);
      }
      this.setState({
        workers,
        depts,
        recordStatuses,
        contractors,
        vehicles,
        contractorMap: getMap(contractors),
        vehicleMap: getMap(vehicles),
        vehicleAutocomplete: getVehicleAutocomplete(vehicles),
        form,
      });
      app.saveToCache('workers', workers);
      app.saveToCache('depts', depts);
      app.saveToCache('records/statuses', recordStatuses);
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onFormChange(newForm) {
    const { form, contractorMap, vehicleMap } = this.state;
    newForm.reg = newForm.reg.toUpperCase();
    if (newForm.reg !== form.reg || newForm.name !== form.name) {
      newForm.contractor = '';
      newForm.vehicle = '';
    }
    let contractor;
    let vehicle;
    if (newForm.reg !== form.reg) {
      const match = newForm.reg.match(/ID_(\d+)/);
      if (match) {
        const vehicleId = Number(match[1]);
        vehicle = vehicleMap[vehicleId];
      }
    }
    if (vehicle) {
      contractor = contractorMap[vehicle.owner_id];
      newForm.vehicle = vehicle.id;
      newForm.reg = vehicle.reg;
      newForm.reg = vehicle.reg;
      newForm.vehicle_brand = vehicle.brand;
      newForm.vehicle_model = vehicle.model;
    }
    if (contractor) {
      newForm.contractor = contractor.id;
      newForm.name = contractor.name;
      newForm.phone = contractor.details.phone || contractor.details.contact_phone || '';
    }
    this.setState({ form: newForm });
  }

  async onSave() {
    const { app, close, record } = this.props;
    let { form } = this.state;
    form = formResetErrors(form);
    form = formTrimAll(form);
    form = formValidateRequired(form, 'date');
    form = formValidateRequired(form, 'dept');
    form = formValidateRequired(form, 'post');
    form = formNormalizeReg(form, 'reg');
    form = formValidateRequired(form, 'reg');
    form = formValidateReg(form, 'reg');
    form = formValidateRequired(form, 'name');
    form = formNormalizePhone(form, 'phone');
    form = formValidateRequired(form, 'phone');
    form = formValidatePhone(form, 'phone');
    form = formValidateRequired(form, 'time');
    form = formValidateRequired(form, 'duration');
    form = formValidateRequired(form, 'record_status');
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    if (record && record.is_virtual && !form.check_virtual) {
      const now = new Date();
      const formDate = new Date(`${form.date} ${form.time}`);
      if (isAfter(startOfDay(now), startOfDay(formDate))) {
        form = formSetError(form, 'date');
        this.setState({ form });
        asyncAlert('Дата записи не может быть раньше текущей даты');
        return;
      }
      now.setMinutes(now.getMinutes() >= 30 ? 30 : 0);
      if (isSameDay(now, formDate) && isAfter(now, formDate) && !isSameMinute(now, formDate)) {
        form = formSetError(form, 'time');
        this.setState({ form });
        asyncAlert('Время записи не может быть раньше текущего времени');
        return;
      }
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostDataRecord(form);
      if (record) {
        await api.put(`/records/${record.id}`, pd);
      } else {
        await api.post(`/records`, pd);
      }
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
    await this.updateContractor(form);
    close();
  }

  async onCreateOrder() {
    const { app } = this.props;
    const { form, contractorMap, vehicleMap } = this.state;
    const order = await app.showModal(MOrderClaimCreate, {
      contractor: contractorMap[form.contractor],
      vehicle: vehicleMap[form.vehicle],
      type: 'order',
    });
    if (!order) {
      return;
    }
    await app.showModal(MOrderEdit, { order, isEditMode: true });
  }

  async onShowInfo() {
    const { app, record } = this.props;
    const { workers } = this.state;
    await app.showModal(MCreateUpdateInfo, {
      created_at: record.created_at,
      created_by: record.created_by,
      updated_at: record.updated_at,
      updated_by: record.updated_by,
      workers,
    });
  }

  async onDelete() {
    const { app, close, record } = this.props;
    if (!window.confirm('Удалить запись?')) {
      return;
    }
    try {
      await app.getApi().delete(`/records/${record.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, record } = this.props;
    const title = record ? `Запись # ${record.id}` : 'Новая запись';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { readOnly } = this.props;
    const { form, vehicleAutocomplete } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup
            className="col-lg-4"
            type="date"
            name="date"
            label="Дата"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-lg-4"
            type="select"
            options={this.getDeptOptions()}
            name="dept"
            label="Подразделение"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-lg-4"
            type="select"
            options={this.getPostOptions()}
            name="post"
            label="Пост"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col-lg-4"
            type="select"
            options={this.getTimeOptions()}
            name="time"
            label="Время"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-lg-4"
            type="select"
            options={this.getDurationOptions()}
            name="duration"
            label="Коридор"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-lg-4"
            type="select"
            options={this.getRecordStatusOptions()}
            name="record_status"
            label="Статус *"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col-lg-4"
            type="text"
            name="reg"
            label="Гос. номер *"
            autocomplete={vehicleAutocomplete}
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-lg-4"
            type="text"
            name="name"
            label="Имя *"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-lg-4"
            type="text"
            name="phone"
            label="Телефон *"
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="textarea"
            name="comment"
            label={this.getCommentFieldLabel()}
            form={form}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="checks"
            name="check"
            form={form}
            checks={CHECKS}
            disabled={readOnly}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close, record, readOnly } = this.props;
    const { isLoading, form } = this.state;
    return (
      <div className="modal-footer">
        {!readOnly && record && (
          <div className="mr-auto">
            <Button
              className="mr-2"
              type="primary"
              text="Создать заказ"
              icon="plus"
              disabled={isLoading || !form.contractor || !form.vehicle}
              onClick={() => this.onCreateOrder()}
            />
            <Button
              className="mr-2 MRecordEdit_infoButton"
              type="info"
              text="Информация"
              icon="info"
              disabled={isLoading}
              onClick={() => this.onShowInfo()}
            />
            <Button
              className="mr-2"
              type="danger"
              text="Удалить"
              icon="trash"
              disabled={isLoading || !this.canDelete()}
              onClick={() => this.onDelete()}
            />
          </div>
        )}
        <Button type="secondary" text={readOnly ? 'Закрыть' : 'Отмена'} disabled={isLoading} onClick={() => close()} />
        {!readOnly && <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />}
      </div>
    );
  }

  // other helpers

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  initForm(recordStatuses) {
    const { app, dept, record, date, time, postIndex } = this.props;
    const step = app.getConfig().scheduler.timeStepMinutes;
    if (!dept && !record) {
      return {};
    }
    if (!record) {
      return {
        date: date,
        dept: String(dept.id),
        post: postIndex ? String(postIndex) : '',
        contractor: '',
        vehicle: '',
        reg: '',
        name: '',
        phone: '',
        time,
        duration: step,
        record_status: this.getInitialStatus(recordStatuses),
        comment: '',
        check_virtual: '',
        vehicle_brand: '',
        vehicle_model: '',
      };
    }
    return {
      date: record.date,
      dept: String(record.dept_id),
      post: String(record.post_index),
      contractor: record.contractor_id ? String(record.contractor_id) : '',
      vehicle: record.vehicle_id ? String(record.vehicle_id) : '',
      reg: record.reg,
      name: record.name,
      phone: record.phone,
      time: record.time,
      duration: String(record.duration),
      record_status: String(record.status_id),
      comment: record.comment,
      check_virtual: record.is_virtual ? 'on' : '',
      vehicle_brand: record.vehicle_brand,
      vehicle_model: record.vehicle_model,
    };
  }

  getInitialStatus(recordStatuses) {
    if (recordStatuses.length === 0) {
      return '';
    }
    const defaultRecordStatus = recordStatuses.find((x) => x.is_default);
    return defaultRecordStatus ? String(defaultRecordStatus.id) : String(recordStatuses[0].id);
  }

  getTimeOptions() {
    const { app } = this.props;
    const timeSteps = getTimeSteps(app);
    return timeSteps.map((x) => ({ value: x, title: x.slice(0, 5) }));
  }

  getDurationOptions() {
    const { app } = this.props;
    const timeSteps = getTimeSteps(app);
    const step = app.getConfig().scheduler.timeStepMinutes;
    const options = timeSteps.map((_x, i) => {
      const value = step * (i + 1);
      return { value, title: formatRecordDuration(value) };
    });
    return options;
  }

  getDeptOptions() {
    const { record, readOnly } = this.props;
    const { depts } = this.state;
    if (readOnly || depts.length === 0) {
      return [{ value: record.dept_id, title: record.dept_name }];
    }
    return depts.map((x) => ({ value: x.id, title: x.name }));
  }

  getPostOptions() {
    const { dept, record, readOnly } = this.props;
    const options = [];
    if (readOnly) {
      return [this.getPostOption(record.post_index)];
    }
    for (let i = 1; i <= dept.post_count; i++) {
      options.push(this.getPostOption(i));
    }
    return options;
  }

  getPostOption(postNumber) {
    return { value: postNumber, title: `Пост ${postNumber}` };
  }

  getRecordStatusOptions() {
    const { record } = this.props;
    const { recordStatuses } = this.state;
    if (recordStatuses.length === 0) {
      return record ? [{ value: record.status_id, title: record.status_name }] : [];
    }
    return recordStatuses.map((x) => ({ value: x.id, title: x.name }));
  }

  getPostDataRecord(form) {
    return {
      date: form.date,
      dept_id: Number(form.dept),
      post_index: Number(form.post),
      contractor_id: Number(form.contractor) || null,
      vehicle_id: Number(form.vehicle) || null,
      reg: form.reg,
      name: form.name,
      phone: form.phone,
      time: form.time,
      duration: Number(form.duration),
      status_id: Number(form.record_status),
      comment: form.comment,
      is_virtual: form.check_virtual === 'on',
    };
  }

  getPostDataContractor(contractor, newPhone) {
    if (contractor.type === 'individual') {
      return {
        type: contractor.type,
        name: contractor.name,
        comment: contractor.comment,
        is_client: contractor.is_client,
        is_provider: contractor.is_provider,
        details: {
          ...contractor.details,
          phone: newPhone,
        },
      };
    }
    return {
      type: contractor.type,
      name: contractor.name,
      comment: contractor.comment,
      is_client: contractor.is_client,
      is_provider: contractor.is_provider,
      details: {
        ...contractor.details,
        contact_phone: newPhone,
      },
    };
  }

  async updateContractor(form) {
    const { app } = this.props;
    const { contractorMap } = this.state;
    const contractor = contractorMap[form.contractor];
    if (!contractor) {
      return;
    }
    const oldPhone = contractor.type === 'individual' ? contractor.details.phone : contractor.details.contact_phone;
    const newPhone = form.phone;
    if (oldPhone === newPhone) {
      return;
    }
    if (oldPhone) {
      const text = `Заменить номер телефона в карточке клиента с ${formatPhone(oldPhone)} на ${formatPhone(newPhone)}?`;
      if (!window.confirm(text)) {
        return;
      }
    }
    try {
      const pd = this.getPostDataContractor(contractor, newPhone);
      await app.getApi().put(`/contractors/${contractor.id}`, pd);
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  getCommentFieldLabel() {
    const {
      form: { vehicle, vehicle_brand, vehicle_model },
    } = this.state;
    if (!vehicle || !vehicle_brand || !vehicle_model) {
      return 'Комментарий';
    }
    return `Комментарий (${vehicle_brand} ${vehicle_model})`;
  }
}
