import PropTypes from 'prop-types';
import React from 'react';

import { formatDate } from '../../lib/fmt';
import Button from '../Button';
import List from '../List';
import MRecordEdit from './MRecordEdit';

const SEARCH_LIMIT = 20;
const MIN_SEARCH_LENGTH = 2;

export default class MRecordSearch extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      recordsNotFound: false,
      search: '',
      records: [],
    };
  }

  render() {
    return (
      <div className="MRecordSearch modal-dialog modal-xl">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  // event handlers

  async onSearch() {
    const { app } = this.props;
    const { search } = this.state;
    if (search.length < MIN_SEARCH_LENGTH) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      const { items: records } = await app.getApi().get('/records', { q: search, limit: SEARCH_LIMIT, sort: 'desc' });
      this.setState({
        records,
        isLoading: false,
        recordsNotFound: records.length === 0,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  onSearchChange(e) {
    this.setState({
      search: e.target.value,
      records: [],
      recordsNotFound: false,
    });
  }

  onSearchKeyDown(e) {
    if (e.key !== 'Enter') {
      return;
    }
    this.onSearch();
  }

  async onItemSelect(record) {
    const { app } = this.props;
    await app.showModal(MRecordEdit, { record, readOnly: true });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Поиск записей</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { records, recordsNotFound } = this.state;
    return (
      <div className="modal-body">
        {this.renderSearchBar()}
        {records.length !== 0 && this.renderList()}
        {recordsNotFound && this.renderNotFound()}
      </div>
    );
  }

  renderSearchBar() {
    const { search, isLoading } = this.state;
    return (
      <div className="d-flex mb-3">
        <input
          className="form-control mr-3"
          type="text"
          value={search}
          placeholder="Поиск"
          disabled={isLoading}
          autoFocus
          onChange={(e) => this.onSearchChange(e)}
          onKeyDown={(e) => this.onSearchKeyDown(e)}
        />
        <Button
          type="primary"
          icon="search"
          disabled={search.length < MIN_SEARCH_LENGTH || isLoading}
          onClick={() => this.onSearch()}
        />
      </div>
    );
  }

  renderList() {
    const { records } = this.state;
    return <List columns={this.getColumns()} items={records} onItemSelect={(item) => this.onItemSelect(item)} />;
  }

  renderNotFound() {
    return <span>Ничего не найдено</span>;
  }

  // other helpers

  getColumns() {
    return [
      {
        name: 'Дата',
        value: (item) => formatDate(item.date),
        headClassName: 'w-140px',
      },
      {
        name: 'Гос. номер',
        value: (item) => item.reg,
        headClassName: 'w-140px',
      },
      {
        name: 'Клиент',
        value: (item) => item.name,
        headClassName: 'd-none d-lg-table-cell w-300px',
        cellClassName: () => 'd-none d-lg-table-cell',
      },
      {
        name: 'Комментарий',
        value: (item) => item.comment,
        headClassName: 'd-none d-lg-table-cell',
        cellClassName: () => 'd-none d-lg-table-cell text-truncate',
      },
    ];
  }
}
