import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from '../../lib/fmt';
import { runFilter } from '../../lib/tree';
import { sortBy } from '../../lib/utils';
import Button from '../Button';
import DirPicker from '../DirPicker';
import List from '../List';
import Pager from '../Pager';
import MPriceQty from './MPriceQty';
import MProductEdit from './MProductEdit';

const PAGE_SIZE = 10;

const SORT_BY_NAME = 0;
const SORT_BY_SKU = 1;

export default class MProductPicker extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    priceType: PropTypes.number.isRequired,
    // --
    usedIds: PropTypes.array,
    selectedIds: PropTypes.array,
    shouldCheckStock: PropTypes.bool,
    shouldIgnorePriceAndQty: PropTypes.bool,
  };

  static defaultProps = {
    usedIds: [],
    selectedIds: [],
    shouldIgnorePriceAndQty: false,
  };

  constructor(props) {
    super(props);
    const { app, selectedIds } = props;
    const config = app.getConfig();
    this.state = {
      dirId: config.dirRootId,
      search: '',
      sortIndex: SORT_BY_NAME,
      page: 1,
      selected: selectedIds.map((x) => ({ id: x })),
      dirs: app.loadFromCache('products/dirs') || [],
      items: app.loadFromCache('products') || [],
    };
  }

  render() {
    return (
      <div className="MProductPicker modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshDirs();
    await this.refreshItems();
  }

  // event handlers

  onDirChange(dirId) {
    this.setState({ dirId, page: 1 });
  }

  onSearchChange(e) {
    this.setState({ search: e.target.value, page: 1 });
  }

  onItemGetClassName(product) {
    const { usedIds } = this.props;
    const { selected } = this.state;
    const item = selected.find((x) => x.id === product.id);
    if (item) {
      return 'table-success';
    }
    if (usedIds.includes(product.id)) {
      return 'table-warning';
    }
    return undefined;
  }

  onItemGetMenu(product) {
    const { app } = this.props;
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await app.showModal(MProductEdit, { product });
          await this.refreshItems();
        },
      },
      {
        name: 'Копировать',
        action: async () => {
          let newProduct;
          try {
            const name = product.name;
            const copySuffix = ' (копия)';
            const copyName = name.endsWith(copySuffix) ? name : name + copySuffix;
            const res1 = await app.getApi().post(`/products/${product.id}/copy`, {
              name: copyName,
            });
            const res2 = await app.getApi().get(`/products/${res1.id}`);
            newProduct = res2.product;
          } catch (err) {
            app.onError(err);
            return;
          }
          newProduct = await app.showModal(MProductEdit, { product: newProduct });
          if (!newProduct) {
            await this.refreshItems();
            return;
          }
          await Promise.all([this.selectItem(newProduct), this.refreshItems()]);
        },
      },
    ];
  }

  async onItemSelect(product) {
    await this.selectItem(product);
  }

  onSortChange(sortIndex) {
    this.setState({ sortIndex });
  }

  onPageChange(page) {
    this.setState({ page });
  }

  async onAddProduct() {
    const { app } = this.props;
    const { dirId } = this.state;
    const newProduct = await app.showModal(MProductEdit, { dirId });
    if (!newProduct) {
      return;
    }
    await Promise.all([this.selectItem(newProduct), this.refreshItems()]);
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Выбор запчастей</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { dirId, search, sortIndex, page, dirs } = this.state;
    const sortField = sortIndex === SORT_BY_SKU ? 'sku_producer' : undefined;
    return (
      <div className="modal-body">
        <DirPicker
          className="mb-3"
          withRoot
          dirs={dirs}
          selectedId={dirId}
          onChange={(dirId) => this.onDirChange(dirId)}
        />
        <input
          className="form-control mb-3"
          value={search}
          placeholder="Поиск"
          onChange={(e) => this.onSearchChange(e)}
        />
        <List
          columns={this.getColumns()}
          items={sortBy(this.filterItems(), sortField)}
          sortIndex={sortIndex}
          pageSize={PAGE_SIZE}
          pageNumber={page}
          noPager
          onItemGetClassName={(item) => this.onItemGetClassName(item)}
          onItemGetMenu={(item) => this.onItemGetMenu(item)}
          onItemSelect={(item) => this.onItemSelect(item)}
          onSortChange={(si) => this.onSortChange(si)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { selected } = this.state;
    return (
      <div className="modal-footer">
        <div className="mr-auto d-flex">
          <Button
            className="mr-2"
            type="primary"
            text="Новая запчасть"
            icon="plus"
            onClick={() => this.onAddProduct()}
          />
          {this.renderPager()}
        </div>
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button
          type="success"
          text="Выбрать"
          disabled={selected.length === 0}
          onClick={() => close(this.getResult())}
        />
      </div>
    );
  }

  renderPager() {
    const { page } = this.state;
    const items = this.filterItems();
    if (items.length <= PAGE_SIZE) {
      return null;
    }
    return (
      <Pager
        total={items.length}
        pageSize={PAGE_SIZE}
        pageNumber={page}
        maxButtons={7}
        onSelect={(p) => this.onPageChange(p)}
      />
    );
  }

  // other helpers

  async selectItem(product) {
    const { shouldIgnorePriceAndQty } = this.props;
    const { selected } = this.state;
    const index = selected.findIndex((x) => x.id === product.id);
    if (index !== -1) {
      const tmp = [...selected];
      tmp.splice(index, 1);
      this.setState({ selected: tmp });
      return;
    }
    if (!shouldIgnorePriceAndQty) {
      const productData = await this.getProductData(product);
      if (!productData) {
        return;
      }
      const item = {
        id: product.id,
        price: productData.price,
        qty: productData.qty,
      };
      this.setState({ selected: [...selected, item] });
    } else {
      this.setState({ selected: [...selected, { id: product.id }] });
    }
  }

  async getProductData(product) {
    const { app, priceType, shouldCheckStock } = this.props;
    const modalResult = await app.showModal(MPriceQty, {
      title: product.sku_producer,
      price: product[`price_${priceType}`],
      qty: 1,
    });
    if (!modalResult) {
      return;
    }
    if (shouldCheckStock) {
      try {
        const { product: freshProduct } = await app.getApi().get(`/products/${product.id}`);
        if (freshProduct.stock_qty - freshProduct.stock_reserve < modalResult.qty) {
          const msg =
            'Недостаточно единиц товара в наличии.\n' +
            `На складе: ${freshProduct.stock_qty}. Резерв: ${freshProduct.stock_reserve}.\n` +
            'Всё равно добавить?';
          if (!window.confirm(msg)) {
            return;
          }
        }
      } catch (err) {
        app.onError(err);
        return;
      }
    }
    return modalResult;
  }

  getColumns() {
    const { priceType } = this.props;
    return [
      {
        name: 'Название',
        value: (item) => `${item.name} (${item.producer_name})`,
        sortAvailable: true,
      },
      {
        name: 'Код',
        value: (item) => item.sku_producer,
        headClassName: 'w-140px',
        sortAvailable: true,
      },
      {
        name: 'Цена',
        value: (item) => formatMoney(item[`price_${priceType}`], true, true),
        headClassName: 'w-140px',
      },
      {
        name: 'Склад',
        value: (item) => item.stock_qty,
        headClassName: 'w-80px',
      },
      {
        name: 'Резерв',
        value: (item) => item.stock_reserve,
        headClassName: 'w-80px',
      },
    ];
  }

  filterItems() {
    const { dirId, search, dirs, items } = this.state;
    const tmpItems = runFilter(dirs, items, dirId);
    if (!search) {
      return tmpItems;
    }
    const lowerSearch = search.toLowerCase();
    return tmpItems.filter((item) => item.$.includes(lowerSearch));
  }

  async refreshItems() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/products');
      app.saveToCache('products', items);
      this.setState({ items });
    } catch (err) {
      app.onError(err);
    }
  }

  async refreshDirs() {
    const { app } = this.props;
    try {
      const { items } = await app.getApi().get('/products/dirs');
      app.saveToCache('products/dirs', items);
      this.setState({ dirs: items });
    } catch (err) {
      app.onError(err);
    }
  }

  getResult() {
    const { selected, items } = this.state;
    const result = selected.map((item) => ({
      product: items.find((p) => p.id === item.id),
      price: item.price,
      qty: item.qty,
    }));
    return result.filter((item) => item.product);
  }
}
