import PropTypes from 'prop-types';
import React from 'react';

import { formatContractorRole, formatContractorType, formatPhone } from '../../lib/fmt';
import Button from '../Button';
import History from '../History';
import LabelValue from '../LabelValue';
import List from '../List';
import ModalNav from '../ModalNav';
import MContractorEdit from './MContractorEdit';
import MVehicleEdit from './MVehicleEdit';
import MVehicleView from './MVehicleView';

const NAV_INFO = 'info';
const NAV_VEHICLES = 'vehicles';
const NAV_HISTORY = 'history';

const NAVS = [
  [NAV_INFO, 'Информация'],
  [NAV_VEHICLES, 'Автомобили'],
  [NAV_HISTORY, 'История'],
];

export default class MContractorView extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    contractor: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      contractor: props.contractor,
      nav: NAV_INFO,
      vehicles: [],
      orders: [],
      claims: [],
      workers: [],
      isLoading: true,
    };
  }

  render() {
    return (
      <div className="MContractorView modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  // event handlers

  onNavChange(nav) {
    this.setState({ nav });
  }

  async onEdit() {
    const { app } = this.props;
    const { contractor } = this.state;
    const updatedContractor = await app.showModal(MContractorEdit, { contractor });
    if (!updatedContractor) {
      return;
    }
    this.setState({ contractor: updatedContractor });
  }

  async onDelete() {
    const { app, close } = this.props;
    const { contractor } = this.state;
    if (!window.confirm('Удалить контрагента?')) {
      return;
    }
    try {
      await app.getApi().delete(`/contractors/${contractor.id}`);
      close();
    } catch (err) {
      app.onError(err);
    }
  }

  async onAddVehicle() {
    const { app } = this.props;
    const { contractor } = this.state;
    await app.showModal(MVehicleEdit, { contractor });
    await this.refreshData();
  }

  async onVehicleSelect(vehicle) {
    const { app } = this.props;
    await app.showModal(MVehicleView, { vehicle });
    await this.refreshData();
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const { contractor } = this.state;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{contractor.name}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(nav) => this.onNavChange(nav)} />;
  }

  renderBody() {
    return <div className="modal-body">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_INFO:
        return this.renderInfo();
      case NAV_VEHICLES:
        return this.renderVehicles();
      case NAV_HISTORY:
        return this.renderHistory();
      default:
        return null;
    }
  }

  renderInfo() {
    const { contractor } = this.state;
    return (
      <div>
        <div className="row">
          <LabelValue className="col-6" label="Тип" value={formatContractorType(contractor.type)} />
          <LabelValue
            className="col-6"
            label="Роль"
            value={formatContractorRole(contractor.is_client, contractor.is_provider)}
          />
        </div>
        {contractor.type === 'individual' ? this.renderIndividualInfo() : this.renderCompanyInfo()}
        <LabelValue label="Комментарий" value={contractor.comment} />
      </div>
    );
  }

  renderIndividualInfo() {
    const { contractor } = this.state;
    return (
      <div className="row">
        <LabelValue className="col-6" label="Телефон" value={formatPhone(contractor.details.phone)} />
        <LabelValue className="col-6" label="Email" value={contractor.details.email} />
      </div>
    );
  }

  renderCompanyInfo() {
    const { details } = this.state.contractor;
    return (
      <div className="row">
        <LabelValue className="col-12" label="Контактное лицо" value={details.contact_name} />
        <LabelValue className="col-6" label="Телефон" value={formatPhone(details.contact_phone)} />
        <LabelValue className="col-6" label="Email" value={details.contact_email} />
        <LabelValue className="col-12" label="ИНН" value={details.inn} />
        <LabelValue className="col-6" label="БИК" value={details.bik} />
        <LabelValue className="col-6" label="Р/с" value={details.account} />
      </div>
    );
  }

  renderVehicles() {
    const { vehicles } = this.state;
    return (
      <List columns={this.getVehicleColumns()} items={vehicles} onItemSelect={(item) => this.onVehicleSelect(item)} />
    );
  }

  renderHistory() {
    const { app } = this.props;
    const { orders, claims, workers, isLoading } = this.state;
    return (
      <History
        app={app}
        orders={orders}
        claims={claims}
        workers={workers}
        emptyMessage="Для данного клиента не найдено ни заказов, ни заявок"
        isLoading={isLoading}
      />
    );
  }

  renderFooter() {
    const { nav } = this.state;
    return (
      <div className="modal-footer justify-content-start">
        {nav === NAV_INFO && <Button type="primary" text="Редактировать" icon="pen" onClick={() => this.onEdit()} />}
        {nav === NAV_INFO && (
          <Button
            type="danger"
            text="Удалить"
            icon="trash"
            disabled={!this.canDelete()}
            onClick={() => this.onDelete()}
          />
        )}
        {nav === NAV_VEHICLES && (
          <Button type="primary" text="Добавить автомобиль" icon="plus" onClick={() => this.onAddVehicle()} />
        )}
      </div>
    );
  }

  // other helpers

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  getVehicleColumns() {
    return [
      {
        name: 'Модель',
        value: (item) => `${item.brand} ${item.model}`,
      },
      {
        name: 'Год',
        value: (item) => item.year,
      },
      {
        name: 'Гос. номер',
        value: (item) => item.reg,
      },
    ];
  }

  async refreshData() {
    const { app, contractor } = this.props;
    const api = app.getApi();
    try {
      const { items: vehicles } = await api.get('/vehicles', { owner_id: contractor.id });
      const orders = await this.fetchOrders();
      const claims = await this.fetchClaims();
      const workers = await this.fetchWorkers();
      this.setState({ vehicles, orders, claims, workers, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async fetchOrders() {
    const { app, contractor } = this.props;
    const api = app.getApi();
    const { items: orders } = await api.get('/orders', { contractor_id: contractor.id });
    for (const order of orders) {
      const { order: fullOrder } = await api.get(`/orders/${order.id}`);
      order.activities = fullOrder.activities;
      order.products = fullOrder.products;
    }
    return orders;
  }

  async fetchClaims() {
    const { app, contractor } = this.props;
    const api = app.getApi();
    const { items: claims } = await api.get('/claims', { contractor_id: contractor.id });
    for (const claim of claims) {
      const { claim: fullClaim } = await api.get(`/claims/${claim.id}`);
      claim.activities = fullClaim.activities;
      claim.products = fullClaim.products;
    }
    return claims;
  }

  async fetchWorkers() {
    const { app } = this.props;
    const api = app.getApi();
    const { items: depts } = await api.get('/depts');
    const { items: workers } = await api.get('/workers');
    for (const worker of workers) {
      worker.dept_short = worker.dept_id ? depts.find((x) => x.id === worker.dept_id).short_name : null;
    }
    return workers;
  }
}
