import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../lib/utils';

export default class PageWrapper extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    className: PropTypes.string,
  };

  render() {
    const { className } = this.props;
    const modifier = this.getModifierClassName();
    return (
      <div className={cn('PageWrapper', 'flex-fill', 'p-3', modifier, className)}>
        <div className="card">
          <div className="card-body">{this.props.children}</div>
        </div>
      </div>
    );
  }

  getModifierClassName() {
    const { app } = this.props;
    const location = app.getHistory().location;
    const regExpList = [/^\/scheduler$/, /^\/scheduler\/\d{4}-\d{2}-\d{2}(\/\d{1})?$/, /^\/my\/orders$/];
    for (const regExp of regExpList) {
      if (regExp.test(location.pathname)) {
        return '_noMobilePadding';
      }
    }
    return '';
  }
}
