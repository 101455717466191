import { addMinutes, getHours, format, isEqual, getMinutes } from 'date-fns';

const H4_HEIGHT = 40;
const RECORD_HORIZONTAL_MARGIN_SMALL = 5;
const RECORD_HORIZONTAL_MARGIN_LARGE = 12;
const RECORD_VERTICAL_MARGIN = 9;
const ROW_HEIGHT = 78;

export function convertChartValueToPercentage(value) {
  return Math.round(Math.pow(value, 2) / 100);
}

export function convertPercentageToChartValue(percentage) {
  return 10 * Math.sqrt(percentage);
}

export function countPercentageWorkload(app, workload, postCount) {
  const timeSteps = getTimeSteps(app);
  return Math.round((100 * workload) / (timeSteps.length * postCount));
}

export function countWorkload(app, records) {
  const step = app.getConfig().scheduler.timeStepMinutes;
  return records.reduce((acc, cur) => (acc += cur.duration / step), 0);
}

export function getTimeSteps(app) {
  const config = app.getConfig();
  const timeStart = new Date(1970, 1, 1, config.scheduler.timeStart.h, config.scheduler.timeStart.m);
  const timeFinish = new Date(1970, 1, 1, config.scheduler.timeFinish.h, config.scheduler.timeFinish.m);
  const step = config.scheduler.timeStepMinutes;
  let date = timeStart;
  const timeSteps = [];
  while (!isEqual(date, timeFinish)) {
    timeSteps.push(format(date, 'HH:mm:ss'));
    date = addMinutes(date, step);
  }
  return timeSteps;
}

export function getTimeOffset(app, date) {
  const config = app.getConfig();
  const timeStepMinutes = config.scheduler.timeStepMinutes;
  const timeStart = config.scheduler.timeStart.h * 60 + config.scheduler.timeStart.m;
  const currentTime = getHours(date) * 60 + getMinutes(date);
  const currentInterval = currentTime - timeStart;
  return (currentInterval * ROW_HEIGHT) / timeStepMinutes;
}

export function getPositionCard(isIntersecting, timeStepIndex) {
  const left = !isIntersecting ? RECORD_HORIZONTAL_MARGIN_SMALL : RECORD_HORIZONTAL_MARGIN_LARGE;
  const right = isIntersecting ? RECORD_HORIZONTAL_MARGIN_SMALL : RECORD_HORIZONTAL_MARGIN_LARGE;
  const top = timeStepIndex !== undefined && ROW_HEIGHT * timeStepIndex + H4_HEIGHT;
  return { left, right, top };
}

export function getPostNames(dept) {
  const posts = [];
  if (!dept) {
    return posts;
  }
  for (let i = 1; i <= dept.post_count; i++) {
    posts.push({ title: `Пост ${i}`, index: i });
  }
  posts.push({ title: 'Виртуальный пост', index: 0 });
  return posts;
}

export function getRecordHeight(app, record) {
  const initialRecordHeight = ROW_HEIGHT - RECORD_VERTICAL_MARGIN;
  if (record.is_virtual) {
    return initialRecordHeight;
  }
  const step = app.getConfig().scheduler.timeStepMinutes;
  return initialRecordHeight + ROW_HEIGHT * (record.duration / step - 1);
}

export function getRecordsByCell(app, records, timeStep, post) {
  if (!timeStep) {
    return [];
  }
  const timeSteps = getTimeSteps(app);
  const timeStepIndex = timeSteps.indexOf(timeStep);
  const step = app.getConfig().scheduler.timeStepMinutes;
  const filteredRecords = records.filter((x) => x.post_index === post.index);
  const recordsByCell = [];
  for (const record of filteredRecords) {
    const startTimeStepIndex = timeSteps.indexOf(record.time);
    const finishTimeStepIndex = startTimeStepIndex + record.duration / step;
    if (startTimeStepIndex <= timeStepIndex && timeStepIndex < finishTimeStepIndex) {
      recordsByCell.push(record);
    }
  }
  return recordsByCell;
}

export function isIntersection(currentRecord, recordsByCell) {
  if (recordsByCell.length <= 1) {
    return false;
  }
  for (const record of recordsByCell) {
    if (record.time === currentRecord.time && currentRecord.id < record.id) {
      return false;
    }
  }
  return true;
}
