import PropTypes from 'prop-types';
import React from 'react';

import { cn, sleep } from '../lib/utils';
import Pager from './Pager';
import VoidLink from './VoidLink';

export default class List extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    // --
    className: PropTypes.string,
    roomy: PropTypes.bool,
    search: PropTypes.string,
    sortIndex: PropTypes.number,
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number,
    noPager: PropTypes.bool,
    onItemFilter: PropTypes.func,
    onItemGetClassName: PropTypes.func,
    onItemGetMenu: PropTypes.func,
    onItemSelect: PropTypes.func,
    onSortChange: PropTypes.func,
    onPageChange: PropTypes.func,
  };

  static defaultProps = {
    roomy: false,
  };

  render() {
    const { className } = this.props;
    return (
      <div className={cn(className, 'List')}>
        {this.renderTable()}
        {this.renderPager()}
      </div>
    );
  }

  // event handlers

  async onRowClick(item) {
    const { onItemSelect } = this.props;
    if (onItemSelect && item) {
      onItemSelect(item);
    }
  }

  onSortChange(sortIndex) {
    const { onSortChange } = this.props;
    if (onSortChange) {
      onSortChange(sortIndex);
    }
  }

  onPageChange(page) {
    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(page);
    }
  }

  // render helpers

  renderTable() {
    const { items, roomy } = this.props;
    const className = cn('table', !roomy && 'table-sm', items.length > 0 ? 'table-hover' : '');
    return (
      <table className={className}>
        <thead>
          <tr>
            {this.renderColumns()}
            {this.renderMenuColumn()}
          </tr>
        </thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }

  renderColumns() {
    const { columns } = this.props;
    return columns.map((column, index) => this.renderColumn(column, index));
  }

  renderColumn(column, index) {
    const { sortIndex } = this.props;
    return (
      <th key={index} className={column.headClassName}>
        {column.sortAvailable && (
          <VoidLink className="text-body" onClick={() => this.onSortChange(index)} text={column.name} />
        )}
        {!column.sortAvailable && column.name}
        {index === sortIndex && <i className="fas fa-arrow-up ml-2" />}
      </th>
    );
  }

  renderMenuColumn() {
    const { onItemGetMenu } = this.props;
    if (!onItemGetMenu) {
      return null;
    }
    return <th className="w-30px"></th>;
  }

  renderRows() {
    const items = this.getDisplayItems();
    if (items.length === 0) {
      return this.renderRow();
    }
    const rows = [];
    let index = 0;
    let prevItem;
    for (const item of items) {
      rows.push(this.renderRow(item, index, prevItem));
      prevItem = item;
      index += 1;
    }
    return rows;
  }

  renderRow(item, index, prevItem) {
    const { onItemGetClassName } = this.props;
    const className = onItemGetClassName && item ? onItemGetClassName(item, prevItem) : undefined;
    return (
      <tr key={index} className={className}>
        {this.renderCells(item)}
        {this.renderMenuCell(item)}
      </tr>
    );
  }

  renderCells(item) {
    const { columns } = this.props;
    return columns.map((column, index) => this.renderCell(item, column, index));
  }

  renderCell(item, column, index) {
    if (!item) {
      return <td key={index}>&nbsp;</td>;
    }
    const value = column.value(item);
    const className = column.cellClassName ? column.cellClassName(item) : undefined;
    return (
      <td key={index} className={className} onClick={() => this.onRowClick(item)}>
        {value}
      </td>
    );
  }

  renderMenuCell(item) {
    const { onItemGetMenu } = this.props;
    if (!onItemGetMenu) {
      return null;
    }
    if (!item) {
      return <td></td>;
    }
    const menu = onItemGetMenu(item);
    return (
      <td className="text-center">
        <div className="dropdown">
          <button className="List_button" data-toggle="dropdown">
            <i className="fas fa-bars" />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {menu.map((menuItem) => this.renderMenuItem(menuItem))}
          </div>
        </div>
      </td>
    );
  }

  renderMenuItem(menuItem) {
    const clickHandler = async () => {
      await sleep(); // allows to hide the menu before `window.confirm`
      menuItem.action();
    };
    return (
      <button key={menuItem.name} className="List_button dropdown-item" onClick={clickHandler}>
        {menuItem.name}
      </button>
    );
  }

  renderPager() {
    const { pageSize, pageNumber, noPager } = this.props;
    if (noPager) {
      return null;
    }
    if (!pageSize) {
      return null;
    }
    const items = this.getMatchedItems();
    if (items.length <= pageSize) {
      return null;
    }
    return (
      <Pager
        total={items.length}
        pageSize={pageSize}
        pageNumber={pageNumber}
        onSelect={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  getDisplayItems() {
    const { pageSize, pageNumber } = this.props;
    const matchedItems = this.getMatchedItems();
    if (!pageSize) {
      return matchedItems;
    }
    const offset = (pageNumber - 1) * pageSize;
    return matchedItems.slice(offset, offset + pageSize);
  }

  getMatchedItems() {
    const { items, search, onItemFilter } = this.props;
    if (!search && !onItemFilter) {
      return items;
    }
    const lowerSearch = search.toLowerCase();
    return items.filter((item) => {
      if (search && !item.$.includes(lowerSearch)) {
        return false;
      }
      if (onItemFilter && !onItemFilter(item)) {
        return false;
      }
      return true;
    });
  }
}
