import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatInt, formatWh } from '../../lib/fmt';
import { asyncAlert, cloneObject } from '../../lib/utils';
import Button from '../Button';
import CheckListCard from '../CheckListCard';

export default class PMyOrder extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  render() {
    const { order } = this.state;
    if (!order) {
      return null;
    }
    return (
      <div className="PMyOrder">
        {this.renderOrderInfo()}
        {this.renderActivitiesList()}
        {this.renderProducts()}
        {this.renderBottom()}
      </div>
    );
  }

  async componentDidMount() {
    const { app, match } = this.props;
    app.setupPage('/my/orders', `Заказ # ${match.params.orderId}`);
    this.setupWebSocket();
    await this.refreshData();
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(undefined);
    ws.registerReconnectHandler(undefined);
  }

  // event handlers

  onActivityChange(worker, index) {
    const { app } = this.props;
    const { order } = this.state;
    let newOrder = cloneObject(order);
    const activity = order.activities[index];
    const workerIndex = activity.workers.findIndex((x) => x.worker_id === worker.id);
    newOrder.activities[index].workers[workerIndex].is_checked = !worker.checked;
    this.setState({ order: newOrder });
    try {
      app.getApi().put(`/order_activities/${activity.id}/workers/${worker.id}/is_checked`, { value: !worker.checked });
    } catch (err) {
      app.onError(err);
    }
  }

  async onProductChange(index) {
    const { app } = this.props;
    const { order } = this.state;
    let newOrder = cloneObject(order);
    const product = order.products[index];
    newOrder.products[index].is_checked = !product.is_checked;
    this.setState({ order: newOrder });
    try {
      app.getApi().put(`/order_products/${product.id}/is_checked`, { value: !product.is_checked });
    } catch (err) {
      newOrder.products[index].is_checked = !product.is_checked;
      this.setState({ order: newOrder });
      app.onError(err);
    }
  }

  async onOwnProductsChange() {
    const { app } = this.props;
    const { order } = this.state;
    let newOrder = cloneObject(order);
    newOrder.own_products_is_checked = !order.own_products_is_checked;
    this.setState({ order: newOrder });
    try {
      app.getApi().put(`/orders/${order.id}/own_products_is_checked`, { value: !order.own_products_is_checked });
    } catch (err) {
      this.setState({ order: { ...order, own_products_is_checked: order.own_products_is_checked } });
      app.onError(err);
    }
  }

  async onSendMessage(message) {
    const { app } = this.props;
    const { order } = this.state;
    this.setState({ isLoading: true });
    try {
      await app.getApi().post(`/orders/${order.id}/message`, { message });
      this.setState({ isLoading: false });
      asyncAlert('Сообщение отправлено');
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  // render helpers

  renderOrderInfo() {
    const { order } = this.state;
    return (
      <div>
        {this.renderInfo('Гос. номер', order.vehicle_reg)}
        {this.renderInfo('Aвтомобиль', `${order.vehicle_brand} ${order.vehicle_model}`)}
        {this.renderInfo('Пробег', order.mileage ? `${formatInt(order.mileage, true)} км` : '–')}
      </div>
    );
  }

  renderInfo(title, text) {
    return (
      <div key={title} className="d-flex align-items-md-start">
        <dt className="w-120px p-0">{title}:</dt>
        <dd className="p-0">{text}</dd>
      </div>
    );
  }

  renderActivitiesList() {
    const { order } = this.state;
    return (
      <div className="mt-2">
        <hr />
        <h4>Работы</h4>
        <div className="d-lg-flex flex-lg-wrap">
          {order.activities.map((x, index) => this.renderActivity(x, index))}
        </div>
      </div>
    );
  }

  renderActivity(activity, index) {
    return (
      <CheckListCard
        key={activity.id}
        className="mr-lg-3"
        header={activity.activity_name}
        type={this.getActivityType(activity.workers)}
        checks={activity.workers.map((x) => this.getWorkerData(x, activity.id))}
        onChange={(worker) => this.onActivityChange(worker, index)}
      />
    );
  }

  renderProducts() {
    const { order } = this.state;
    if (order.products.length === 0 && order.own_products.length === 0) {
      return null;
    }
    return (
      <div className="mt-2">
        <h5>Запчасти</h5>
        <div className="d-lg-flex flex-lg-wrap">
          {order.products.map((x, index) => this.renderProduct(x, index))}
          {this.renderOwnProducts()}
        </div>
      </div>
    );
  }

  renderProduct(product, index) {
    return (
      <CheckListCard
        key={`${product.id}_${index}`}
        className="mr-lg-3"
        header={product.product_name}
        type={product.is_checked ? 'success' : 'secondary'}
        onChange={() => this.onProductChange(index)}
        checks={[
          {
            name: `ord_product_${product.id}`,
            id: product.id,
            label: `${product.product_sku_producer} – ${product.qty} шт`,
            checked: product.is_checked,
          },
        ]}
      />
    );
  }

  renderOwnProducts() {
    const { order } = this.state;
    if (order.own_products.length === 0) {
      return null;
    }
    return (
      <CheckListCard
        key={`${order.id}_own`}
        header="Запчасти клиента"
        className="mr-lg-3"
        type={order.own_products_is_checked ? 'success' : 'secondary'}
        onChange={() => this.onOwnProductsChange()}
        checks={[
          {
            name: `${order.id}_own_products`,
            id: `${order.id}_own`,
            label: this.getOwnProductNames(),
            checked: order.own_products_is_checked,
          },
        ]}
      />
    );
  }

  renderBottom() {
    const { isLoading } = this.state;
    return (
      <div className="PMyOrder_buttons d-flex justify-content-between">
        <Button
          type="primary"
          className="w-50 mr-2"
          text="Готово"
          disabled={isLoading || this.isNotReady()}
          onClick={() => this.onSendMessage(this.getFullMessage('Готово - всё проверил'))}
        />
        <Button
          type="warning"
          className="w-50 ml-2"
          text="Вызов МП"
          disabled={isLoading}
          onClick={() => this.onSendMessage(this.getFullMessage('Замечания к авто'))}
        />
      </div>
    );
  }

  // other helpers

  setupWebSocket() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'order_update') {
        const { ord_id } = msg.details;
        if (ord_id === this.getOrderId()) {
          await this.refreshData();
        }
      }
    });
    ws.registerReconnectHandler(async () => {
      await this.refreshData();
    });
  }

  getOrderId() {
    const { match } = this.props;
    return Number(match.params.orderId);
  }

  isActivityDisabled(worker) {
    const { app } = this.props;
    const user = app.getUser();
    return worker.worker_id !== user.id;
  }

  getActivityType(workers) {
    const hasNotChecked = workers.some((x) => !x.is_checked);
    return hasNotChecked ? 'secondary' : 'success';
  }

  getWorkerData(worker, activityId) {
    return {
      id: worker.worker_id,
      name: `${activityId}_${worker.worker_id}`,
      label: `${worker.worker_name} – ${formatWh(worker.wh)} ч`,
      disabled: this.isActivityDisabled(worker),
      checked: worker.is_checked,
    };
  }

  getOwnProductNames() {
    const { order } = this.state;
    return order.own_products.map((x) => `${x.name} – ${x.qty} шт`).join(', ');
  }

  getFullMessage(msg) {
    const { app } = this.props;
    const { order } = this.state;
    const user = app.getUser();
    return `${dateFns.format(new Date(), 'HH:mm')} ${user.name} #${order.vehicle_reg} ${msg}`;
  }

  isNotReady() {
    const { order } = this.state;
    if (order.activities.some((x) => x.workers.some((w) => !w.is_checked))) {
      return true;
    }
    if (order.products.some((x) => !x.is_checked)) {
      return true;
    }
    if (order.own_products.length > 0 && !order.own_products_is_checked) {
      return true;
    }
    return false;
  }

  async refreshData() {
    const { app } = this.props;
    try {
      const { order } = await app.getApi().get(`/orders/${this.getOrderId()}`);
      this.setState({ order });
    } catch (err) {
      app.onError(err);
    }
  }
}
