import PropTypes from 'prop-types';
import React from 'react';
import { formatInt, formatMoney } from '../../lib/fmt';
import { parseMoney, parseNumber } from '../../lib/parser';
import { asyncAlert } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';

import {
  formHasError,
  formNormalizeMoney,
  formSetError,
  formTrimAll,
  formValidateMoney,
  formValidateRegexp,
  formValidateRequired,
} from '../../lib/form';

export default class MPriceQty extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    title: PropTypes.string,
    price: PropTypes.number,
    qty: PropTypes.number,
  };

  constructor(props) {
    super(props);
    const { price, qty } = props;
    const amount = price && qty ? price * qty : undefined;
    this.state = {
      isPricePrimary: true,
      form: {
        price: formatMoney(price, true),
        qty: formatInt(qty),
        amount: formatMoney(amount, true),
      },
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form) {
    let { isPricePrimary } = this.state;
    let { price, amount } = form;
    const { qty } = form;
    const priceChanged = price !== this.state.form.price;
    const qtyChanged = qty !== this.state.form.qty;
    const amountChanged = amount !== this.state.form.amount;
    if (priceChanged || (qtyChanged && isPricePrimary)) {
      const priceValue = parseMoney(price);
      const qtyValue = parseNumber(qty);
      amount = priceValue && qty ? formatMoney(priceValue * qtyValue, true) : '';
      isPricePrimary = true;
    } else if (amountChanged || (qtyChanged && !isPricePrimary)) {
      const qtyValue = parseNumber(qty);
      const amountValue = parseMoney(amount);
      price = qty && amountValue ? formatMoney(Math.round(amountValue / qtyValue), true) : '';
      isPricePrimary = false;
    }
    this.setState({
      isPricePrimary,
      form: { price, qty, amount },
    });
  }

  async onAdd() {
    const { close } = this.props;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formNormalizeMoney(form, 'price');
    form = formNormalizeMoney(form, 'amount');
    form = formValidateRequired(form, 'price');
    form = formValidateMoney(form, 'price');
    form = formValidateRequired(form, 'qty');
    form = formValidateRegexp(form, 'qty', /^\d+$/);
    const price = parseMoney(form.price);
    const qty = parseNumber(form.qty);
    if (price === undefined || price < 0) {
      form = formSetError(form, 'price');
    }
    if (qty === undefined || qty <= 0) {
      form = formSetError(form, 'qty');
    }
    if (formHasError(form)) {
      this.setState({ form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    close({ price, qty });
  }

  // render helpers

  renderHeader() {
    const { close, title } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title || 'Цена и количество'}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { form } = this.state;
    return (
      <form className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="price"
            label="Цена *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="qty"
            label="Количество *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="amount"
            label="Стоимость"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderFooter() {
    return (
      <div className="modal-footer">
        <Button className="w-100" type="success" text="Добавить" onClick={() => this.onAdd()} />
      </div>
    );
  }
}
