import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../lib/utils';
import VoidLink from './VoidLink';

export default class OwnProductGrid extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    checked: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    // --
    className: PropTypes.string,
    onChange: PropTypes.func,
  };

  render() {
    const { isEditMode, items } = this.props;
    const removeClassName = cn(!isEditMode && 'd-none', 'w-40px');
    const checkClassName = cn((isEditMode || items.length === 0) && 'd-none', 'w-40px');
    return (
      <table className="Grid table table-sm table-bordered">
        <thead>
          <tr>
            <th className="w-40px">#</th>
            <th>Наименование</th>
            <th className="w-80px">Кол-во</th>
            <th className={checkClassName}></th>
            <th className={removeClassName}></th>
          </tr>
        </thead>
        <tbody>{this.renderItems()}</tbody>
      </table>
    );
  }

  // event handlers

  onChange(items) {
    if (this.props.onChange) {
      this.props.onChange(items);
    }
  }

  onDelete(index) {
    const { items } = this.props;
    const newItems = [...items];
    newItems.splice(index, 1);
    this.onChange(newItems);
  }

  onNameChange(e, index) {
    const { items } = this.props;
    const item = items[index];
    const newItems = [...items];
    newItems[index] = { ...item, name: e.target.value };
    this.onChange(newItems);
  }

  onQtyChange(e, index) {
    const { items } = this.props;
    const item = items[index];
    const newItems = [...items];
    newItems[index] = { ...item, qty: e.target.value };
    this.onChange(newItems);
  }

  // render helpers

  renderItems() {
    const { items } = this.props;
    if (items.length === 0) {
      return this.renderItem();
    }
    return items.map((item, index) => this.renderItem(item, index));
  }

  renderItem(item, index) {
    const { isEditMode, checked } = this.props;
    const removeClassName = cn(!isEditMode && 'd-none');
    const checkClassName = cn(isEditMode && 'd-none');
    if (!item) {
      return (
        <tr>
          <td>&nbsp;</td>
          <td></td>
          <td></td>
          <td className={removeClassName}></td>
        </tr>
      );
    }
    const trClassName = index % 2 === 0 ? 'table-accent-bg' : '';
    return (
      <tr className={trClassName} key={index}>
        <td>{index + 1}</td>
        <td>
          <input
            className="form-control form-control-sm bg-transparent"
            value={item.name}
            disabled={!isEditMode}
            onChange={(e) => this.onNameChange(e, index)}
          />
        </td>
        <td>
          <input
            className="form-control form-control-sm bg-transparent"
            value={item.qty}
            disabled={!isEditMode}
            onChange={(e) => this.onQtyChange(e, index)}
          />
        </td>
        <td className={checkClassName}>
          <i className={cn('fas fa-check', !checked && 'opacity-10')} />
        </td>
        <td className={removeClassName}>
          <VoidLink onClick={() => this.onDelete(index)}>
            <i className="fas fa-times text-danger" />
          </VoidLink>
        </td>
      </tr>
    );
  }
}
