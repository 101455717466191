import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import DirPicker from '../DirPicker';

export default class MDirPicker extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['products', 'activities']),
    // --
    dirId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    const { app, type, dirId } = props;
    const config = app.getConfig();
    this.state = {
      dirId: dirId || config.dirRootId,
      dirs: app.loadFromCache(`${type}/dirs`) || [],
    };
  }

  render() {
    return (
      <div className="MDirPicker modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshDirs();
  }

  // event handlers

  onDirChange(dirId) {
    this.setState({ dirId });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Выбор группы</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { dirId, dirs } = this.state;
    return (
      <div className="modal-body">
        <DirPicker className="mb-3" dirs={dirs} selectedId={dirId} onChange={(dirId) => this.onDirChange(dirId)} />
      </div>
    );
  }

  renderFooter() {
    const { app, close } = this.props;
    const config = app.getConfig();
    const { dirId } = this.state;
    const isDisabled = dirId === config.dirRootId;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" onClick={() => close()} />
        <Button type="success" text="Выбрать" disabled={isDisabled} onClick={() => close(this.getResult())} />
      </div>
    );
  }

  // other helpers

  async refreshDirs() {
    const { app, type } = this.props;
    if (!type) {
      return;
    }
    try {
      const { items } = await app.getApi().get(`/${type}/dirs`);
      app.saveToCache(`${type}/dirs`, items);
      this.setState({ dirs: items });
    } catch (err) {
      app.onError(err);
    }
  }

  getResult() {
    return this.state.dirId;
  }
}
