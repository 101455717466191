import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney, formatWh } from '../lib/fmt';
import { parseWh } from '../lib/parser';
import { cn } from '../lib/utils';
import MOrderActivityEdit from './modals/MOrderActivityEdit';
import VoidLink from './VoidLink';

export default class ActivityGrid extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    whPrice: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['order', 'claim']),
    // --
    isCompact: PropTypes.bool,
    className: PropTypes.string,
    isEditMode: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    isCompact: false,
  };

  render() {
    const { items, whPrice, isEditMode, isCompact } = this.props;
    const isClaim = this.isClaim();
    const removeClassName = cn((!isEditMode || isCompact) && 'd-none', 'w-40px');
    const checkClassName = cn((isClaim || isEditMode || isCompact || items.length === 0) && 'd-none', 'w-40px');
    const n = items.length;
    const trSummaryClassName = `font-weight-bold ${n > 0 && n % 2 === 0 ? 'table-accent-bg' : ''}`;
    let wh = 0;
    items.forEach((item) => {
      wh += item.wh * item.qty;
    });
    const amount = Math.round((whPrice * wh) / 100);
    return (
      <table className="Grid table table-sm table-bordered">
        <thead>
          <tr>
            <th className="w-40px">#</th>
            <th>Наименование</th>
            {!isClaim && <th>Исполнитель</th>}
            <th className="w-80px">Н/ч</th>
            <th className="w-80px">Кол-во</th>
            <th className="w-140px">Стоимость</th>
            <th className={checkClassName}></th>
            <th className={removeClassName}></th>
          </tr>
        </thead>
        <tbody>
          {this.renderItems()}
          <tr className={trSummaryClassName}>
            <td colSpan={isClaim ? 2 : 3}>Итого:</td>
            <td colSpan="2">{formatWh(wh)}</td>
            <td>{formatMoney(amount, true, true)}</td>
            <td className={checkClassName}></td>
            <td className={removeClassName}></td>
          </tr>
        </tbody>
      </table>
    );
  }

  // event handlers

  onChange(items) {
    if (this.props.onChange) {
      this.props.onChange(items);
    }
  }

  async onEdit(index) {
    const { app, items } = this.props;
    const orderActivity = items[index];
    const newOrderActivity = await app.showModal(MOrderActivityEdit, { orderActivity });
    if (!newOrderActivity) {
      return;
    }
    const newItems = [...items];
    newItems[index] = newOrderActivity;
    this.onChange(newItems);
  }

  onDelete(index) {
    const { items } = this.props;
    const newItems = [...items];
    newItems.splice(index, 1);
    this.onChange(newItems);
  }

  onQtyChange(e, index) {
    const { items } = this.props;
    const item = items[index];
    const newItems = [...items];
    newItems[index] = { ...item, qty: e.target.value };
    this.onChange(newItems);
  }

  onWhChange(e, index) {
    const { items } = this.props;
    const item = items[index];
    const newItems = [...items];
    newItems[index] = { ...item, wh: parseWh(e.target.value) };
    this.onChange(newItems);
  }

  // render helpers

  renderItems() {
    const { items } = this.props;
    if (items.length === 0) {
      return this.renderItem();
    }
    return items.map((item, index) => this.renderItem(item, index));
  }

  renderItem(item, index) {
    const { whPrice, isEditMode, isCompact } = this.props;
    const isOrder = this.isOrder();
    const isClaim = this.isClaim();
    const removeClassName = cn((!isEditMode || isCompact) && 'd-none');
    const checkClassName = cn((isClaim || isEditMode || isCompact) && 'd-none');
    if (!item) {
      return (
        <tr>
          <td>&nbsp;</td>
          <td></td>
          {isOrder && <td></td>}
          <td></td>
          <td></td>
          <td></td>
          <td className={removeClassName}></td>
        </tr>
      );
    }
    const amount = Math.round((whPrice * item.wh * item.qty) / 100);
    const trClassName = index % 2 === 0 ? 'table-accent-bg' : '';
    return (
      <tr className={trClassName} key={index}>
        <td>{index + 1}</td>
        <td>
          {!isEditMode || !isOrder ? (
            item.activity_name
          ) : (
            <VoidLink onClick={() => this.onEdit(index)} text={item.activity_name} />
          )}
        </td>
        {isOrder && <td>{this.renderWorkers(item)}</td>}
        <td>
          {isOrder ? (
            formatWh(item.wh)
          ) : (
            <input
              className="form-control form-control-sm bg-transparent"
              defaultValue={formatWh(item.wh)}
              disabled={!isEditMode}
              onChange={(e) => this.onWhChange(e, index)}
            />
          )}
        </td>
        <td>
          <input
            className="form-control form-control-sm bg-transparent"
            value={item.qty}
            disabled={isOrder || !isEditMode}
            onChange={(e) => this.onQtyChange(e, index)}
          />
        </td>
        <td>{formatMoney(amount, true, true)}</td>
        <td className={checkClassName}>{isOrder && item.workers && item.workers.map((x) => this.renderCheck(x))}</td>
        <td className={removeClassName}>
          <VoidLink onClick={() => this.onDelete(index)}>
            <i className="fas fa-times text-danger" />
          </VoidLink>
        </td>
      </tr>
    );
  }

  renderWorkers(item) {
    const names = item.workers ? item.workers.map((w) => w.worker_name) : [];
    return names.join(', ');
  }

  renderCheck(worker) {
    const className = cn('mb-2 fas fa-check', !worker.is_checked && 'opacity-10');
    return <i key={worker.worker_id} className={className} />;
  }

  // other helpers

  isOrder() {
    const { type } = this.props;
    return type === 'order';
  }

  isClaim() {
    const { type } = this.props;
    return type === 'claim';
  }
}
