import PropTypes from 'prop-types';
import React from 'react';

import VoidLink from '../components/VoidLink';
import { getPagerButtons } from '../lib/pager';
import { cn } from '../lib/utils';

export default class Pager extends React.Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    // --
    className: PropTypes.string,
    maxButtons: PropTypes.number,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    maxButtons: 10,
  };

  render() {
    const { className } = this.props;
    return (
      <nav className={className}>
        <ul className="pagination">{this.renderPages()}</ul>
      </nav>
    );
  }

  // event handlers

  onPageClick(page) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(page);
    }
  }

  // render helpers

  renderPages() {
    const { total, pageSize, pageNumber, maxButtons } = this.props;
    const pageCount = Math.floor((total - 1) / pageSize) + 1;
    const buttons = getPagerButtons(pageNumber, pageCount, maxButtons);
    return buttons.map((p) => this.renderPage(p, p === pageNumber));
  }

  renderPage(page, isCurrent) {
    const className = cn('page-item', isCurrent && 'active', page < 0 && 'disabled');
    return (
      <li className={className} key={page}>
        <VoidLink className="page-link" onClick={() => this.onPageClick(page)}>
          {page < 0 ? '...' : page}
        </VoidLink>
      </li>
    );
  }
}
