export function asyncAlert(message) {
  setTimeout(() => {
    alert(message);
  }, 100);
}

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function coalesce(...values) {
  for (const value of values) {
    if (value !== undefined && value !== null) {
      return value;
    }
  }
  return values[values.length - 1];
}

export function cn(...parts) {
  return parts.filter((p) => p).join(' ');
}

export function esc(str) {
  str = replace(str, '&', '&amp;');
  str = replace(str, '<', '&lt;');
  str = replace(str, '>', '&gt;');
  str = replace(str, '"', '&quot;');
  str = replace(str, "'", '&#39;');
  str = replace(str, '`', '&#96;');
  return str;
}

export function replace(str, search, replacement) {
  return str.split(search).join(replacement);
}

export function sortBy(arr, field) {
  if (!field) {
    return arr;
  }
  return [...arr].sort((a, b) => {
    const x = a[field];
    const y = b[field];
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });
}

export function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function pluralize(value, w1, w2, w5, appendNumber = true) {
  const v1 = value % 10; // 123 % 10 = 3
  const v2 = value % 100; // 123 % 100 = 23
  let w = w2;
  if (v1 >= 5 || v1 === 0 || (v2 >= 11 && v2 <= 19)) {
    w = w5;
  } else if (v1 === 1) {
    w = w1;
  }
  return appendNumber ? `${value} ${w}` : w;
}

export function uniqueArray(arr) {
  const set = new Set();
  const result = [];
  for (const item of arr) {
    if (!set.has(item)) {
      result.push(item);
      set.add(item);
    }
  }
  return result;
}
