export function convertLinesToCsv(lines) {
  return lines.map(convertLineToCsv).join('\n');
}

// private

function convertLineToCsv(line) {
  return line.map(escapeCsvField).join(',');
}

function escapeCsvField(field) {
  let s = String(field);
  s = s.split('"').join('""');
  s = `"${s}"`;
  return s;
}
