import PropTypes from 'prop-types';
import React from 'react';

import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  getMonth,
  getYear,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import { formatMonth } from '../lib/fmt';
import { cn } from '../lib/utils';

export default class SchedulerCalendar extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    day: PropTypes.string.isRequired,
    records: PropTypes.array.isRequired,
    mode: PropTypes.oneOf(['m1', 'w2']),
    // --
    onDayChange: PropTypes.func,
    onSearch: PropTypes.func,
  };

  render() {
    const classNameArrowPrev = cn('text-primary', 'fas', 'fa-chevron-left', 'SchedulerCalendar_arrow', '_prev');
    const classNameArrowNext = cn('text-primary', 'fas', 'fa-chevron-right', 'SchedulerCalendar_arrow', '_next');
    const classNameSearch = cn('text-primary', 'fas', 'fa-search', 'SchedulerCalendar_search');
    return (
      <section className="SchedulerCalendar">
        <table className="table table-bordered text-center">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </table>
        <i className={classNameArrowPrev} onClick={() => this.onArrowClick('left')} />
        <i className={classNameArrowNext} onClick={() => this.onArrowClick('right')} />
        <i className={classNameSearch} onClick={() => this.onSearch()} />
      </section>
    );
  }

  // event handlers

  async onArrowClick(direction) {
    const { day, onDayChange, mode } = this.props;
    if (!onDayChange) {
      return;
    }
    const newDay =
      mode === 'm1'
        ? format(addMonths(day, direction === 'left' ? -1 : 1), 'YYYY-MM-DD')
        : format(addDays(day, direction === 'left' ? -14 : 14), 'YYYY-MM-DD');
    onDayChange(newDay);
  }

  async onDayClick(day) {
    const { onDayChange } = this.props;
    if (!onDayChange) {
      return;
    }
    onDayChange(day);
  }

  onSearch() {
    const { onSearch } = this.props;
    if (!onSearch) {
      return;
    }
    onSearch();
  }

  // render helpers

  renderTableHead() {
    const { day } = this.props;
    return (
      <thead>
        <tr>
          <th colSpan="7">
            {formatMonth(getMonth(day))} {getYear(day)}
          </th>
        </tr>
        <tr>
          <th>Пн</th>
          <th>Вт</th>
          <th>Ср</th>
          <th>Чт</th>
          <th>Пт</th>
          <th>Сб</th>
          <th>Вс</th>
        </tr>
      </thead>
    );
  }

  renderTableBody() {
    const weeks = this.getWeeks();
    return (
      <tbody>
        {weeks.map((x, index) => (
          <tr key={index}>{x.map((x) => this.renderDayCell(x))}</tr>
        ))}
      </tbody>
    );
  }

  renderDayCell(currentDay) {
    const { day, records } = this.props;
    const isActive = isSameDay(day, currentDay);
    const isMuted = !isSameMonth(day, currentDay);
    const isLoaded = records.some((x) => isSameDay(x.date, currentDay));
    const isToday = isSameDay(currentDay, new Date());
    const className = cn(
      'text-success',
      isActive && '_active',
      isMuted && 'text-muted',
      isLoaded && 'text-danger',
      isToday && 'font-weight-bold',
    );
    return (
      <td key={currentDay} className={className} onClick={() => this.onDayClick(currentDay)}>
        {format(currentDay, 'D')}
      </td>
    );
  }

  // other helpers

  getWeeks() {
    const { day, mode } = this.props;
    const weeks = [];
    const firstDay = startOfWeek(mode === 'm1' ? startOfMonth(day) : day, { weekStartsOn: 1 });
    const lastDay = endOfWeek(mode === 'm1' ? endOfMonth(day) : addDays(day, 7), { weekStartsOn: 1 });
    let currentDay = firstDay;
    let week = [];
    while (currentDay <= lastDay) {
      week.push(format(currentDay, 'YYYY-MM-DD'));
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      currentDay = addDays(currentDay, 1);
    }
    return weeks;
  }
}
