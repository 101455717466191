import PropTypes from 'prop-types';
import React from 'react';

import { formatRecordDuration } from '../lib/fmt';
import { cn } from '../lib/utils';
import { getRecordHeight } from '../services/scheduler.service';
import MRecordCopy from './modals/MRecordCopy';

export default class RecordCard extends React.Component {
  regRef = React.createRef();
  phoneRef = React.createRef();

  static propTypes = {
    app: PropTypes.object.isRequired,
    // --
    className: PropTypes.string,
    record: PropTypes.object,
    position: PropTypes.object,
    onOpenRecord: PropTypes.func,
  };

  render() {
    const { record, className, position } = this.props;
    if (!record) {
      return (
        <div
          className={cn('RecordCard', 'table-success', className)}
          style={position}
          onClick={() => this.onOpenRecord()}
        >
          Добавить
        </div>
      );
    }
    return this.renderFilledCard();
  }

  // event helpers

  onOpenRecord(record) {
    const { onOpenRecord } = this.props;
    if (onOpenRecord) {
      onOpenRecord(record);
    }
  }

  async onCopyButtonClick(e, record) {
    const { app } = this.props;
    e.stopPropagation();
    await app.showModal(MRecordCopy, { record });
  }

  // render heplers

  renderFilledCard() {
    const { app, record, className, position } = this.props;
    const height = getRecordHeight(app, record);
    return (
      <div
        className={cn('RecordCard', this.getColor(), className)}
        style={{ ...position, height }}
        onClick={() => this.onOpenRecord(record)}
      >
        <div className="RecordCard_copyButton">
          <i className="fas fa-copy d-block text-secondary" onClick={(e) => this.onCopyButtonClick(e, record)} />
        </div>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    const { app, record } = this.props;
    const isLongRecord = record.duration > app.getConfig().scheduler.timeStepMinutes && !record.is_virtual;
    return isLongRecord ? (
      <React.Fragment>
        {this.renderReg()}
        {this.renderPhone()}
        {this.renderVehicle()}
        {this.renderStatus()}
        {this.renderComment()}
        <div className="RecordCard_fade" />
      </React.Fragment>
    ) : (
      <React.Fragment>
        {this.renderReg()}
        {this.renderPhone()}
        {this.renderStatus()}
      </React.Fragment>
    );
  }

  renderReg() {
    const { record } = this.props;
    return (
      <span ref={this.regRef} className="text-primary text-nowrap">
        {`#${record.reg}`}
        {record.is_virtual && ` – ${formatRecordDuration(record.duration)}`}
      </span>
    );
  }

  renderPhone() {
    const { record } = this.props;
    return (
      <span ref={this.phoneRef} className="text-success">
        {record.phone}
      </span>
    );
  }

  renderVehicle() {
    const { record } = this.props;
    return (
      <span className="text-info">
        {record.vehicle_brand} {record.vehicle_model}
      </span>
    );
  }

  renderStatus() {
    const { record } = this.props;
    return <span className="text-dark font-weight-bold">{record.status_name}</span>;
  }

  renderComment() {
    const { record } = this.props;
    return <span className="text-secondary">{record.comment}</span>;
  }
  // other helpers

  getColor() {
    const { record } = this.props;
    if (record.is_virtual) {
      return 'table-secondary';
    }
    switch (record.status_style) {
      case 'green':
        return 'table-success';
      case 'yellow':
        return 'table-warning';
      case 'red':
        return 'table-danger';
      case 'muted':
        return 'table-secondary';
      case '':
      default:
        return 'table-primary';
    }
  }
}
