import { endOfMonth, format, isSameDay, isSameMonth, startOfMonth } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import MRecordSearch from '../modals/MRecordSearch';
import SchedulerCalendar from '../SchedulerCalendar';
import SchedulerChart from '../SchedulerChart';

export default class PScheduler extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { app } = props;
    this.state = {
      isReady: false,
      depts: app.loadFromCache('depts') || [],
      records: app.loadFromCache('records') || [],
    };
  }

  render() {
    return (
      <div className="PScheduler">
        <div className="d-lg-flex align-items-flex-start justify-content-around">
          {this.renderCalendar()}
          {this.renderChart()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    app.setupPage(null, 'Планировщик');
    this.setupWebSocket();
  }

  async componentDidUpdate(prevProps) {
    const { app, match } = this.props;
    const { isReady } = this.state;
    const { match: prevMatch } = prevProps;
    if (!match.params.date) {
      app.getHistory().push(`/scheduler/${format(new Date(), 'YYYY-MM-DD')}`);
      return;
    }
    const prevDay = prevMatch.params.date;
    const day = this.getDate();
    const api = app.getApi();
    const newState = {};
    if (!isReady) {
      this.setState({ isReady: true });
      const { items } = await api.get('/depts');
      newState.depts = items;
      app.saveToCache('depts', items);
    }
    if (!isReady || !isSameMonth(prevDay, day)) {
      newState.records = await this.fetchRecords();
    }
    if (newState.depts || newState.records) {
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(undefined);
    ws.registerReconnectHandler(undefined);
  }

  // event handlers

  async onDayChange(newDay) {
    const { app } = this.props;
    app.getHistory().push(`/scheduler/${newDay}`);
  }

  async onChartClick(index) {
    const { app } = this.props;
    const { depts } = this.state;
    app.getHistory().push(`/scheduler/${this.getDate()}/${depts[index].id}`);
  }

  async onSearch() {
    const { app } = this.props;
    await app.showModal(MRecordSearch);
  }

  // render helpers

  renderCalendar() {
    const { app } = this.props;
    const { records } = this.state;
    const day = this.getDate();
    return (
      <SchedulerCalendar
        app={app}
        day={day}
        mode={this.getCalendarMode()}
        records={records}
        onDayChange={(day) => this.onDayChange(day)}
        onSearch={() => this.onSearch()}
      />
    );
  }

  renderChart() {
    const { app } = this.props;
    const { depts, records } = this.state;
    const day = this.getDate();
    return (
      <SchedulerChart
        className="mb-3 mb-lg-0"
        app={app}
        depts={depts}
        records={records.filter((x) => isSameDay(x.date, day))}
        onClick={(element) => this.onChartClick(element)}
      />
    );
  }

  // other helpers

  setupWebSocket() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'scheduler_update') {
        const { date } = msg.details;
        if (!date || isSameMonth(date, this.getDate())) {
          const records = await this.fetchRecords();
          this.setState({ records });
        }
      }
    });
    ws.registerReconnectHandler(async () => {
      const records = await this.fetchRecords();
      this.setState({ records });
    });
  }

  getDate() {
    const { match } = this.props;
    return match.params.date || format(new Date(), 'YYYY-MM-DD');
  }

  getCalendarMode() {
    const { app } = this.props;
    return app.isMobile() ? 'w2' : 'm1';
  }

  async fetchRecords() {
    const { app } = this.props;
    const date = this.getDate();
    const api = app.getApi();
    const { items } = await api.get('/records', {
      start_date: format(startOfMonth(date), 'YYYY-MM-DD'),
      finish_date: format(endOfMonth(date), 'YYYY-MM-DD'),
      is_virtual: false,
    });
    app.saveToCache('records', items, true);
    return items;
  }
}
