import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime } from '../../lib/fmt';
import LabelValue from '../LabelValue';

export default class MCreateUpdateInfo extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    created_at: PropTypes.string.isRequired,
    created_by: PropTypes.number.isRequired,
    updated_at: PropTypes.string.isRequired,
    updated_by: PropTypes.number.isRequired,
    workers: PropTypes.array.isRequired,
  };

  render() {
    return (
      <div className="MCreateUpdateInfo modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Информация</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const { created_at, created_by, updated_at, updated_by } = this.props;
    return (
      <form className="modal-body">
        <div className="row">
          <LabelValue
            className="col"
            label="Создал"
            value={`${this.getWorkerName(created_by)}, ${formatDateTime(created_at)}`}
          />
        </div>
        <div className="row">
          <LabelValue
            className="col"
            label="Обновил"
            value={`${this.getWorkerName(updated_by)}, ${formatDateTime(updated_at)}`}
          />
        </div>
      </form>
    );
  }

  // other helpers

  getWorkerName(workerId) {
    const { workers } = this.props;
    if (!workerId) {
      return '–';
    }
    const worker = workers.find((w) => w.id === workerId);
    return worker ? worker.name : '–';
  }
}
