import Chart from 'chart.js';
import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../lib/utils';

import {
  convertChartValueToPercentage,
  convertPercentageToChartValue,
  countPercentageWorkload,
  countWorkload,
} from '../services/scheduler.service';

const SEGMENT_BORDER_COLOR = '#bf9765';
const SEGMENT_OPACITY = 0x88;
const SEGMENT_LAYOUT_OPACITY = 26;

export default class SchedulerChart extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    depts: PropTypes.array.isRequired,
    records: PropTypes.array.isRequired,
    // --
    className: PropTypes.string,
    deptId: PropTypes.number,
    onClick: PropTypes.func,
  };

  chart;
  refChart = React.createRef();

  render() {
    const { className } = this.props;
    return (
      <section className={cn(className, 'SchedulerChart')}>
        <canvas ref={this.refChart} />
      </section>
    );
  }

  componentDidMount() {
    this.initChart();
  }

  async componentDidUpdate() {
    const { depts, deptId } = this.props;
    this.chart.data.labels = depts.map((x) => x.name);
    this.chart.data.datasets[0].data = this.getChartData();
    this.chart.data.datasets[0].backgroundColor = depts.map((x) => x.color + SEGMENT_OPACITY.toString(16));
    this.chart.data.datasets[1].data = depts.map(() => 100);
    this.chart.data.datasets[1].backgroundColor = depts.map((x) => x.color + SEGMENT_LAYOUT_OPACITY);
    if (deptId) {
      this.chart.data.datasets[1].borderColor = depts.map((x) =>
        x.id === deptId ? SEGMENT_BORDER_COLOR : 'transparent',
      );
    }
    this.chart.update();
  }

  // event handlers

  async onHover(e) {
    const elements = this.chart.getElementsAtEvent(e);
    if (elements.length !== 0) {
      e.target.style.cursor = 'pointer';
    } else {
      e.target.style.cursor = 'default';
    }
  }

  async onClick(e) {
    const { onClick } = this.props;
    if (!onClick) {
      return;
    }
    const elements = this.chart.getElementsAtEvent(e);
    if (elements.length === 0) {
      return;
    }
    onClick(elements[0]._index);
  }

  // other helpers

  initChart() {
    const { depts } = this.props;
    if (!this.refChart.current) {
      return;
    }
    this.chart = new Chart(this.refChart.current, {
      type: 'polarArea',
      options: {
        animation: { animateRotate: false },
        aspectRatio: 1,
        // https://github.com/chartjs/Chart.js/issues/5907
        startAngle: 4 * Math.PI * 1891 - Math.PI,
        title: { display: true, fontColor: '#000', fontSize: 16, text: 'Загруженность' },
        legend: {
          labels: {
            generateLabels: () => depts.map((x) => ({ text: x.short_name, fillStyle: x.color, lineWidth: 0 })),
          },
          position: 'bottom',
          onClick: (e) => e.stopPropagation(),
        },
        scale: {
          ticks: {
            callback: (value) => {
              switch (value) {
                case 70:
                  return '50%';
                case 100:
                  return '100%';
                default:
                  return '';
              }
            },
          },
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              if (tooltipItem.datasetIndex === 0) {
                const currentData = convertChartValueToPercentage(data.datasets[0].data[tooltipItem.index]);
                const currentLabel = data.labels[tooltipItem.index];
                return `${currentLabel}: ${currentData} %`;
              }
            },
          },
        },
        onHover: (e) => this.onHover(e),
        onClick: (e) => this.onClick(e),
      },
      data: {
        datasets: [
          { data: [], borderColor: 'transparent' },
          { data: [], borderColor: 'transparent', borderWidth: 1 },
        ],
      },
    });
    this.forceUpdate();
  }

  getChartData() {
    const { app, depts, records } = this.props;
    const workloads = depts.map((d) => {
      const filteredRecords = records.filter((r) => r.dept_id === d.id);
      const workload = countWorkload(app, filteredRecords);
      const percenageWorkload = countPercentageWorkload(app, workload, d.post_count);
      return convertPercentageToChartValue(percenageWorkload);
    });
    return workloads;
  }
}
