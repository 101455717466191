export function getPagerButtons(page, pageCount, maxButtons) {
  if (pageCount < 2) {
    return [];
  }
  const leftDots = hasLeftDots(page, pageCount, maxButtons);
  const rightDots = hasRightDots(page, pageCount, maxButtons);
  const secondPage = getSecondPage(page, pageCount, maxButtons);
  const penultPage = getPenultPage(page, pageCount, maxButtons);
  const buttons = [1];
  if (leftDots) {
    buttons.push(-1);
  }
  for (let p = secondPage; p <= penultPage; p += 1) {
    buttons.push(p);
  }
  if (rightDots) {
    buttons.push(-2);
  }
  buttons.push(pageCount);
  return buttons;
}

// helpers:

function getSecondPage(page, pageCount, maxButtons) {
  const leftDots = hasLeftDots(page, pageCount, maxButtons);
  const rightDots = hasRightDots(page, pageCount, maxButtons);
  if (leftDots && rightDots) {
    return page - getLeftSize(maxButtons) + 2;
  } else if (leftDots) {
    return pageCount - maxButtons + 3;
  } else {
    return 2;
  }
}

function getPenultPage(page, pageCount, maxButtons) {
  const leftDots = hasLeftDots(page, pageCount, maxButtons);
  const rightDots = hasRightDots(page, pageCount, maxButtons);
  if (leftDots && rightDots) {
    return page + getRightSize(maxButtons) - 2;
  } else if (rightDots) {
    return maxButtons - 2;
  } else {
    return pageCount - 1;
  }
}

function hasLeftDots(page, pageCount, maxButtons) {
  if (pageCount <= maxButtons) {
    return false;
  }
  return page > getLeftSize(maxButtons) + 1;
}

function hasRightDots(page, pageCount, maxButtons) {
  if (pageCount <= maxButtons) {
    return false;
  }
  return pageCount - page > getRightSize(maxButtons);
}

function getRightSize(maxButtons) {
  return maxButtons - getLeftSize(maxButtons) - 1;
}

function getLeftSize(maxButtons) {
  return Math.floor(maxButtons / 2);
}
