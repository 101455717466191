import { addDays, format, getMonth, isSameDay, isSameMonth, startOfMonth, startOfWeek } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatMonth } from '../lib/fmt';
import { cn } from '../lib/utils';

export class HolidayCalendar extends React.Component {
  static propTypes = {
    date: PropTypes.object.isRequired,
    holidays: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { className } = this.props;
    const holidayCalendarClassName = cn(className, 'HolidayCalendar');
    return (
      <div className={holidayCalendarClassName}>
        <table className="table table-bordered table-sm text-center bg-light">
          {this.renderTableHead()}
          {this.renderTableBody()}
        </table>
      </div>
    );
  }

  // event handlers

  async onDayClick(day) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(day);
    }
  }

  // render helpers

  renderTableHead() {
    const { date } = this.props;
    return (
      <thead>
        <tr>
          <th colSpan={7}>{formatMonth(getMonth(date))}</th>
        </tr>
        <tr>
          <th className="font-weight-normal text-secondary">Пн</th>
          <th className="font-weight-normal text-secondary">Вт</th>
          <th className="font-weight-normal text-secondary">Ср</th>
          <th className="font-weight-normal text-secondary">Чт</th>
          <th className="font-weight-normal text-secondary">Пт</th>
          <th className="font-weight-normal text-secondary">Сб</th>
          <th className="font-weight-normal text-secondary">Вс</th>
        </tr>
      </thead>
    );
  }

  renderTableBody() {
    const weeks = this.getWeeks();
    return (
      <tbody>
        {weeks.map((x, index) => (
          <tr key={index}>{x.map((x) => this.renderDayCell(x))}</tr>
        ))}
      </tbody>
    );
  }

  renderDayCell(day) {
    const { date } = this.props;
    if (!isSameMonth(day, date)) {
      return (
        <td className="_empty" key={day.toString()}>
          {format(day, 'DD')}
        </td>
      );
    }
    const isToday = this.isToday(day);
    const isHoliday = this.isHoliday(day);
    const className = cn(isHoliday && 'text-danger', (isHoliday || isToday) && 'font-weight-bold');
    return (
      <td key={day.toString()} className={className} onClick={() => this.onDayClick(day)}>
        {format(day, 'D')}
      </td>
    );
  }

  // other helpers

  getWeeks() {
    const { date } = this.props;
    const weeks = [];
    const firstDay = startOfWeek(startOfMonth(date), { weekStartsOn: 1 });
    let currentDay = firstDay;
    let week = [];
    while (weeks.length < 6) {
      week.push(currentDay);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      currentDay = addDays(currentDay, 1);
    }
    return weeks;
  }

  isHoliday(day) {
    const { holidays } = this.props;
    return holidays.some((x) => isSameDay(new Date(x.date), day));
  }

  isToday(day) {
    return isSameDay(new Date(), day);
  }
}
