import PropTypes from 'prop-types';
import React from 'react';
import Swiper from 'swiper';

import { cn } from '../lib/utils';
import {
  getPositionCard,
  getPostNames,
  getRecordsByCell,
  getTimeSteps,
  isIntersection,
} from '../services/scheduler.service';
import CurrentTimeLine from './CurrentTimeLine';
import RecordCard from './RecordCard';

export default class MobileSchedulerTable extends React.Component {
  refSwiperContainer = React.createRef();
  swiper;

  static propTypes = {
    app: PropTypes.object.isRequired,
    records: PropTypes.array.isRequired,
    virtualRecords: PropTypes.array.isRequired,
    // --
    dept: PropTypes.object,
    isCurrentDay: PropTypes.bool,
    isHoliday: PropTypes.bool,
    onAddRecord: PropTypes.func,
    onEditRecord: PropTypes.func,
    onChangePost: PropTypes.func,
  };

  render() {
    const { app, isCurrentDay, isHoliday } = this.props;
    const timeSteps = getTimeSteps(app);
    return (
      <section className={cn('MobileSchedulerTable d-lg-none', isHoliday && '_holiday')}>
        <div className="MobileSchedulerTable_wrapper position-relative">
          <table className="table table-bordered text-center">
            {this.renderTableHead()}
            {this.renderTableBody(timeSteps)}
          </table>
          {this.renderSlider(timeSteps)}
          {isCurrentDay && this.renderCurrentTimeLine()}
        </div>
      </section>
    );
  }

  componentDidMount() {
    this.initSwiper(this.refSwiperContainer.current);
  }

  // event handlers

  onAddRecord(time, postIndex) {
    const { onAddRecord, isHoliday } = this.props;
    const checkHoliday = !isHoliday || window.confirm('Вы уверены, что хотите записать на выходной?');
    if (!onAddRecord || !checkHoliday) {
      return;
    }
    onAddRecord(time, postIndex);
  }

  onEditRecord(record) {
    const { onEditRecord } = this.props;
    if (!onEditRecord) {
      return;
    }
    onEditRecord(record);
  }

  // render helpers

  renderTableHead() {
    return (
      <thead>
        <tr>
          <th />
          <th />
        </tr>
      </thead>
    );
  }

  renderTableBody(timeSteps) {
    return (
      <tbody>
        {timeSteps.map((x) => (
          <tr key={x}>{this.renderTableRow(x)}</tr>
        ))}
        {this.renderAdditionalTableRows()}
      </tbody>
    );
  }

  renderAdditionalTableRows() {
    const { app, virtualRecords } = this.props;
    const timeSteps = getTimeSteps(app);
    const additionalRowCount = Math.max(0, virtualRecords.length - timeSteps.length);
    const rows = [];
    for (let i = 1; i <= additionalRowCount; i++) {
      rows.push(<tr key={i}>{this.renderTableRow()}</tr>);
    }
    return rows;
  }

  renderTableRow(timeStep) {
    return (
      <React.Fragment>
        <td>{timeStep && timeStep.slice(0, 5)}</td>
        <td></td>
      </React.Fragment>
    );
  }

  renderSlider(timeSteps) {
    const { dept } = this.props;
    const posts = getPostNames(dept);
    return (
      <div className="MobileSchedulerTable_slider position-absolute">
        <div className="swiper-container" ref={this.refSwiperContainer}>
          <div className="swiper-wrapper">{posts.map((x) => this.renderSlide(timeSteps, x))}</div>
        </div>
      </div>
    );
  }

  renderCurrentTimeLine() {
    const { app } = this.props;
    return <CurrentTimeLine app={app} mode="mobile" />;
  }

  renderSlide(timeSteps, post) {
    return (
      <div key={post.index} className="swiper-slide" data-post={post.index}>
        <h4 className="text-center">{post.title}</h4>
        {post.index !== 0 ? this.renderRealPostSlider(timeSteps, post) : this.renderVirtualPostTableCell()}
      </div>
    );
  }

  renderRealPostSlider(timeSteps, post) {
    return timeSteps.map((x, index) => this.renderRealPostTableCell(x, index, post));
  }

  renderRealPostTableCell(timeStep, timestepIndex, post) {
    const { app, records } = this.props;
    const filteredRecords = records.filter((x) => x.post_index === post.index);
    const recordsByCell = getRecordsByCell(app, records, timeStep, post);
    const isEmpty = timeStep ? recordsByCell.length === 0 : false;
    const position = getPositionCard(false, timestepIndex);
    return (
      <div key={`${timeStep}${post.index}`}>
        {filteredRecords.map(
          (x) => x.time === timeStep && this.renderTableCellRecord(x, isIntersection(x, recordsByCell), timestepIndex),
        )}
        {isEmpty && (
          <RecordCard
            app={app}
            key={`${timeStep}${post.index}_empty`}
            className="_empty"
            position={position}
            onOpenRecord={() => this.onAddRecord(timeStep, post.index)}
          />
        )}
      </div>
    );
  }

  renderVirtualPostTableCell() {
    const { virtualRecords } = this.props;
    return virtualRecords.map((record) => {
      return this.renderTableCellRecord(record, true);
    });
  }

  renderTableCellRecord(record, isIntersecting, timestepIndex) {
    const { app } = this.props;
    let position;
    if (!record.is_virtual) {
      position = getPositionCard(isIntersecting, timestepIndex);
    }
    return (
      <RecordCard
        key={record.id}
        position={position}
        app={app}
        record={record}
        onOpenRecord={() => this.onEditRecord(record)}
      />
    );
  }

  // other helpers

  initSwiper(container) {
    const { onChangePost } = this.props;
    if (this.swiper) {
      this.swiper.destroy(false, true);
    }
    this.swiper = new Swiper(container, {
      spaceBetween: 30,
      on: {
        slideChange: () => onChangePost(this.swiper.realIndex + 1),
      },
    });
  }
}
